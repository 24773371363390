import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return {
      ...data,
      onlyCoverage: props.onlyCoverage,
    }
  }, [data, props.onlyCoverage])
}
