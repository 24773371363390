// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentPlans/New/Steps/Content/PaymentPlans/Content/Content/PaymentPlan/Content/Content/Insurances/Insurance/Subscription/Content/Content/Search/Content/InsurancePlan/Assign/Content/category_query.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentPlans_New_Steps_Content_PaymentPlans_Content_Content_PaymentPlan_Content_Content_Insurances_Insurance_Subscription_Content_Content_Search_Content_InsurancePlan_Assign_Content{
  vaxiom_tx_categories(where: { name: { _eq: "Ortho" } }) {
    id
  }
}

`