// this is an autogenerated file from App/Account/Content/Marketing/ReportTable/Content/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Marketing_ReportTable_Content_Content($lead_id: uuid!) {
  leads_create_patient(lead_id: $lead_id) {
    ok
  }
}

`