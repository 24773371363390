import TysiaModalBackdrop from 'DesignSystem/TysiaModal/TysiaModalBackdrop/index.js'

export default function Logic(props) {
  let children =
    typeof props.children === 'function'
      ? props.children(props)
      : props.children

  return props.isVisible ? (
    <TysiaModalBackdrop viewPath={`${props.viewPath}/TysiaModalBackdrop`}>
      {children}
    </TysiaModalBackdrop>
  ) : (
    children
  )
}
