export default function useDefaultTab(props, data) {
  return function ensureDefaultTab(maybeTab, id, sync, appId) {
    if (maybeTab?.selected) {
      return {
        ...maybeTab,
        sync: {
          app_id: sync?.app_id || appId,
          title: sync?.title,
        },
      }
    }

    return {
      ...data,
      id,
      type: id,
      name: id,
      viewPath: props.viewPath,
      selected: {
        ...data.selected,
      },
      sync: {
        app_id: sync?.app_id,
        title: sync?.title,
      },
    }
  }
}
