import { numberMoney } from 'Data/format.js'

export function getValue(max_lifetime_coverage, used_lifetime_coverage) {
  if (
    typeof max_lifetime_coverage !== 'number' ||
    typeof used_lifetime_coverage !== 'number' ||
    isNaN(max_lifetime_coverage) ||
    isNaN(used_lifetime_coverage)
  ) {
    return '-'
  }

  return numberMoney(
    parseFloat((max_lifetime_coverage - used_lifetime_coverage).toFixed(2))
  )
}
