import { useDataValue, useDataChange } from 'Simple/Data.js'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'appointment_overlay',
    viewPath: props.viewPath,
  })
  let patient_id = useDataValue({
    context: 'person',
    path: 'patient._id',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(next => {
      next.patient_id = patient_id
    })
  }
}
