import {
  useDataSubmit,
  useDataValue,
  useDataIsSubmitting,
} from 'Simple/Data.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'selected',
  })

  return () => {
    submit()
  }
}

export function useIsDisabled(props) {
  let isSubmitting = useDataIsSubmitting({
    context: 'selected',
    viewPath: props.viewPath,
  })
  let send_to_person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'send_to_person_id',
  })
  let contact_method_id = useDataValue({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'contact_method.id',
  })

  return !send_to_person_id || !contact_method_id || isSubmitting
}

export function isComms(contact_method) {
  return ['Phone', 'InApp', 'Email'].includes(contact_method?.type)
}
