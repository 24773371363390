import React, { useEffect } from 'react'
import { useDataValue, useDataChange } from 'Simple/Data.js'

import View from './view.js'

export default function Logic(props) {
  let image_series = useDataValue({
    context: 'image_series',
    viewPath: props.viewPath,
  })
  let image_series_id = useDataValue({
    context: 'tab',
    path: 'selected.images.image_series_id',
    viewPath: props.viewPath,
  })
  let show_deleted_image_series = useDataValue({
    context: 'tab',
    path: 'selected.images.show_deleted_image_series',
    viewPath: props.viewPath,
  })
  let setSelectedImageSeriesId = useDataChange({
    context: 'tab',
    path: 'selected.images.image_series_id',
    viewPath: props.viewPath,
  })

  let setSelectedImageSeriesUuid = useDataChange({
    context: 'tab',
    path: 'selected.images.image_series_uuid',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    if (image_series_id) return

    setSelectedImageSeriesId(image_series[0].id)
    setSelectedImageSeriesUuid(image_series[0]._id)
  }, [image_series, image_series_id]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (show_deleted_image_series || !image_series_id) return

    if (
      image_series.some(item => item.id === image_series_id && item.deleted)
    ) {
      setSelectedImageSeriesId(null)
      setSelectedImageSeriesUuid(null)
    }
  }, [show_deleted_image_series, image_series_id]) // eslint-disable-line react-hooks/exhaustive-deps
  return <View {...props} />
}
