import { useDataSubmit, useDataValue } from 'Simple/Data.js'
import { DEFAULT_APPOINTMENT_DURATION } from 'Data/constants.js'
import { isUnscheduledAppointment, timeToMinutes } from 'Data/format.js'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
  })
  let submitTab = useDataSubmit({
    viewPath: props.viewPath,
    context: 'tab',
  })
  let appointment_id = useDataValue({
    context: 'appointment_overlay',
    viewPath: props.viewPath,
    path: 'appointment_id',
  })
  let is_external_any = useDataValue({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
    path: 'is_external_any',
  })

  return async function onChange(next) {
    if (!next?.length) return

    /**
     * If the appointment selected in the overlay is due to external args, setExistingAppointment
     * was already triggered in AppointmentData. Here, we have access to the calculated approximate
     * or optimal date if applies, and in such case we need to change the optimal date at tab level
     */
    if (is_external_any) {
      let appointment = appointment_id
        ? next.find(v => v._id === appointment_id)
        : next.find(v => v.id === 'new')
      if (appointment && appointment.approximateDate !== null) {
        submitTab({ type: 'setOptimalDate', date: appointment.approximateDate })
      }
      return
    }

    let already_selected_appointment = next.find(v => v._id === appointment_id)
    if (already_selected_appointment) {
      /**
       * We do not set the location here. This is because this condition handles the case where an
       * appointment is already selected in the overlay, and the user updates the location. So, it
       * is assumed that when the location was changed, we do not need to update it from here.
       */
      submit({
        type: 'setExistingAppointment',
        id: already_selected_appointment._id,
        type_id: already_selected_appointment.type._id,
        type_name: already_selected_appointment.type.display_name,
        treatment_id: already_selected_appointment.tx._id,
        duration:
          already_selected_appointment.duration || DEFAULT_APPOINTMENT_DURATION,
        approximateDate: already_selected_appointment.approximateDate,
        ...(!isUnscheduledAppointment(already_selected_appointment)
          ? {
              preselect_scheduling_slot_config: {
                start_min: timeToMinutes(
                  already_selected_appointment.booking[0].local_start_time
                ),
                date: already_selected_appointment.booking[0].local_start_date,
                chair_id: already_selected_appointment.booking[0].chair._id,
                location_id:
                  already_selected_appointment.booking[0].chair.resource
                    .organization._id,
              },
            }
          : {}),
      })

      return
    }

    /**
     * Instead, if we are selecting the appointment from existing patient searched inside
     * appointment overlay, we should choose the preferred one from the timeline
     * If there is a preferred, we call setExistingAppointment method to set the data
     * in the appointment overlay context and update the optimal date if this applies
     */
    let preferred = next.filter(
      v => isUnscheduledAppointment(v) && v.id !== 'new'
    )?.[0]

    if (preferred) {
      // There is atleast one unscheduled appointment in the list of appointments
      // This unscheduled appointment, either has no booking associated or the
      // booking is in NO_SHOW, OFFICE_CANCELLED or PATIENT_CANCELLED state
      submit({
        type: 'setExistingAppointment',
        id: preferred._id,
        type_id: preferred.type._id,
        type_name: preferred.type.display_name,
        treatment_id: preferred.tx._id,
        duration: preferred.duration || DEFAULT_APPOINTMENT_DURATION,
        approximateDate: preferred.approximateDate,
        location: {
          id: preferred.tx.organization._id,
          vaxiom_id: preferred.tx.organization.id,
          name: preferred.tx.organization.name,
          time_zone_id: preferred.tx.organization.time_zone_id,
        },
      })
    } else {
      let newAppointment = next.find(v => v.id === 'new')
      submit({
        type: 'setNewAppointment',
        approximateDate: newAppointment.approximateDate || null,
      })
    }
  }
}
