import { useDataSubmit } from 'Simple/Data.js'

export function useOnChange(props) {
  let submit = useDataSubmit({
    context: 'settings',
    viewPath: props.viewPath,
  })

  return function onChange(id) {
    submit({
      key: 'chairs_in_view',
      value: id,
    })
  }
}
