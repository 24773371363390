// this is an autogenerated file from App/Account/PatientOverlay/Content/Content/Appointments/TabContent/Content/AppointmentsList/Content/Appointment/BottomAction/Content/Unseat/MissingRequiredTreatmentFieldsDialog/Content/TreatmentFields/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_PatientOverlay_Content_Content_Appointments_TabContent_Content_AppointmentsList_Content_Appointment_BottomAction_Content_Unseat_MissingRequiredTreatmentFieldsDialog_Content_TreatmentFields($appointment_id: uuid!) {
  appointments_missing_required_treatment_fields(
    args: { _appointment_id: $appointment_id }
    order_by: { number: asc }
  ) {
    id
    name
  }
}

`