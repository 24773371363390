import {
  notifyError,
  useNotifications,
  notifySuccess,
} from 'Logic/Notifications.js'
import { useDataValue, useDataSubmit } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'

import mutation from './mutation-connect-api-token.graphql.js'

export default function useListItemDataOnSubmit(props, data) {
  let [, executeMutation] = useMutation(mutation)

  let [, notify] = useNotifications()

  let location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location._id',
  })
  let submit = useDataSubmit({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return async function onAction({ value, args, change }) {
    if (!value.token) return

    let mutationResponse = await executeMutation({
      app_id: value.id,
      location_id,
      // TO DO: take this from dynamically generated connection configuration inputs
      // see: https://www.notion.so/getgreyfinch/App-Connections-Refactor-Proposal-96b3ef598e3e4db9b6880b42da3aae09?pvs=4
      connection_properties: {},
      token: value.token.trim(),
      expires_at: value.tokenExpiresAt || null,
    })

    if (
      mutationResponse.error ||
      !mutationResponse.data.apps_connect_api_token.connection_id
    ) {
      return notify(
        notifyError(
          'An error occurred trying to connect the app. Please refresh the page and try again.'
        )
      )
    }

    change(next => {
      next.connection_id =
        mutationResponse.data.apps_connect_api_token.connection_id
    })
    submit({ type: 'manage', app_id: value.id, title: value.name })

    return notify(notifySuccess('The application was connected successfully'))
  }
}
