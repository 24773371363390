// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentPlans/New/Steps/Content/PaymentPlans/Content/Content/PaymentPlan/Content/Content/mutation-save.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentPlans_New_Steps_Content_PaymentPlans_Content_Content_PaymentPlan_Content_Content(
  $current_location_id: numeric!
  $payment_plan: jsonb!
) {
  treatments_insert_payment_plan(
    args: {
      current_location_id: $current_location_id
      payment_plan: $payment_plan
    }
  ) {
    id
  }
}

`