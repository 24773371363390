import {
  notifyError,
  useNotifications,
  notifySuccess,
} from 'Logic/Notifications.js'
import { useMutation } from 'Data/Api.js'

import mutationInstall from './mutation-install.graphql.js'

export default function useListItemDataOnActionInstall(props, data) {
  let [, executeMutationInstall] = useMutation(mutationInstall)

  let [, notify] = useNotifications()

  return async function onAction({ value, args, change }) {
    if (!value.acceptedTermsAndConditions) return

    let mutationResponse = await executeMutationInstall({
      app_id: value.id,
    })
    if (mutationResponse.error || !mutationResponse.data.apps_install.app) {
      return notify(
        notifyError(
          `An error occurred trying to install the "${value.app_name}" app. Please refresh the page and try again.`
        )
      )
    }

    notify(notifySuccess(`App "${value.app_name}" installed!`))
  }
}
