import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

let DEFAULT_SETTINGS = {
  slot_interval: 5,
  hipaa: false,
  selected_appointment_templates: [],
  blue_bar_range: { before: 4, after: 4 },
}

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let tab_appointment_overlay_open = useDataValue({
    viewPath: props.viewPath,
    context: 'tab',
    path: 'appointment_overlay.open',
  })
  let global_appointment_overlay_open = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'appointment_overlay.open',
  })
  let appointment_overlay_open =
    tab_appointment_overlay_open || global_appointment_overlay_open

  return useMemo(() => {
    let user_settings = data?.calendar_user_settings?.[0]
    let location_settings = data?.calendar_location_settings?.[0]
    let public_user_preferences = data?.user_preferences?.[0]

    if (!user_settings) {
      user_settings = {}
    }

    if (!location_settings) {
      location_settings = {}
    }

    if (!public_user_preferences) {
      public_user_preferences = {}
    }

    let settings = {
      slot_interval:
        user_settings.slot_interval ?? DEFAULT_SETTINGS.slot_interval,
      hipaa: public_user_preferences.hipaa ?? DEFAULT_SETTINGS.hipaa,
      selected_appointment_templates:
        user_settings.selected_appointment_templates ??
        DEFAULT_SETTINGS.selected_appointment_templates,
      blue_bar_range:
        location_settings.blue_bar_range ?? DEFAULT_SETTINGS.blue_bar_range,
      // The default value for chairs_in_view is not set here.
      // It is set in the next level when we have knowledge
      // about the total number of chairs present in the clinic
      chairs_in_view: user_settings.chairs_in_view,
    }

    // prevent full day view when scheduling, but still allows switching between 5/10
    if (appointment_overlay_open && settings.slot_interval === 30) {
      return {
        ...settings,
        slot_interval: DEFAULT_SETTINGS.slot_interval,
      }
    }

    return settings
  }, [data, appointment_overlay_open])
}
