// this is an autogenerated file from DesignSystem/CommsThread/Content/mutation-practice-hub-invite-patient.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_CommsThread_Content(
  $patient_person_id: uuid!
  $invited_person_id: uuid!
  $email: String!
  $location_id: uuid!
) {
  auth_accounts_practice_hub_invite_patient(
    patient_person_id: $patient_person_id
    invited_person_id: $invited_person_id
    email: $email
    location_id: $location_id
    permitted_to_see_info: true
    send_patient_reminders_to_email: true
  ) {
    ok
  }
}

`