// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentPlans/New/Steps/Content/PaymentPlans/Content/Content/PaymentPlan/Content/Content/Insurances/Insurance/Subscription/Content/Content/Search/Content/InsurancePlan/ViewAction/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentPlans_New_Steps_Content_PaymentPlans_Content_Content_PaymentPlan_Content_Content_Insurances_Insurance_Subscription_Content_Content_Search_Content_InsurancePlan_ViewAction_Content($id: numeric!) {
  vaxiom_patient_insurance_plans_by_pk(id: $id) {
    id
    _id
    number
    name
    description
    last_verified
    insurance_company {
      id
      carrier_name
    }
    insurance_billing_center {
      id
      name
    }
    insurance_plan_employers {
      employer_id
    }

    tx_category_coverages {
      id
      ortho_coverages {
        id
        max_lifetime_coverage
        dependent_age_limit
        reimbursement_percentage
        age_limit_cutoff
        deductible_amount
        waiting_period
        downpayment_type
        downpayment_amount
        downpayment_percentage
        downpayment_percentage_base
        inprogress
        cob_type
      }
    }
  }
}

`