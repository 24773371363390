import { useMutation } from 'Data/Api.js'
import { useDataValue, useDataSubmit } from 'Simple/Data.js'
import { notifyError, useNotifications } from 'Logic/Notifications'

import mutationDeleteTreatmentPlan from './mutation-delete-treatment-plan.graphql.js'
import mutationAcceptTreatmentPlan from './mutation-accept-treatment-plan.graphql.js'

export default function useDataOnSubmit(props, data, reExecuteQuery) {
  let onRemove = useOnRemoveTreatmentPlan(props, data, reExecuteQuery)
  let onAccept = useOnAcceptTreatmentPlan(props, data, reExecuteQuery)

  return async function onSubmit({ args }) {
    switch (args.type) {
      case 'remove': {
        return onRemove({ args })
      }
      case 'accept': {
        return onAccept({ args })
      }
      default: {
        return
      }
    }
  }
}

function useOnRemoveTreatmentPlan(props, data, reExecuteQuery) {
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutationDeleteTreatmentPlan)

  return async function onSubmit({ args }) {
    let mutationResponse = await executeMutation({
      treatment_plan_id: args.treatment_plan_id,
    })

    if (mutationResponse.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )

      return
    }

    reExecuteQuery({ requestPolicy: 'cache-and-network' })
  }
}

function useOnAcceptTreatmentPlan(props, data, reExecuteQuery) {
  let treatment_id = useDataValue({
    context: 'treatment',
    path: '_id',
    viewPath: props.viewPath,
  })

  let submit = useDataSubmit({
    context: 'wizard',
    viewPath: props.viewPath,
  })

  let [, notify] = useNotifications()

  let [, executeMutation] = useMutation(mutationAcceptTreatmentPlan)

  return async function onSubmit({ args }) {
    let mutationResponse = await executeMutation({
      treatment_plan_id: args.treatment_plan_id,
      treatment_id,
      is_financials_managed_externally: !!args.is_financials_managed_externally,
      with_financial_externally:
        typeof args.is_financials_managed_externally === 'boolean',
    })

    if (mutationResponse.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
      return
    }

    submit({ type: 'done' })
  }
}
