import { useDataValue, useDataChange, useDataSubmit } from 'Simple/Data.js'

export default function useDataOnChange(props) {
  let change = useDataChange({
    context: 'tab',
    path: 'selected.topbar_appointment_templates',
    viewPath: props.viewPath,
  })

  let hipaa = useDataValue({
    context: 'global',
    viewPath: props.viewPath,
    path: 'user.preferences.hipaa',
  })

  let submit = useDataSubmit({
    context: 'global',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    change(value.selected_appointment_templates)

    if (hipaa !== value.hipaa) {
      submit({
        type: 'session/updateHipaa',
        hipaa: value.hipaa,
      })
    }
  }
}
