import React, { useMemo } from 'react'
import View from './view.js'
import CalendarDay from './CalendarDay/index'
import CalendarHeader from './CalendarHeader/index'
import { DataProvider, useDataValue } from 'Simple/Data.js'
import { format } from 'Data/date.js'
import { utcToZonedTime } from 'date-fns-tz'
import { addDays, isBefore, subDays } from 'date-fns'

export default function Logic({ viewPath }) {
  let monthly_calendar_data = useDataValue({
    context: 'monthly_calendar_data',
    viewPath,
  })

  let selected_date = useDataValue({
    context: 'tab',
    viewPath,
    path: 'selected.date',
  })

  let optimal_date = useDataValue({
    context: 'tab',
    viewPath,
    path: 'optimal_date',
  })

  let blue_bar_range = useDataValue({
    context: 'settings',
    viewPath,
    path: 'blue_bar_range',
  })

  let time_zone_id = useDataValue({
    context: 'tab',
    viewPath,
    path: 'selected.time_zone_id',
  })

  let blueBarDays = useMemo(
    () =>
      optimal_date !== null
        ? calculateBlueBarDays(optimal_date, blue_bar_range, time_zone_id)
        : {},
    [optimal_date, blue_bar_range, time_zone_id]
  )

  function renderDay(date) {
    let formattedDate = format(date, 'yyyy-MM-dd')
    let day_data =
      monthly_calendar_data?.find(data => data?.date === formattedDate) ?? {}

    let blueBarConfig = {}
    if (blueBarDays && formattedDate in blueBarDays) {
      let isOptimal = blueBarDays[formattedDate]
      blueBarConfig = isOptimal
        ? { isOptimal: true }
        : { isBeforeOrAfter: true }
    }

    return (
      <DataProvider
        context="calendar_day"
        value={{
          ...day_data,
          ...blueBarConfig,
          day: format(date, 'd'),
          date,
          is_selected: format(selected_date, 'yyyy-MM-dd') === formattedDate,
        }}
        viewPath={`${viewPath}/CalendarDay(${date.getTime()})`}
      >
        <CalendarDay viewPath={`${viewPath}/CalendarDay(${date.getTime()})`} />
      </DataProvider>
    )
  }

  function weekdayElement(props) {
    return (
      <DataProvider
        context="calendar_header"
        value={{
          date: props.localeUtils
            .formatWeekdayShort(props.weekday)
            .toUpperCase(),
        }}
        viewPath={`${viewPath}/CalendarHeader`}
      >
        <CalendarHeader viewPath={`${viewPath}/CalendarHeader`} />
      </DataProvider>
    )
  }

  return (
    <View
      viewPath={viewPath}
      renderDay={renderDay}
      weekdayElement={weekdayElement}
    />
  )
}

/**
 *
 * @param {Date} optimalDate
 * @param {{ before?: number, after?: number }} blueBarRange
 * @param {string} timeZoneId
 * @returns {Record<string, boolean>}
 */
function calculateBlueBarDays(optimalDate, blueBarRange, timeZoneId) {
  let before = blueBarRange.before ?? 0
  let after = blueBarRange.after ?? 0

  let today = utcToZonedTime(new Date(), timeZoneId)
  /** @type {Record<string, boolean>} */
  let days = {}

  for (
    let i = 0, date = subDays(optimalDate, 1);
    i < before && isBefore(today, date);
    date = subDays(date, 1)
  ) {
    if (date.getDay() === 0 || date.getDay() === 6) continue
    days[format(date, 'yyyy-MM-dd')] = false
    i++
  }

  days[format(optimalDate, 'yyyy-MM-dd')] = true

  for (
    let i = 0, date = addDays(optimalDate, 1);
    i < after;
    date = addDays(date, 1)
  ) {
    if (date.getDay() === 0 || date.getDay() === 6) continue
    days[format(date, 'yyyy-MM-dd')] = false
    i++
  }

  return days
}
