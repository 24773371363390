// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/Content/Content/Content/Content/DownloadAction/Content/Content/Launchers/Content/App/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_Content_Content_Content_Content_DownloadAction_Content_Content_Launchers_Content_App(
  $id: String!
  $resource_id: uuid!
  $location_id: uuid!
) {
  apps_launchers_resolve(
    id: $id
    resource_id: $resource_id
    location_id: $location_id
  ) {
    ok
    url
    component
    message
  }
}

`