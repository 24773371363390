import { useEffect } from 'react'
import { useDataSubmit, useDataValue } from 'Simple/Data.js'
import View from './view.js'

export default function Logic(props) {
  let submitGlobal = useDataSubmit({
    context: 'global',
    viewPath: props.viewPath,
  })
  let submitOverlay = useDataSubmit({
    context: 'overlay',
    viewPath: props.viewPath,
  })
  let patient_overlay_patient_id = useDataValue({
    context: 'global',
    path: 'patient_overlay_patient_id',
    viewPath: props.viewPath,
  })

  // close patient overlay
  useEffect(() => {
    submitGlobal({ type: 'patientOverlay/close' })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // close on patient overlay open
  useEffect(() => {
    if (patient_overlay_patient_id) {
      submitOverlay()
    }
  }, [patient_overlay_patient_id]) // eslint-disable-line react-hooks/exhaustive-deps

  return <View {...props} />
}
