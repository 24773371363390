import { DEFAULT_TIMEZONE } from 'Data/constants.js'
import { startOfDay } from 'date-fns'
import { toDate, utcToZonedTime } from 'date-fns-tz'
import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let location = useDataValue({
    context: 'global',
    path: 'current_location',
    viewPath: props.viewPath,
  })
  let parent_company_id = useDataValue({
    context: 'global',
    path: 'current_location.parent_company._id',
    viewPath: props.viewPath,
  })

  let tab = useDataValue({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!data) return data

    let time_zone_id = location.time_zone_id || DEFAULT_TIMEZONE
    let date = tab.date
      ? toDate(tab.date, { timeZone: time_zone_id })
      : startOfDay(utcToZonedTime(new Date(), time_zone_id))
    return {
      ...data,
      selected: {
        ...data.selected,
        ...(tab.appointment_booking_location
          ? {
              location_id: tab.appointment_booking_location._id,
              vaxiom_location_id: tab.appointment_booking_location.id,
              location_name: tab.appointment_booking_location.name,
              time_zone_id:
                tab.appointment_booking_location.time_zone_id ??
                DEFAULT_TIMEZONE,
            }
          : {
              location_id: location._id,
              vaxiom_location_id: location.id,
              location_name: location.name,
              time_zone_id,
            }),
        parent_company_id,
        date,
        // static value, do not update this value from outside
        location_current_date: startOfDay(
          utcToZonedTime(new Date(), time_zone_id)
        ),
      },
      sidebar: {
        ...data.sidebar,
        date,
      },
      focused_event_id: tab.appointment_booking_id ?? null,
    }
  }, [
    data,
    location,
    parent_company_id,
    tab.date,
    tab.appointment_booking_id,
    tab.appointment_booking_location,
  ])
}
