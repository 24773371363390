// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/IntegrationsApplications/Content/SyncPatients/Content/SyncPatients/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_IntegrationsApplications_Content_SyncPatients_Content_SyncPatients(
  $sync_id: uuid!
  $matched: [apps_sync_matching_start_matched_item!]!
) {
  apps_sync_matching_start(sync_id: $sync_id, matched: $matched) {
    ok
    sync_id
  }
}

`