// this is an autogenerated file from DesignSystem/CommsThread/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_CommsThread($id: uuid!) {
  comms_threads_by_pk(id: $id) {
    id
    status
    type
    name
    parent_company_id
    # I think this can be done more explicit by type but say it stays, it can be moved too if a subscription?
    inbound_notification
    # move to section
    allowed_to_send_from {
      id
      text
    }
    users {
      id
      # used to tell whether there's an account
      # we should move this to the little label section at least
      account {
        id
        active
        email
      }
      # used in Internal/Participants to get people names, move to that section at least
      vaxiom_person {
        id
        _id
        title
        first_name
        last_name
        birth_date
        patient {
          id
        }
        contact_method_associations(
          where: { contact_method: { dtype: { _eq: "email" } } }
          order_by: [{ sys_created: asc }]
        ) {
          id
          communication_preferences {
            id
            type
          }
          email {
            id
            address
          }
        }
        other_professional_relationships {
          id
        }
        to_person_relationships {
          id
          role
          type
          permitted_to_see_info
          from_person_record {
            id
            _id
            birth_date
            first_name
            last_name
            public_user {
              id
              account {
                id
                active
                email
              }
            }
            contact_method_associations(
              where: { contact_method: { dtype: { _eq: "email" } } }
              order_by: [{ sys_created: asc }]
            ) {
              id
              communication_preferences {
                id
                type
              }
              email {
                id
                address
              }
            }
          }
        }
      }
    }
    # used to send to that number within the action, I wonder if we should use the thread id instead
    # or if we can hard-code the number on the thread since it's always going to be the same
    mobile_numbers_inbound {
      id
      number
    }
    # used to get the last outbound number
    # not sure about the internal one yet
    events(
      limit: 1
      where: { _or: [{ type: { _eq: sms } }, { type: { _eq: internal } }] }
      order_by: { created_at: desc }
    ) {
      id
      internal {
        id
      }
      sms {
        id
        mobile_number_outbound {
          id
          number
          name
        }
      }
    }
  }
}

`