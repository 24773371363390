import { useDataChange, useDataValue } from 'Simple/Data.js'

export default function useDataOnChange(props, data) {
  let change = useDataChange({
    context: 'calendar',
    viewPath: props.viewPath,
    path: 'resource_width',
  })

  let timegrid_width = useDataValue({
    context: 'tab',
    viewPath: props.viewPath,
    path: 'timegrid_width',
  })

  let chairs_in_view = useDataValue({
    context: 'settings',
    viewPath: props.viewPath,
    path: 'chairs_in_view',
  })

  return function onChange(value) {
    if (!chairs_in_view && timegrid_width && value.selected) {
      change(Math.floor((timegrid_width - 112) / value.selected))
    }
  }
}
