import mutationUpsertUserSettings from './mutation-upsert-user-settings.graphql.js'
import mutationUpsertUserPreferences from './mutation-upsert-public-user-preferences.graphql.js'
import mutationUpsertLocationSettings from './mutation-upsert-location-settings.graphql.js'
import { useMutation } from 'Data/Api.js'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let [, executeMutationUpsertUserSettings] = useMutation(
    mutationUpsertUserSettings
  )
  let [, executeMutationUpsertUserPreferences] = useMutation(
    mutationUpsertUserPreferences
  )
  let [, executeMutationUpsertLocationSettings] = useMutation(
    mutationUpsertLocationSettings
  )

  let location_id = useDataValue({
    context: 'tab',
    viewPath: props.viewPath,
    path: 'selected.location_id',
  })

  return async function onSubmit({ args }) {
    switch (args.key) {
      case 'slot_interval':
      case 'chairs_in_view':
      case 'selected_appointment_templates': {
        await executeMutationUpsertUserSettings({
          object: {
            location_id,
            [args.key]: args.value,
          },
          columns_to_update: [args.key],
        })

        break
      }

      case 'blue_bar_range': {
        await executeMutationUpsertLocationSettings({
          object: {
            location_id,
            [args.key]: args.value,
          },
          columns_to_update: [args.key],
        })
        break
      }

      case 'hipaa': {
        await executeMutationUpsertUserPreferences({
          object: {
            [args.key]: args.value,
          },
          columns_to_update: [args.key],
        })

        break
      }

      default:
        break
    }
  }
}
