import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let email = useDataValue({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'email',
  })

  return useMemo(() => {
    if (!data) return data

    let emailInList = data.some(item => item.text === email)
    if (email === null || emailInList) return data

    return [{ id: email, text: email }, ...data]
  }, [data, email])
}
