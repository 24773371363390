import mutation from './mutation-create-tx.graphql.js'
import { useMutation } from 'Data/Api'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  let patient_id = useDataValue({
    context: 'patient',
    viewPath: props.viewPath,
    path: '_id',
  })

  let location_id = useDataValue({
    context: 'global',
    path: 'current_location._id',
    viewPath: props.viewPath,
  })

  return async function onClick() {
    let mutationResponse = await executeMutation({
      patient_id,
      location_id,
    })

    if (mutationResponse.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )

      return
    }

    notify(
      notifySuccess('New treatment is added to the patient.', 'Treatment added')
    )
  }
}
