// this is an autogenerated file from DesignSystem/ImageCard/Content/Content/query-print-image.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_ImageCard_Content_Content($image_id: numeric!) {
  vaxiom_patient_image_print(image_id: $image_id) {
    url
  }
}

`