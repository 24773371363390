import addDays from 'date-fns/addDays/index.js'
import format from 'date-fns/format/index.js'
import differenceInDays from 'date-fns/differenceInDays/index.js'

import parseISO from 'date-fns/parseISO/index.js'

export function getDescription(value) {
  let expiresDate = addDays(
    parseISO(value.connection.connected_at),
    value.connection.app.connection.expiresInDays
  )

  let fomattedExpiresDate = format(expiresDate, 'MMM d, yyyy, h:mm aaa')
  let expiresDays = differenceInDays(expiresDate, new Date())

  if (value.connection.status === 'expired' || expiresDays < 1)
    return `A connection to ${value.connection.app.name} expired on ${fomattedExpiresDate}. It should be reconnected.`

  return `Your connection to ${value.connection.app.name} will reset in ${expiresDays} days on ${fomattedExpiresDate}.`
}
