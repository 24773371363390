// this is an autogenerated file from DesignSystem/ImageCard/Content/Content/Actions/Launchers/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_ImageCard_Content_Content_Actions_Launchers($location_id: uuid!, $resource_id: uuid!) {
  apps_launchers(
    slot: ADMIN_PATIENT_IMAGE
    location_id: $location_id
    resource_id: $resource_id
  ) {
    id
    shortName
    launchers {
      id
      app_id
      icon
      name
      active
      error
      connected
      type
      description
    }
  }
}

`