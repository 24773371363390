import { useMemo } from 'react'
import { formatTimegridAppointmentTime } from 'Data/format.js'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return {
      slots: data.map(slot => {
        let id = `${slot.chair_id}_${slot.date}_${slot.start_min}_${slot.end_min}`

        return {
          ...slot,
          id,
          variant: 'templated',
          chair_name: slot.chair.full_name,
          time_formatted: formatTimegridAppointmentTime({
            start: `${slot.date}T${slot.start_time}`,
            end: `${slot.date}T${slot.end_time}`,
          }),
        }
      }),
      // This allows us to trigger the onChange even if the slots haven't change (like empty array for different dates)
      updated_at: Date.now(),
    }
  }, [data])
}
