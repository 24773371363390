// this is an autogenerated file from DesignSystem/DataVaxiomOtherProfessionalsEdit/Content/Email/Emails/Content/Email/Actions/sendHubInviteMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_DataVaxiomOtherProfessionalsEdit_Content_Email_Emails_Content_Email_Actions(
  $invited_person_id: uuid!
  $email: String!
  $location_id: uuid!
) {
  auth_accounts_practice_hub_invite_professional(
    invited_person_id: $invited_person_id
    email: $email
    location_id: $location_id
  ) {
    ok
  }
}

`