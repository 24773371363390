import addDays from 'date-fns/addDays/index.js'
import differenceInDays from 'date-fns/differenceInDays/index.js'

import parseISO from 'date-fns/parseISO/index.js'

let EXPIRY_THRESHOLD_IN_DAYS = 10

export function getView(value) {
  if (!value.connection?.expiresInDays || value.connections?.length === 0) {
    return false
  }

  return value.connections.some(connection => {
    let expiresDate = addDays(
      parseISO(connection.updated_at),
      value.connection.expiresInDays
    )
    return differenceInDays(expiresDate, new Date()) <= EXPIRY_THRESHOLD_IN_DAYS
  })
}
