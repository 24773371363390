// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/IntegrationsApplications/Content/Connection/Content/Automations/Content/AutomationsTable/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_IntegrationsApplications_Content_Connection_Content_Automations_Content_AutomationsTable($location_id: uuid!, $app_id: uuid!) {
  apps_automations(location_id: $location_id, app_id: $app_id) {
    id
    automations
  }
}

`