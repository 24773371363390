import { useMemo } from 'react'
import { useDataChange } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return
    let launchers = [
      { id: 'SEND_SECURELY', text: 'Send securely' },
      { id: 'EDIT', text: 'Edit' },
      { id: 'COMPARE', text: 'Compare' },
      { id: 'PRINT', text: 'Print' },
      { id: 'DOWNLOAD', text: 'Download' },
      { id: 'DELETE', text: 'Delete' },
      { id: 'ANALYZE', text: 'Analyze' },
    ]
    launchers.push(
      ...data
        .map(app => app.launchers)
        .flat()
        .filter(launcher => launcher.active && !launcher.error)
        .map(launcher => ({ id: launcher.id, text: launcher.name }))
    )

    return launchers
  }, [data])
}
