import { isUnscheduledAppointment } from 'Data/format.js'
import { useMemo } from 'react'
import { addDays, addWeeks, isAfter, max } from 'date-fns'
import { utcToZonedTime, toDate } from 'date-fns-tz'
import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let time_zone_id = useDataValue({
    context: 'tab',
    path: 'selected.time_zone_id',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!data) return

    return [
      {
        id: 'new',
        booking: [],
        approximateDate: calculateApproximateDate(data, null, time_zone_id),
      },
      ...data
        .filter(d => {
          // Check if the appointment is in the future or might have the need to be rescheduled
          if (isUnscheduledAppointment(d.appointment)) return true

          let today = utcToZonedTime(new Date(), time_zone_id)
          let booking_date = toDate(
            `${d.appointment.booking[0].local_start_date}T${d.appointment.booking[0].local_start_time}`,
            { timeZone: time_zone_id }
          )

          // Checks if today's date is not after the booking date of the appointment
          return !isAfter(today, booking_date)
        })
        .map(d => ({
          ...d.appointment,
          approximateDate: calculateApproximateDate(
            data,
            d.appointment._id,
            time_zone_id
          ),
        })),
    ]
  }, [data, time_zone_id])
}

function calculateApproximateDate(events, appointment_id, time_zone_id) {
  let lastBookedAppointmentIndex = -1,
    currentAppointmentIndex = -1
  for (let i = 0; i < events.length; i++) {
    if (events[i].appointment._id === appointment_id) {
      currentAppointmentIndex = i
      break
    }
    if (!isUnscheduledAppointment(events[i].appointment))
      lastBookedAppointmentIndex = i
  }

  let today = utcToZonedTime(new Date(), time_zone_id)
  if (
    currentAppointmentIndex >= 0 &&
    !isUnscheduledAppointment(events[currentAppointmentIndex].appointment)
  ) {
    // Remove this condition if we want to display blue bars
    // when editing the schedule of an already scheduled appointment
    // Would need some logic updates below as well
    return null
  } else if (lastBookedAppointmentIndex === -1) {
    // Scenario: No previous booked appointment present
    return today
  }

  /** Scenario:
   * There are 0 or more unscheduled appointments between the last
   * booked appointment and the appointment we are trying to schedule
   */
  let date = toDate(
    events[lastBookedAppointmentIndex].appointment.booking[0].local_start_date,
    { timeZone: time_zone_id }
  )
  do {
    date = addWeeks(
      date,
      events[lastBookedAppointmentIndex].appointment.interval_to_next
    )
    lastBookedAppointmentIndex++
  } while (lastBookedAppointmentIndex < currentAppointmentIndex)

  let optimalDate = max([date, today])

  // Advance the day if it is a weekend
  while (optimalDate.getDay() === 0 || optimalDate.getDay() === 6) {
    optimalDate = addDays(optimalDate, 1)
  }
  return optimalDate
}
