import { useDataSubmit } from 'Simple/Data.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
  })

  return function onClick() {
    submit({ type: 'schedule' })
  }
}

/**
 *
 * @param {Object} scheduling
 * @param {Object} appointment_overlay
 * @returns
 */
export function isDisabled(scheduling, appointment_overlay) {
  return (
    !appointment_overlay.template_exists_in_location ||
    (!scheduling?.slot_id && !scheduling.untemplated_slot.id)
  )
}
