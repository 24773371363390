import { useMemo } from 'react'
// import formatISO from 'date-fns/formatISO'
import subYears from 'date-fns/subYears'
import startOfToday from 'date-fns/startOfToday'
import isAfter from 'date-fns/isAfter'
import parseISO from 'date-fns/parseISO'
import isValid from 'date-fns/isValid'
import toSentenceCase from 'to-sentence-case'

let YEARS_OLD_ENOUGH = 18
let OLD_ENOUGH = subYears(startOfToday(), YEARS_OLD_ENOUGH)

let STATUS_MESSAGES = {
  HAS_ACCESS: 'Has Hub access',
  ACCESS_GRANTED_PENDING_ACCOUNT_ACTIVATION:
    'Hub access granted. Pending account activation.',
  ACCESS_ALLOWED_SEND_INVITE: 'Hub access allowed. Send invite.',
  NO_ACCESS: 'No Hub access',
  NO_ACCESS_ADD_BIRTH_DATE: 'No Hub access. Add birth date.',
  NO_ACCESS_ADD_EMAIL: 'No Hub access. Add email.',
  NO_ACCESS_ADD_RELATIONSHIP:
    'No Hub access. Underage patient. Add relationship.',
}

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    let send_to_status = null
    let send_to_email = null
    let send_to_invites = null

    if (data.type === 'in_app') {
      send_to_status = STATUS_MESSAGES.NO_ACCESS

      let [user] = data.users
      if (user.account) {
        send_to_status = user.account.active
          ? STATUS_MESSAGES.HAS_ACCESS
          : STATUS_MESSAGES.ACCESS_GRANTED_PENDING_ACCOUNT_ACTIVATION
        send_to_email = user.account.email
      } else if (user.vaxiom_person) {
        let person = user.vaxiom_person

        if (person.patient) {
          let birth_date = parseISO(person.birth_date)

          if (!isValid(birth_date)) {
            send_to_status = STATUS_MESSAGES.NO_ACCESS_ADD_BIRTH_DATE
          } else if (isAfter(birth_date, OLD_ENOUGH)) {
            // Patient isn't old enough
            // TODO: update cache definition to support relations refreshing comms_threads_by_pk
            let relations_permitted_to_see_info_with_accounts =
              person.to_person_relationships.filter(
                item =>
                  item.permitted_to_see_info &&
                  item.from_person_record.public_user?.account
              )

            let relations_permitted_to_see_info_with_email =
              person.to_person_relationships.filter(
                item =>
                  item.permitted_to_see_info &&
                  item.from_person_record.contact_method_associations.length > 0
              )

            if (relations_permitted_to_see_info_with_accounts.length > 0) {
              // TODO: active_pending if any account is !active?
              send_to_status =
                relations_permitted_to_see_info_with_accounts.some(
                  item => item.from_person_record.public_user.account.active
                )
                  ? STATUS_MESSAGES.HAS_ACCESS
                  : STATUS_MESSAGES.ACCESS_GRANTED_PENDING_ACCOUNT_ACTIVATION
              send_to_email = relations_permitted_to_see_info_with_accounts
                .map(
                  item =>
                    `${item.from_person_record.first_name} ${
                      item.from_person_record.last_name
                    } - ${toSentenceCase(item.type)} (${
                      item.from_person_record.public_user.account.email
                    })`
                )
                .join(', ')
            } else if (relations_permitted_to_see_info_with_email.length > 0) {
              // TODO: active_pending if any account is !active?
              send_to_status = STATUS_MESSAGES.ACCESS_ALLOWED_SEND_INVITE
              send_to_email = relations_permitted_to_see_info_with_email
                .map(
                  item =>
                    `${item.from_person_record.first_name} ${
                      item.from_person_record.last_name
                    } - ${toSentenceCase(item.type)} (${
                      item.from_person_record.contact_method_associations[0]
                        .email.address
                    })`
                )
                .join(', ')
              send_to_invites = relations_permitted_to_see_info_with_email.map(
                item => ({
                  invited_person_id: item.from_person_record._id,
                  patient_person_id: person._id,
                  email:
                    item.from_person_record.contact_method_associations[0].email
                      .address,
                })
              )
            } else {
              send_to_status = STATUS_MESSAGES.NO_ACCESS_ADD_RELATIONSHIP
            }
          } else {
            // Patient is old enough, they get their own account
            // TODO: is there a potential gap here if the patient all of the sudden
            // turns 18yo and we stop sending messages to already associated RPs?
            if (person.contact_method_associations.length > 0) {
              let contact_method_association =
                person.contact_method_associations.find(item =>
                  item.communication_preferences.some(
                    item => item.type === 'APPOINTMENT_MESSAGES'
                  )
                ) || person.contact_method_associations[0]

              send_to_email = contact_method_association.email.address
              send_to_status = STATUS_MESSAGES.ACCESS_ALLOWED_SEND_INVITE
              send_to_invites = [
                {
                  invited_person_id: person._id,
                  patient_person_id: person._id,
                  email: send_to_email,
                },
              ]
            } else {
              send_to_status = STATUS_MESSAGES.NO_ACCESS_ADD_EMAIL
            }
          }
        } else if (person.other_professional_relationships.length > 0) {
          // An external professional
          // TODO: update cache definition to support refreshing comms_threads_by_pk
          if (person.contact_method_associations.length > 0) {
            send_to_email = person.contact_method_associations[0].email.address
            send_to_status =
              STATUS_MESSAGES.ACCESS_GRANTED_PENDING_ACCOUNT_ACTIVATION
            send_to_invites = [
              {
                invited_person_id: person._id,
                patient_person_id: null,
                email: send_to_email,
              },
            ]
          } else {
            send_to_status = STATUS_MESSAGES.NO_ACCESS_ADD_EMAIL
          }
        } else {
          // A relation of a patient that isn't a patient themselves
          // TODO: update cache definition to support refreshing comms_threads_by_pk
          if (person.contact_method_associations.length > 0) {
            send_to_email = person.contact_method_associations[0].email.address
            send_to_status = STATUS_MESSAGES.ACCESS_ALLOWED_SEND_INVITE
            send_to_invites = [
              {
                invited_person_id: person._id,
                patient_person_id: null,
                email: send_to_email,
              },
            ]
          } else {
            send_to_status = STATUS_MESSAGES.NO_ACCESS_ADD_EMAIL
          }
        }
      }
    }

    return { ...data, send_to_status, send_to_email, send_to_invites }
  }, [data])
}
