// this is an autogenerated file from DesignSystem/CommsThread/Content/mutation-practice-hub-invite-professional.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_CommsThread_Content(
  $invited_person_id: uuid!
  $email: String!
  $location_id: uuid!
) {
  auth_accounts_practice_hub_invite_professional(
    invited_person_id: $invited_person_id
    email: $email
    location_id: $location_id
  ) {
    ok
  }
}

`