// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/IntegrationsApplications/Content/Connections/Main/Content/Actions/Disconnect/Content/DisconnectAction/mutation-mark-connection-inactive.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_IntegrationsApplications_Content_Connections_Main_Content_Actions_Disconnect_Content_DisconnectAction($id: uuid!) {
  apps_connection_disconnect(id: $id) {
    id
    ok
  }
}

`