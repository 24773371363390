// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentPlans/New/Steps/Content/PaymentPlans/Content/Content/PaymentPlan/Content/Content/Insurances/Insurance/Subscription/Content/Content/Search/data.graphql
import { gql } from 'Data/Api'

export default gql`
# TODO: is this query performant enough? it's searching based on a lot of stuff, so need to check it first
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentPlans_New_Steps_Content_PaymentPlans_Content_Content_PaymentPlan_Content_Content_Insurances_Insurance_Subscription_Content_Content_Search($search_term: String!, $organization_id: numeric!) {
  vaxiom_patient_insurance_plans(
    where: {
      insurance_company: { organization_id: { _eq: $organization_id } }
      _or: [
        { number: { _like: $search_term } }
        { insurance_company: { carrier_name: { _ilike: $search_term } } }
        {
          insurance_plan_employers: {
            employer: { name: { _ilike: $search_term } }
          }
        }
      ]
    }
    order_by: { number: asc, insurance_company: { carrier_name: asc } }
    limit: 20
  ) {
    id
    _id
    number
    insurance_company {
      id
      carrier_name
    }
    insurance_plan_employers {
      employer_id
      employer {
        id
        name
      }
    }
    insurance_billing_center {
      id
      name
    }
  }
}

`