import addDays from 'date-fns/addDays/index.js'
import format from 'date-fns/format/index.js'
import differenceInDays from 'date-fns/differenceInDays/index.js'

import parseISO from 'date-fns/parseISO/index.js'

export function getDescription(value) {
  //one of the connections might be expiring
  let expiring = value.connections.filter(connection => {
    if (connection.status === 'expired') return true

    let expiresDate = addDays(
      parseISO(connection.connected_at),
      value.connection.expiresInDays
    )
    return differenceInDays(expiresDate, new Date()) <= 10
  })[0]

  let expiresDate = addDays(
    parseISO(expiring.connected_at),
    value.connection.expiresInDays
  )
  let fomattedExpiresDate = format(expiresDate, 'MMM d, yyyy, h:mm aaa')
  let expiresDays = differenceInDays(expiresDate, new Date())

  if (expiring.status === 'expired' || expiresDays < 1)
    return `A connection to ${value.app_name} expired on ${fomattedExpiresDate}. It should be reconnected.`

  return `A connection to ${value.app_name} will reset in ${differenceInDays(
    expiresDate,
    new Date()
  )} days on ${fomattedExpiresDate}.`
}
