import { getRemainingAmount } from 'Data/payment-plan.js'

export function getText(payment_plan, treatment_plan) {
  if (!treatment_plan?.tx_plan_template?.tx_plan_group_id) {
    return 'The treatment plan should belong to a group'
  } else if (payment_plan.insurances.some(insurance => !insurance.insured_id)) {
    return 'Please select an insurance subscription'
  } else if (payment_plan.payors.length !== 1) {
    return 'Treatment plan requests could only be sent for a single payor'
  } else if (payment_plan.payors.some(payor => !payor.person_id)) {
    return 'Please select a payor'
  } else if (getRemainingAmount(payment_plan) > 0) {
    return 'Remaining amount is greater than zero'
  } else if (
    payment_plan.insurances.length > 0 &&
    !payment_plan.insurance_code_id
  ) {
    return 'Please select an insurance code'
  } else {
    return 'Creating treatment plan request...'
  }
}
