import { DEFAULT_TIMEZONE } from 'Data/constants.js'
import { useDataSubmit, useDataValue } from 'Simple/Data'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'tab',
    viewPath: props.viewPath,
  })

  let item = useDataValue({
    context: 'item',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({
      type: 'setLocation',
      location_id: item._id,
      vaxiom_location_id: item.id,
      time_zone_id: item?.time_zone_id || DEFAULT_TIMEZONE,
      location_name: item?.name,
      show_loading_overlay: true,
    })
  }
}
