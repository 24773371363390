import arrayToSentence from 'array-to-sentence'
import React, { useContext, useMemo, useReducer } from 'react'

export let SET = 'SET'
export let UPDATE = 'UPDATE'
export let REMOVE = 'REMOVE'
export let OK = 'OK'
export let ERROR = 'ERROR'
export let ALERT = 'ALERT'
export let WARNING = 'WARNING'
export let INFO = 'INFO'

let randomString = () => Math.random().toString(36).substr(7)

let NotificationsContext = React.createContext(
  /** @type {const} */ ([
    [],
    /** @type {(input: {status: ERROR | OK | ALERT | WARNING | INFO, id: string, message: string, action?: null | { text: string, onClick: function }, title?: string | null }, options?: {hideAfter?: number, extendExistingNotification?: boolean}) => void} */ (
      () => {}
    ),
    () => {},
  ])
)

function reducer(state, action) {
  switch (action.type) {
    case SET: {
      return [
        ...state,
        {
          status: action.status,
          message: action.message,
          title: action.title,
          action: action.action,
          id: action.id,
          timeout_id: action.timeout_id,
        },
      ]
    }
    case UPDATE: {
      return state.map(notification =>
        notification.id === action.id
          ? {
              status: action.status,
              message: action.message,
              title: action.title,
              action: action.action,
              id: action.id,
              timeout_id: action.timeout_id,
            }
          : notification
      )
    }

    case REMOVE: {
      return state.filter(notification => notification.id !== action.id)
    }

    default: {
      throw new Error(`Action not implemented ${JSON.stringify(action)}`)
    }
  }
}

export function Notifications(props) {
  let [notifications, dispatch] = useReducer(reducer, [
    // comment out if you want test notifications to design it
    // {
    //   id: 1,
    //   message: 'This is a notification. This is a notification. This is a notification. This is a notification. This is a notification. This is a notification. This is a notification.',
    //   status: 'OK',
    // },
    // {
    //   id: 1,
    //   title: 'Incoming call',
    //   message: 'Garvan Gallagher (505) 444-3424 is calling.',
    //   status: 'OK',
    //   action: {
    //     text: 'Go to patient',
    //     onClick: () => alert('Profile...'),
    //   },
    // },
    // {
    //   id: 2,
    //   message: 'This is a notification error',
    //   status: 'ERROR',
    // },
  ])

  let context = useMemo(() => {
    function notify(
      { status = OK, message, action = null, title = null, id = Date.now() },
      options = {}
    ) {
      let { hideAfter, extendExistingNotification } = {
        hideAfter: 6000,
        extendExistingNotification: false,
        ...options,
      }

      let notification = notifications.find(
        notification =>
          notification.id === id ||
          (notification.status === status && notification.message === message)
      )

      if (extendExistingNotification && notification) {
        clearTimeout(notification.timeout_id)

        let timeout_id = setTimeout(() => {
          close(notification)
        }, hideAfter)

        dispatch({
          type: UPDATE,
          id: notification.id,
          status,
          message,
          action,
          title,
          timeout_id,
        })
      } else {
        let timeout_id = hideAfter
          ? setTimeout(() => {
              close({ id, action })
            }, hideAfter)
          : null

        dispatch({ type: SET, id, status, message, action, title, timeout_id })
      }

      return message
    }

    function close({ id, action = null }) {
      dispatch({ type: REMOVE, id })
      if (typeof action?.onRemove === 'function') {
        ;(async () => {
          try {
            await action.onRemove()
          } catch (_) {}
        })()
      }
    }

    return [notifications, notify, close]
  }, [notifications])

  return (
    <NotificationsContext.Provider value={context}>
      {props.children}
    </NotificationsContext.Provider>
  )
}

export let useNotifications = () => useContext(NotificationsContext)

/** @type {(fields: string[], list: string) => {status: ERROR, id: string, message: string}} */
export let notifyInvalid = (fields, list) => ({
  status: ERROR,
  id: randomString(),
  message: createMessage(fields, list),
})

/** @type {(id: string, payload: object, type?: string | null) => {status: ERROR | WARNING | ALERT | INFO | OK, id: string, message: string, action?: null | { text: string, onClick: function }, title?: string | null }} */
export let systemUserNotification = ({
  id,
  payload,
  type: status = ERROR,
}) => ({
  ...payload,
  status,
  id,
})

/** @type {(message: string | null, title?: string | null, action?: null | { text: string, onClick: function }) => {status: ERROR, id: string, message: string, action?: null | { text: string, onClick: function }, title?: string | null }} */
export let notifyError = (message = null, title = null, action = null) => ({
  status: ERROR,
  title,
  action,
  id: randomString(),
  message: message || 'Something went wrong. Please, try again.',
})

/** @type {(message: string | null, title?: string | null, action?: null | { text: string, onClick: function } ) => {status: OK, id: string, message: string, action?: null | { text: string, onClick: function }, title?: string | null }} */
export let notifySuccess = (message = '', title = null, action = null) => ({
  message,
  title,
  action,
  status: OK,
  id: randomString(),
})

function createMessage(fields, list) {
  switch (list) {
    case 'password':
      return 'Make sure your password has minimum 8 characters, one number, one capital letter, one special character !@&'
    default:
      return `Please check: ${arrayToSentence(fields, {
        lastSeparator: ' & ',
      })}`
  }
}
