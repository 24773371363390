import addDays from 'date-fns/addDays/index.js'
import differenceInDays from 'date-fns/differenceInDays/index.js'

import parseISO from 'date-fns/parseISO/index.js'
let EXPIRY_THRESHOLD_IN_DAYS = 10

export function getView(value) {
  if (!value.connection?.app.connection?.expiresInDays) {
    return false
  }

  let expiresDate = addDays(
    parseISO(value.updated_at),
    value.connection.app.connection?.expiresInDays
  )
  return differenceInDays(expiresDate, new Date()) <= EXPIRY_THRESHOLD_IN_DAYS
}
