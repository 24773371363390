import { useDataSubmit, useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'connection',
    viewPath: props.viewPath,
  })

  let automation_id = useDataValue({
    context: 'automation',
    path: 'automation_id',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'connection',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(next => {
      next.automation_id = automation_id
    })
    submit({ type: 'create_automations' })
  }
}
