import { patientName } from 'Data/format.js'
import { useDataChange, useDataSubmit, useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useListItemDataOnSubmit} */
export default function useListItemDataOnSubmit(props, data) {
  let submit = useDataSubmit({
    context: 'global',
    viewPath: props.viewPath,
  })

  let is_new_calendar_default_enabled = useDataValue({
    context: 'global',
    path: 'feature_flags.is_new_calendar_default_enabled',
    viewPath: props.viewPath,
  })

  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })
  return async function onSubmit({ value, args }) {
    switch (args.type) {
      case 'showPatient': {
        return change(next => {
          next.name = patientName(value.person)
          next.tab = args.tab || 'profile'
        })
      }

      case 'showCalendar': {
        await submit({
          type: 'tabs/add',
          tab: {
            id: 'Calendar',
            name: 'Calendar',
            type: 'Calendar',
            viewPath: '/App/Account/Content/Calendar',
            coreParams: args.coreParams,
            appointment_booking_location: args.appointment_booking_location,
            ...(args.only_show_appointment === true
              ? {
                  date: args.date,
                  appointment_booking_id: args.appointment_booking_id,
                }
              : {}),
          },
        })
        if (is_new_calendar_default_enabled) {
          if (args.only_show_appointment !== true) {
            submit({
              type: 'appointmentOverlay/open',
              patient_id: args.patient_id,
              appointment_id: args.appointment_id,
            })
          } else {
            // This is to handle the case if the appointment overlay was already open,
            // and then we click on "Go to appointment" by going to the tx card. It would
            // close the already open appointment overlay.
            submit({
              type: 'appointmentOverlay/close',
            })
          }
        }
        return
      }

      default: {
      }
    }
  }
}
