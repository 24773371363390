// this is an autogenerated file from DesignSystem/SendPatientSignatureRequestMessage/ContactMethods/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_SendPatientSignatureRequestMessage_ContactMethods($person_id: uuid!) {
  vaxiom_contact_method_associations(
    where: {
      contact_method: { dtype: { _in: ["phone", "email"] } }
      person: { _id: { _eq: $person_id } }
    }
  ) {
    id
    description
    contact_method {
      id
      dtype
      mobile_phone {
        id
        number
      }
    }
  }
}

`