import { useDataValue } from 'Simple/Data.js'
import { dateShortOut } from 'Data/format.js'

export default function useDataConfiguration(props) {
  let date = useDataValue({
    context: 'tab',
    path: 'selected.date',
    viewPath: props.viewPath,
  })
  let location_id = useDataValue({
    context: 'tab',
    path: 'selected.location_id',
    viewPath: props.viewPath,
  })

  let shortDate = dateShortOut(date)

  return {
    variables: {
      date: shortDate,
      location_id,
    },
    pause: !date || !location_id,
    requestPolicy: 'cache-and-network',
  }
}
