import {
  useDataSubmit,
  useDataValue,
  useDataChange,
  useDataFormat,
} from 'Simple/Data.js'
import { format } from 'date-fns-tz'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props) {
  let submitTab = useDataSubmit({
    viewPath: props.viewPath,
    context: 'tab',
  })
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
  })
  let appointment_id = useDataValue({
    context: 'appointment_overlay',
    path: 'appointment_id',
    viewPath: props.viewPath,
  })
  let appointment_type_name = useDataValue({
    context: 'appointment_overlay',
    path: 'appointment_type_name',
    viewPath: props.viewPath,
  })
  let duration = useDataValue({
    context: 'appointment_overlay',
    path: 'duration',
    viewPath: props.viewPath,
  })
  let preselect_scheduling_slot_config = useDataValue({
    context: 'appointment_overlay',
    path: 'preselect_scheduling_slot_config',
    viewPath: props.viewPath,
  })
  let has_calendar_schedule_conflict = useDataValue({
    context: 'global',
    path: 'current_location.permissions.has_calendar_schedule_conflict',
    viewPath: props.viewPath,
  })
  let tabDate = useDataFormat({
    context: 'tab',
    path: 'selected.date',
    format: value => format(value, 'yyyy-MM-dd'),
    viewPath: props.viewPath,
  })
  let tabLocationId = useDataValue({
    context: 'tab',
    path: 'selected.location_id',
    viewPath: props.viewPath,
  })

  return async function onChange(value) {
    if (!value) return

    let slots = value.slots
    let { date, start_min, chair_id, location_id } =
      preselect_scheduling_slot_config
    let selectedSlot = {
      slot_id: null,
      untemplated_slot: null,
    }

    if (
      appointment_id && // we have an appointment
      tabDate === date && // the selected slot is in the same date as tab
      tabLocationId === location_id && // the selected slot belongs to the same tab location
      typeof start_min === 'number' && // the booking has a valid start
      chair_id // the booking has a chair
    ) {
      // Search for selected appointment within array of slots
      let templatedSlot = slots?.find(
        slot =>
          slot.date === date &&
          slot.start_min === start_min &&
          slot.chair_id === chair_id
      )

      // Reset preselect scheduling slot, so it does not affect further interactions
      change(next => {
        next.preselect_scheduling_slot_config = {
          start_min: null,
          date: null,
          chair_id: null,
        }
      })

      if (templatedSlot?.id) {
        selectedSlot.slot_id = templatedSlot.id
      } else if (has_calendar_schedule_conflict) {
        selectedSlot.untemplated_slot = {
          start_min,
          end_min: start_min + duration,
          date,
          chair_id,
        }
      }
    }

    await submitTab({
      type: 'scheduling/setTemplatedSlots',
      slots,
      appointment_id,
      appointment_type_name,
      duration,
      ...selectedSlot,
    })
  }
}
