// this is an autogenerated file from App/Account/Content/Calendar/New/AppointmentOverlay/Content/Form/Content/AppointmentSelect/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Calendar_New_AppointmentOverlay_Content_Form_Content_AppointmentSelect($treatment_id: uuid!) {
  treatments_timeline(
    args: { _treatment_id: $treatment_id }
    where: { type: { _eq: "appointment" } }
  ) {
    id
    appointment {
      id
      _id
      interval_to_next
      duration
      booking {
        id
        _id
        state
        start_time
        local_start_date
        local_start_time
        duration
        location {
          id
          _id
          name
          time_zone_id
        }
        chair {
          id
          _id
          resource {
            id
            organization {
              _id
            }
          }
        }
      }
      type {
        id
        _id
        display_name
        display_color_id
      }
      tx {
        id
        _id
        organization {
          id
          _id
          name
          time_zone_id
        }
      }
    }
  }
}

`