import { useDataChange } from 'Simple/Data.js'
import uuid from 'uuid/v4.js'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'selected',
    viewPath: props.viewPath,
  })
  return function onClick() {
    change(next => {
      next.lead.notes = [
        { id: uuid(), notes: '', createNote: true },
        ...next.lead.notes,
      ]
    })
  }
}
