// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/IntegrationsApplications/Content/Apps/App/ConnectionAction/Connect/OAuth2/Processing/data.graphql
import { gql } from 'Data/Api'

export default gql`
subscription app__App_Account_Content_Settings_AdminContent_SettingsContent_IntegrationsApplications_Content_Apps_App_ConnectionAction_Connect_OAuth2_Processing($connection_id: uuid!) {
  apps_connections_by_pk(id: $connection_id) {
    id
    status
  }
}

`