// this is an autogenerated file from App/Account/PatientOverlay/Content/Content/Appointments/TabContent/Content/AppointmentsList/Content/Appointment/BottomAction/Content/Seat/SeatConfirmationDialog/Content/Assistant/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_PatientOverlay_Content_Content_Appointments_TabContent_Content_AppointmentsList_Content_Appointment_BottomAction_Content_Seat_SeatConfirmationDialog_Content_Assistant($current_location_id: uuid!) {
  vaxiom_employee_resources(
    where: {
      invalid: { _eq: false }
      resource: {
        organization: { _id: { _eq: $current_location_id } }
        resource_type: {
          abbreviation: { _in: ["HYGI", "TC", "ASS", "TL", "MNG"] }
        }
      }
      employment_contract: { employment_end_date: { _is_null: true } }
    }
    order_by: {
      employment_contract: { person: { first_name: asc, last_name: asc } }
    }
  ) {
    id
    employment_contract {
      id
      person {
        id
        first_name
        last_name
      }
    }
  }
}

`