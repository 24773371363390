// this is an autogenerated file from App/Account/Content/Calendar/New/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Calendar_New_Content($location_id: uuid!) {
  calendar_user_settings(where: { location_id: { _eq: $location_id } }) {
    id
    user_id
    location_id
    slot_interval
    selected_appointment_templates
    chairs_in_view
  }
  calendar_location_settings {
    id
    blue_bar_range
  }
  user_preferences {
    id: user_id
    hipaa
  }
}

`