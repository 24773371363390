// this is an autogenerated file from App/Account/PatientOverlay/Content/Content/Appointments/TabContent/Content/AppointmentsList/Content/Appointment/BottomAction/Content/CheckIn/query_patient_due_amount.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_PatientOverlay_Content_Content_Appointments_TabContent_Content_AppointmentsList_Content_Appointment_BottomAction_Content_CheckIn($patient_id: uuid!) {
  financial_patient_due_amount(args: { _patient_id: $patient_id }) {
    id: payment_account_id
    due_now
    due_30_days
    due_60_days
    due_90_days
    due_120_days
    payment_account {
      id
      account_type
    }
  }
}

`