import {
  useDataIsSubmitting,
  useDataSubmit,
  useDataValue,
  useDataChange,
} from 'Simple/Data'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'steps',
    viewPath: props.viewPath,
  })

  let index = useDataValue({
    context: 'selected',
    path: 'index',
    viewPath: props.viewPath,
  })

  let change = useDataChange({
    context: 'connection',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(next => {
      next.automation_id = null
    })
    submit({
      type: 'changeStatus',
      value: 'completed',
      index,
    })
  }
}

export function useIsDisabled(props) {
  let isSubmitting = useDataIsSubmitting({
    context: 'steps',
    viewPath: props.viewPath,
  })

  let value = useDataValue({
    context: 'selected',
    viewPath: props.viewPath,
  })

  return !validate(value) || isSubmitting
}

function validate(value) {
  if (value.step_type === 'automation') {
    return isAutomationValid(value.event)
  }

  if (
    value.step_type === 'preferences' &&
    value.required &&
    value.value === null
  ) {
    return false
  }

  return true
}

function isAutomationValid(event) {
  switch (event.type) {
    case 'APPOINTMENT_BOOKING_STATE_UPDATE': {
      return (
        event.data.appointmentTypeIds.length > 0 &&
        event.data.valueTo.length > 0
      )
    }
    case 'TREATMENT_STATUS_UPDATE': {
      return event.data.valueTo.length > 0
    }
    case 'RESOURCE_INSERT': {
      return event.actionData.taskBasketId
    }
    default: {
      return true
    }
  }
}
