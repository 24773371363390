// this is an autogenerated file from DesignSystem/ImageCard/Content/Content/mutation-launcher.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_ImageCard_Content_Content(
  $id: String!
  $resource_id: uuid!
  $location_id: uuid!
) {
  apps_launchers_resolve(
    id: $id
    resource_id: $resource_id
    location_id: $location_id
  ) {
    ok
    url
    component
    message
  }
}

`