import { useDataValue, useDataChange } from 'Simple/Data.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'

export function useOnClick(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)

  let office = useDataValue({
    context: 'office',
    viewPath: props.viewPath,
  })

  let changeExternalOffice = useDataChange({
    viewPath: props.viewPath,
    context: 'edit_professional',
    path: 'other.external_office',
  })

  let changeCityTerm = useDataChange({
    viewPath: props.viewPath,
    context: 'city_search',
    path: 'term',
  })

  return function onClick() {
    changeExternalOffice(office)
    changeCityTerm(office.postal_address.city)
    setFlowTo(normalizePath(props.viewPath, '../../../../No'))
  }
}
