import { useMemo } from 'react'
import { startOfMonth, endOfMonth, isAfter, addDays } from 'date-fns'
import { format } from 'Data/date.js'
import { useDataValue } from 'Simple/Data'

let COLOR_MAP = {
  GREEN: '#D5FFD6',
  ORANGE: '#FBE7CA',
  PINK: '#FFD9E6',
  BLUE: '#CBECFE',
  YELLOW: '#EEE8B2',
  GRAY: '#CBD2D9',
  WHITE: '#FFFFFF',
  VIOLET: '#E3D5FA',
  OUT_OF_OFFICE: '#F5F7FA',
  CHAIR_CLOSED: '#9AA5B1', // Dark Gray
  UNDEFINED: '#F5F7FA',
}

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let date = useDataValue({
    context: 'sidebar',
    viewPath: props.viewPath,
    path: 'date',
  })
  let sidebar_appointment_templates = useDataValue({
    context: 'tab',
    path: 'selected.sidebar_appointment_templates',
    viewPath: props.viewPath,
  })
  let sidebar_employee_resources = useDataValue({
    context: 'tab',
    path: 'selected.sidebar_employee_resources',
    viewPath: props.viewPath,
  })

  let { first_day_of_month, last_day_of_month } = useMemo(() => {
    if (!date) return
    return {
      first_day_of_month: startOfMonth(date),
      last_day_of_month: endOfMonth(date),
    }
  }, [date])

  return useMemo(() => {
    if (!data) return data

    let contextData = []

    /** @type {Date} */
    let currentDate = first_day_of_month
    while (!isAfter(currentDate, last_day_of_month)) {
      let dateFormat = format(currentDate, 'yyyy-MM-dd')
      let is_weekend = currentDate.getDay() === 6 || currentDate.getDay() === 0

      // Get the metrics for the day if they exist
      let metrics = data?.metrics.filter(
        item => item.office_day_date === dateFormat
      )?.[0]

      // No metrics, no office template defined for the day
      if (!metrics) {
        contextData.push({
          date: dateFormat,
          has_percentage_of_occupancy: false,
          is_office_day: false,
          not_included_in_sidebar_filters: false,
          occupancy_color: getOccupancyColor(null),
          template_color: getOfficeDayTemplateColor({
            office_day: undefined,
            is_weekend,
          }),
        })
        currentDate = addDays(currentDate, 1)
        continue
      }

      // Resources allocated for the day
      let providers_and_assistants = data.allocations?.length
        ? data.allocations
            .filter(allocation => dateFormat === allocation.ca_date)
            .reduce((resources, current) => {
              if (!resources.some(item => item.id === current.resource.id)) {
                resources.push({
                  id: current.resource.id,
                  person:
                    current.resource.employee_resources?.[0]
                      ?.employment_contract?.person,
                  role: current.resource.resource_type.is_provider
                    ? 'Provider'
                    : current.resource.resource_type.is_assistant
                    ? 'Assistant'
                    : '',
                })
              }
              return resources
            }, [])
        : []

      // Check if there are sidebar filters and transparency should be applied
      let not_included_in_sidebar_filters =
        sidebar_appointment_templates.length ||
        sidebar_employee_resources.length

      if (
        sidebar_appointment_templates.length &&
        metrics.appointment_templates?.some(item =>
          sidebar_appointment_templates.includes(item)
        )
      ) {
        not_included_in_sidebar_filters = false
      }

      if (
        not_included_in_sidebar_filters &&
        sidebar_employee_resources.length &&
        providers_and_assistants.some(item =>
          sidebar_employee_resources.includes(item.id)
        )
      ) {
        not_included_in_sidebar_filters = false
      }

      contextData.push({
        date: dateFormat,
        appointments: metrics.appointments ?? 0,
        patients: metrics.patients ?? 0,
        exams: metrics.exams ?? 0,
        starts: metrics.starts ?? 0,
        has_percentage_of_occupancy: metrics.percentage_of_occupancy !== null,
        is_office_day: true,
        providers_and_assistants,
        not_included_in_sidebar_filters,
        occupancy_color: getOccupancyColor(metrics.percentage_of_occupancy),
        template_name: metrics.office_day?.template?.name ?? '',
        template_color: getOfficeDayTemplateColor({
          office_day: metrics.office_day,
          is_weekend,
        }),
      })
      currentDate = addDays(currentDate, 1)
    }

    return contextData
  }, [
    data,
    first_day_of_month,
    last_day_of_month,
    sidebar_appointment_templates,
    sidebar_employee_resources,
  ])
}

function getOccupancyColor(percentage_of_occupancy) {
  if (percentage_of_occupancy === null) return ''

  if (percentage_of_occupancy <= 0.3) return '#3F9142'
  else if (percentage_of_occupancy >= 0.67) return '#BA2525'
  else return '#E9B949'
}

function getOfficeDayTemplateColor({ office_day, is_weekend }) {
  let color = office_day?.template?.color

  // No template and is weekend
  if (!color && is_weekend) return '#9AA5B1'

  // No template
  if (!color) return COLOR_MAP['UNDEFINED']

  // Template's color
  return color in COLOR_MAP ? COLOR_MAP[color] : COLOR_MAP['UNDEFINED']
}
