// this is an autogenerated file from App/Account/Content/Calendar/New/AppointmentOverlay/Content/mutation-create-treatment.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Calendar_New_AppointmentOverlay_Content($patient_id: uuid!, $location_id: uuid!) {
  treatments_create_treatment(
    args: { _patient_id: $patient_id, _location_id: $location_id }
  ) {
    _id
    id
  }
}

`