// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/IntegrationsApplications/Content/Connections/Main/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_IntegrationsApplications_Content_Connections_Main($app_id: uuid!) {
  apps_connections(where: { app_id: { _eq: $app_id } }) {
    id
    display_name
    status
    app_id
    xid
    created_at
    connected_at
    updated_at
    app {
      id
      connection: metadata(path: "connection")
    }
    user {
      id
      vaxiom_person {
        id
        first_name
        last_name
      }
    }
  }
}

`