import {
  addDays,
  addMonths,
  isBefore,
  isPast,
  setDate,
  setDay,
  formatISO,
  parseISO,
  lastDayOfMonth,
} from 'date-fns'
import { useDataChange, useDataValue } from 'Simple/Data.js'
import { isWeekly } from 'Data/payment-plan.js'

export function useOnChange(props) {
  let index = useDataValue({
    context: 'payor_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    change(next => {
      let payor = next.payors[index]
      payor.first_installment_date = value
      if (isWeekly(payor.installment_interval)) {
        // JS days of the week are 0-6, while it's expected to be stored as 1-7
        let day_of_the_week = value === 7 ? 0 : value
        let first_due_date = parseISO(payor.first_due_date)
        let previous_or_same_sunday = setDay(first_due_date, 0)
        let next_or_same_day = setDay(
          previous_or_same_sunday,
          day_of_the_week,
          {
            weekStartsOn: 1,
          }
        )
        if (isBefore(next_or_same_day, first_due_date)) {
          next_or_same_day = addDays(next_or_same_day, 7)
        }
        payor.first_due_date = formatISO(next_or_same_day, {
          representation: 'date',
        })
      } else {
        let first_due_date = parseISO(payor.first_due_date)
        let last_day_of_month = lastDayOfMonth(first_due_date)
        let updated_due_date = setDate(
          first_due_date,
          // preserve the current month, but allow selecting 31 on months on 30 days months, for example
          Math.min(value, last_day_of_month.getDate())
        )
        if (isPast(updated_due_date)) {
          payor.first_due_date = formatISO(addMonths(updated_due_date, 1), {
            representation: 'date',
          })
        } else {
          payor.first_due_date = formatISO(updated_due_date, {
            representation: 'date',
          })
        }
      }
    })
  }
}
