// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentPlans/New/Steps/Content/PaymentPlans/Content/Content/PaymentPlan/Content/Content/SignedContracts/Content/PayorSignedContract/RemoveSignatureAction/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentPlans_New_Steps_Content_PaymentPlans_Content_Content_PaymentPlan_Content_Content_SignedContracts_Content_PayorSignedContract_RemoveSignatureAction_Content($contract_id: numeric!) {
  update_vaxiom_tx_contracts_by_pk(
    pk_columns: { id: $contract_id }
    _set: { signed_date: null, signature_request_id: null }
  ) {
    id
    signed_date
    signature_request_id
  }
}

`