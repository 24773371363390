import { useDataValue, useDataIsSubmitting } from 'Simple/Data.js'

export function useIsDisabled(props) {
  let isSubmitting = useDataIsSubmitting({
    context: 'launcher',
    viewPath: props.viewPath,
  })

  let active = useDataValue({
    context: 'launcher',
    path: 'active',
    viewPath: props.viewPath,
  })

  let error = useDataValue({
    context: 'launcher',
    path: 'error',
    viewPath: props.viewPath,
  })

  return isSubmitting || !active || error
}
