import {
  useDataValue,
  useDataSubmit,
  useDataIsSubmitting,
} from 'Simple/Data.js'
import { useUppy } from 'DesignSystem/FileUploaderProvider/react.js'

export function useDisabled(props) {
  let send_from_id = useDataValue({
    viewPath: props.viewPath,
    context: 'message_compose',
    path: 'send_from_id',
  })

  let submitting = useDataIsSubmitting({
    viewPath: props.viewPath,
    context: 'message_compose',
  })

  let send_to_status = useDataValue({
    viewPath: props.viewPath,
    context: 'thread',
    path: 'send_to_status',
  })

  return !send_from_id || submitting || /inactive/i.test(send_to_status)
}

export function useOnClick(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'message_compose',
  })
  let uppy = useUppy({ viewPath: props.viewPath })

  return function onClick(rstatus) {
    submit({ type: 'sendInternal', uppy, rstatus })
  }
}
