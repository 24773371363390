import { useDataChange } from 'Simple/Data.js'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'event',
    viewPath: props.viewPath,
  })

  return function onClick(event) {
    event.preventDefault()
    change(next => {
      next.selected.showInfoDialog = true
      next.selected.showActionsDialog = false
    })
  }
}

export function useOnContextMenu(props) {
  let change = useDataChange({
    context: 'event',
    viewPath: props.viewPath,
  })

  return function onContextMenu(event) {
    event.preventDefault()
    change(next => {
      next.selected.showInfoDialog = false
      next.selected.showActionsDialog = true
    })
  }
}
