// this is an autogenerated file from App/Account/PatientOverlay/Content/Content/Appointments/TabContent/Content/AppointmentsList/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_PatientOverlay_Content_Content_Appointments_TabContent_Content_AppointmentsList(
  $tx_uuid: uuid!
  $appointment_bool_exp: vaxiom_appointments_bool_exp!
  $created_at_order_by: order_by!
) {
  treatments_timeline(
    args: { _treatment_id: $tx_uuid }
    where: { type: { _eq: "appointment" }, appointment: $appointment_bool_exp }
    order_by: { created_at: $created_at_order_by }
  ) {
    id
    treatment_id
    appointment {
      estimated_scheduling_date
      id
      _id
      duration
      interval_to_next
      has_notes
      type {
        id
        _id
        name
        display_name
        full_name
        color_id
      }
      booking {
        id
        _id
        state
        confirmation_status
        local_start_date
        local_start_time
        start_time
        end_time
        chair_id
        provider_id
        assistant_id
        location {
          id
          _id
          time_zone_id
        }
      }
    }
  }
}

`