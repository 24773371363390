// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentCard/Summary/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentCard_Summary_Content($treatment_id: uuid!) {
  update_vaxiom_txs(
    where: { _id: { _eq: $treatment_id } }
    _set: { is_financials_managed_externally: true }
  ) {
    affected_rows
  }
}

`