import formatDuration from 'date-fns/formatDuration/index.js'
import addWeeks from 'date-fns/addWeeks/index.js'
import intervalToDuration from 'date-fns/intervalToDuration/index.js'
import { useDataValue, useDataChange } from 'Simple/Data.js'

export function getSubtitle(appointments) {
  let weeks = appointments.reduce((acc, item) => acc + item.interval_to_next, 0)
  let now = new Date()
  let futureDate = addWeeks(now, weeks)
  let duration = intervalToDuration({ start: now, end: futureDate })

  return `${formatDuration(duration, {
    format: ['years', 'months', 'weeks'],
  })}`
}

export function useOnClick(props) {
  let id = useDataValue({
    context: 'treatment_plan',
    path: '_id',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'selected',
    path: 'treatment_plan_id',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(id)
  }
}
