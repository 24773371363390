import { useMemo } from 'react'
import { personPhone } from 'Data/format.js'
import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'send_to_person_id',
  })

  return useMemo(() => {
    if (!person_id) return []

    if (!data) return data

    // only allow sending via email if the person has at least one email
    let has_email = data.some(item => item.contact_method.dtype === 'email')
    let has_phone = data.some(
      item =>
        item.contact_method.dtype === 'phone' &&
        item.contact_method.mobile_phone?.number
    )
    let phones = has_phone
      ? data
          .filter(
            item =>
              item.contact_method.dtype === 'phone' &&
              item.contact_method.mobile_phone?.number
          )
          .map(item => ({
            id: item.id,
            type: 'Phone',
            value: item.contact_method.mobile_phone.number,
            text: [
              `Send SMS to ${personPhone(item.contact_method.mobile_phone)}`,
              item.description ? `(${item.description})` : null,
            ]
              .filter(Boolean)
              .join(' '),
          }))
      : [
          {
            id: 'Phone',
            text: 'Send SMS',
            type: 'Phone',
            value: null,
            disabled: true,
          },
        ]

    return [
      {
        id: 'QrCode',
        text: 'Show QR code',
        type: 'QrCode',
        value: null,
      },
      {
        id: 'InApp',
        text: 'Send securely',
        type: 'InApp',
        value: null,
        disabled: !has_email,
      },
      {
        id: 'Email',
        text: 'Send email',
        type: 'Email',
        value: null,
        disabled: !has_email,
      },
      ...phones,
    ]
  }, [data, person_id])
}
