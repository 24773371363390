// this is an autogenerated file from DesignSystem/AppointmentActions/mutation-print-appointment-slip.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_AppointmentActions($id: numeric!) {
  vaxiom_appointment_request_slip(
    appointment_booking_id: $id
    output_format: "html"
  ) {
    slip
  }
}

`