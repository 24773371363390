// this is an autogenerated file from App/Account/Content/Calendar/New/Content/mutation-upsert-public-user-preferences.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Calendar_New_Content(
  $object: user_preferences_insert_input!
  $columns_to_update: [user_preferences_update_column!]!
) {
  insert_user_preferences_one(
    object: $object
    on_conflict: {
      constraint: user_preferences_pkey
      update_columns: $columns_to_update
    }
  ) {
    id: user_id
    hipaa
  }
}

`