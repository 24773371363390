export function getView(
  app_connection,
  connections,
  is_admin,
  app_company_status
) {
  if (!is_admin) return 'No'
  if (app_company_status !== 'granted') return 'Install'
  if (!app_connection) return 'No'

  return connections.length > 0 ? 'Manage' : 'Connect'
}
