// this is an autogenerated file from App/Account/Topbar/Options/Content/Apps/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Topbar_Options_Content_Apps($location_id: uuid!) {
  apps_launchers(slot: ADMIN_GLOBAL, location_id: $location_id) {
    id
    shortName
    launchers {
      id
      app_id
      icon
      name
      active
      error
      connected
      type
      description
      data
    }
  }
}

`