import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { useDataValue } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import { useUppy } from 'design-system/FileUploader/react.js'
import mutation from './mutation.graphql.js'

export function useOnClose(props) {
  let patient_id = useDataValue({
    context: 'patient',
    path: 'id',
    viewPath: props.viewPath,
  })
  let image_series_id = useDataValue({
    context: 'tab',
    path: 'selected.images.image_series_id',
    viewPath: props.viewPath,
  })
  let uppy = useUppy(props)
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  return async function onClose() {
    let attachments = uppy.getFiles()

    if (attachments.some(attachment => !attachment.progress.uploadComplete)) {
      return notify(
        notifyError(
          'Please upload the pending videos or remove them from the list before closing the modal.'
        )
      )
    }

    if (attachments.length) {
      let objects = attachments.map(attachment => ({
        patient_id,
        imageseries_id: image_series_id,
        type_key: 'video',
        slot_key: null,
        url: attachment.meta.location,
        mime_type: attachment.type,
        file_key: attachment.meta.location.substring(
          attachment.meta.location.lastIndexOf('/') + 1
        ),
      }))

      let mutationCreateDocumentResponse = await executeMutation({ objects })

      if (mutationCreateDocumentResponse.error) {
        return notify(
          notifyError(
            'There was a problem storing the videos. Please try again.'
          )
        )
      }
    }

    setFlowTo(normalizePath(props.viewPath, '../../No'))

    // reset Uppy after successfully uploading files
    uppy.cancelAll()
  }
}
