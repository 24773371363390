import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data
    return data.map(item => {
      let uniquePhones = new Set()
      let phones = item.from_person_record.phones
        .map(item => {
          let number = item.contact_method.phone?.number

          if (!number || uniquePhones.has(item.contact_method.phone?.id)) {
            return false
          }
          uniquePhones.add(item.contact_method.phone.id)

          return {
            id: item.id,
            number,
            description: item.description,
            preference: item.preference,
          }
        })
        .filter(Boolean)

      let uniqueAddress = new Set()
      let addresses = item.from_person_record.addresses
        .map(item => {
          let address_line1 = item.contact_method.postal_address?.address_line1
          let city = item.contact_method.postal_address?.city
          let state = item.contact_method.postal_address?.state
          let zip = item.contact_method.postal_address?.zip

          if (
            !address_line1 ||
            !city ||
            !state ||
            !zip ||
            uniqueAddress.has(item.contact_method.postal_address?.id)
          ) {
            return false
          }

          uniqueAddress.add(item.contact_method.postal_address.id)

          return {
            id: item.id,
            address_line1,
            city,
            state,
            zip,
          }
        })
        .filter(Boolean)

      let uniqueEmails = new Set()
      let emails = item.from_person_record.emails
        .map(item => {
          let address = item.contact_method.email?.address

          if (!address || uniqueEmails.has(item.contact_method.email?.id)) {
            return false
          }

          uniqueEmails.add(item.contact_method.email.id)

          return {
            id: item.id,
            address,
            description: item.description,
            preference: item.preference,
          }
        })
        .filter(Boolean)

      return {
        ...item,
        emails,
        phones,
        addresses,
      }
    })
  }, [data])
}
