import { useMemo } from 'react'
import { employeeName } from 'Data/format'
import { addDays, addMonths, subDays, subMonths } from 'date-fns'
import { toDate } from 'date-fns-tz'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let time_zone_id = useDataValue({
    context: 'tab',
    path: 'selected.time_zone_id',
    viewPath: props.viewPath,
  })
  let date = useDataValue({
    context: 'tab',
    path: 'selected.date',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!data) return { chairs: [], open_office_dates: {} }

    // Resources for calendar: all the chairs defined for the location
    // are retrieved in case there are some without configuration
    // but still with appointment bookings at the specified date
    // The decision to display or not the chair is made in children
    // data context -calendar- based on both chair configuration from here and
    // appointment bookings coming from -events- data context
    let chairs =
      data.chairs?.map(item => {
        let allocations =
          item.chair_allocations?.map(ca => ({
            id: ca.resource?.id,
            is_provider: ca.resource?.resource_type?.is_provider,
            is_assistant: ca.resource?.resource_type?.is_assistant,
            name: ca.resource?.employee_resources?.[0]?.employment_contract
              ?.person
              ? employeeName(
                  ca.resource?.employee_resources?.[0]?.employment_contract
                    ?.person
                )
              : '-',
          })) ?? []
        let configuration = item.chair_configurations?.[0]

        return {
          id: item._id,
          vaxiom_id: item.id,
          name: item.full_name,
          position: item.pos,
          allocations,
          office_hours: configuration
            ? configuration.office_and_break_hours.office_hours
            : [],
          appointment_slots: configuration?.appointment_slots ?? [],
        }
      }) ?? []

    // Open office dates: next and previous dates at which the clinic is
    // open from current date to skip closed when using tobar date actions
    // next_day: +1 day
    // next_month: +1 month
    // previous_day: -1 day
    // previous_month: -1 month
    let open_office_dates = data.open_office_dates[0]
      ? {
          next_day: toDate(`${data.open_office_dates[0].next_day}T00:00:00`, {
            timeZone: time_zone_id,
          }),
          next_month: toDate(
            `${data.open_office_dates[0].next_month}T00:00:00`,
            {
              timeZone: time_zone_id,
            }
          ),
          previous_day: toDate(
            `${data.open_office_dates[0].previous_day}T00:00:00`,
            {
              timeZone: time_zone_id,
            }
          ),
          previous_month: toDate(
            `${data.open_office_dates[0].previous_month}T00:00:00`,
            {
              timeZone: time_zone_id,
            }
          ),
        }
      : {
          next_day: addDays(date, 1),
          next_month: addMonths(date, 1),
          previous_day: subDays(date, 1),
          previous_month: subMonths(date, 1),
        }

    return {
      chairs,
      open_office_dates,
    }
  }, [data, time_zone_id, date])
}
