import {
  useNotifications,
  OK,
  ERROR,
  ALERT,
  WARNING,
  INFO,
} from 'Logic/Notifications.js'
import View from './view.js'
import React from 'react'

let TITLES = {
  ERROR: 'Error',
  WARNING: 'Warning',
  ALERT: 'Alert',
  SUCCESS: 'Success',
  OK: 'Success',
  INFO: 'Information',
}

export default function Logic(props) {
  let [notifications, , close] = useNotifications()

  return notifications.map(n => (
    <View
      key={n.id}
      hasAction={Boolean(n.action?.onClick)}
      textAction={n.action?.text}
      onClickAction={n.action?.onClick}
      isError={n.status === ERROR}
      isAlert={n.status === ALERT}
      isWarning={n.status === WARNING}
      isInfo={n.status === INFO}
      isOk={n.status === OK}
      title={n.title || TITLES[n.status]}
      message={n.message}
      onClose={() => close(n)}
      viewPath={props.viewPath}
    />
  ))
}
