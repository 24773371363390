// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentPlans/New/Steps/Content/PaymentPlans/Content/Content/PaymentPlan/Content/Content/Discounts/Content/Discount/Type/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentPlans_New_Steps_Content_PaymentPlans_Content_Content_PaymentPlan_Content_Content_Discounts_Content_Discount_Type($location_id: uuid!) {
  vaxiom_discounts(
    where: {
      organization: { _id: { _eq: $location_id } }
      tx_fee: { _eq: true }
      deleted: { _eq: false }
    }
    order_by: { name: asc_nulls_last }
  ) {
    id
    text: name
    name
    default_amount
    default_percentage
    fixed
    is_included_in_insurance_claim
  }
}

`