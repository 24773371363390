import { useDataSubmit } from 'Simple/Data.js'
import { DEFAULT_APPOINTMENT_DURATION } from 'Data/constants.js'
import { isUnscheduledAppointment, timeToMinutes } from 'Data/format.js'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props) {
  let submit = useDataSubmit({
    context: 'appointment_overlay',
    viewPath: props.viewPath,
  })

  return async function onChange(value) {
    if (!value) return
    let booking = !isUnscheduledAppointment(value) ? value.booking?.[0] : null
    let location = booking?.location || value.tx.organization
    await submit({
      type: 'setExistingAppointment',
      is_external: true,
      id: value._id,
      type_id: value.type._id,
      type_name: value.type.display_name,
      treatment_id: value.tx._id,
      patient_id: value.patient._id,
      duration: value.duration || DEFAULT_APPOINTMENT_DURATION,
      notes: value.notes,
      location: {
        id: location._id,
        vaxiom_id: location.id,
        name: location.name,
        time_zone_id: location.time_zone_id,
      },
      ...(booking
        ? {
            preselect_scheduling_slot_config: {
              start_min: timeToMinutes(booking.local_start_time),
              date: booking.local_start_date,
              chair_id: booking.chair._id,
              location_id: booking.chair.resource.organization._id,
            },
          }
        : {}),
    })
  }
}
