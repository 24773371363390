import { normalizePath, useSetFlowTo } from 'Simple/Flow'
import { useDataSubmit, useDataValue } from 'Simple/Data'

export function useOnClick(props) {
  let setFlow = useSetFlowTo(props.viewPath)
  let steps = useDataValue({
    context: 'steps',
    path: 'steps',
    viewPath: props.viewPath,
  })

  let submit = useDataSubmit({
    context: 'connection',
    viewPath: props.viewPath,
  })
  return function onClick() {
    return steps.length > 0
      ? setFlow(normalizePath(props.viewPath, '../Content'))
      : (submit({ type: 'activate_location_connection' }),
        setFlow(
          normalizePath(props.viewPath, '../../../../../../SetupAutomations/No')
        ))
  }
}

export function getText(steps) {
  return steps.some(step => step.step_type === 'automation')
    ? `This may add automations required for the integration to function properly.`
    : ''
}
