// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/IntegrationsApplications/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_IntegrationsApplications{
  apps_apps(order_by: [{ name: asc }]) {
    id
    app_name: name
    visibility: metadata(path: "visibility")
    name: metadata(path: "name")
    logo: metadata(path: "logo")
    description: metadata(path: "description")
    website: metadata(path: "website")
    connection: metadata(path: "connection")
    sync: metadata(path: "sync")
    automations: metadata(path: "automations")
    tags: metadata(path: "tags")
    connections {
      id
      updated_at
      connected_at
      status
    }
    companies {
      id
      status
    }
  }
}

`