import { useDataChange, useDataValue } from 'Simple/Data.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'

export function useOnClick(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let insurance_plan_id = useDataValue({
    context: 'insurance_plan',
    path: 'id',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'assign',
    path: 'insurance_plan_id',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(insurance_plan_id)
    setFlowTo(normalizePath(props.viewPath, 'Assign/Content'))
  }
}

export function getTitle(insurance_plan) {
  if (insurance_plan.insurance_plan_employers?.length > 0) {
    return insurance_plan.insurance_plan_employers
      .map(item => item.employer.name)
      .join(', ')
  } else {
    return 'No employer'
  }
}

export function getSubtitle(insurance_plan) {
  return [
    insurance_plan.insurance_billing_center?.name ??
      insurance_plan.insurance_company.carrier_name,
    insurance_plan.number,
  ].join(` • `)
}
