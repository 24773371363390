import { useDataSubmit, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'tab',
    viewPath: props.viewPath,
  })

  let app_id = useDataValue({
    context: 'app',
    path: 'id',
    viewPath: props.viewPath,
  })
  let title = useDataValue({
    context: 'app',
    path: 'name',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({ type: 'manage_connections', app_id, title })
  }
}
