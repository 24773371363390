// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentPlans/New/Steps/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentPlans_New_Steps($treatment_id: uuid!) {
  vaxiom_txs(where: { _id: { _eq: $treatment_id } }) {
    id
    _id
    is_treatment_plan_applied
    is_contract_applied
    has_treatment_plans
    has_payment_plans
    treatment_plan_action
    is_financials_managed_externally
    organization {
      id
      _id
      treatment_settings {
        id
        data
      }
    }
  }
}

`