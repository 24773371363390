import { useDataIsSubmitting, useDataFormat } from 'Simple/Data'

export function useDisabled(props) {
  let isSubmitting = useDataIsSubmitting({
    viewPath: props.viewPath,
    context: 'patients',
  })
  let matched = useDataFormat({
    viewPath: props.viewPath,
    context: 'patients',
    format: value => value.some(item => item.selected),
  })

  return isSubmitting || !matched
}
