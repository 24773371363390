// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/PracticeHub/People/Person/Actions/Options/Option/mutation-remove-hub-access.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_PracticeHub_People_Person_Actions_Options_Option($user_id: uuid!) {
  update_users_by_pk(pk_columns: { id: $user_id }, _set: { account_id: null }) {
    id
    account_id
    account {
      id
    }
  }
}

`