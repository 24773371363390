import {
  useDataIsSubmitting,
  useDataSubmit,
  useDataValue,
} from 'Simple/Data.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'app',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({ type: 'connect-api-token' })
  }
}

export function useDisabled(props) {
  let isSubmitting = useDataIsSubmitting({
    context: 'app',
    viewPath: props.viewPath,
  })
  let token = useDataValue({
    context: 'app',
    path: 'token',
    viewPath: props.viewPath,
  })

  return isSubmitting || !token
}
