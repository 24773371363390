import { useDataSubmit, useDataValue } from 'Simple/Data.js'
import { useMutation, useClient } from 'Data/Api.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'

import query from './query.graphql.js'
import mutationCreateRequest from './mutation-create-request.graphql.js'
import mutationCancelRequest from './mutation-cancel-request.graphql.js'
import mutationCreateContract from './mutation-create-contract.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let onCreateRequest = useOnCreateRequest(props)
  let onCancelRequest = useOnCancelRequest(props)
  let onViewContracts = useOnViewContracts(props)

  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'send-request':
        return onCreateRequest({ value, originalValue, args, change })

      case 'cancel-request':
        return onCancelRequest({ value, originalValue, args, change })

      case 'view-contracts':
        return onViewContracts({ value, originalValue, args, change })

      default:
        return
    }
  }
}

function useOnCreateRequest(props) {
  let current_location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location.id',
  })
  let treatment_plan = useDataValue({
    context: 'treatment_plan',
    viewPath: props.viewPath,
  })
  let patient_id = useDataValue({
    context: 'patient',
    path: '_id',
    viewPath: props.viewPath,
  })

  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, executeMutation] = useMutation(mutationCreateRequest)
  let client = useClient()

  return async function onSubmit({ value, args, change }) {
    if (value.payors.length !== 1) return

    let response = await client
      .query(query, { person_id: value.payors[0].person_id })
      .toPromise()
    if (response.error || !response.data?.vaxiom_persons_by_pk) {
      notify(
        notifyError('An error has occurred while saving the treatment plan')
      )
      return
    }

    let mutationResponse = await executeMutation({
      current_location_id,
      treatment_plan_id: treatment_plan._id,
      patient_id,
      person_id: response.data.vaxiom_persons_by_pk._id,
      payment_plan: {
        ...value,
        insurances: getPaymentPlanInsurances(value, treatment_plan),
      },
    })
    if (mutationResponse.error) {
      notify(
        notifyError(
          'There was a problem creating the treatment plan request. Please try again.'
        )
      )
      return
    }

    change(next => {
      next.request_id = mutationResponse.data.treatments_request_create.id
    })
    setFlowTo(normalizePath(props.viewPath, 'Send/Content'))
  }
}

function useOnCancelRequest(props) {
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutationCancelRequest)

  return async function onSubmit({ value, originalValue, args, change }) {
    let mutationResponse = await executeMutation({
      treatment_plan_id: args.treatment_plan_id,
      person_id: args.person_id,
    })
    if (mutationResponse.error) {
      notify(
        notifyError(
          'There was a problem cancelling the treatment plan request. Please try again.'
        )
      )
      return
    }

    change(next => {
      next.request_id = null
    })

    notify(notifySuccess('Treatment plan request cancelled successfully'))
  }
}

function useOnViewContracts(props) {
  let treatment_plan = useDataValue({
    context: 'treatment_plan',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'wizard',
    viewPath: props.viewPath,
  })

  let [, notify] = useNotifications()
  let [, executeMutationCreateContract] = useMutation(mutationCreateContract)

  return async function onSubmit({ value, args, change }) {
    let results = await Promise.all(
      value.payors.map(payor =>
        executeMutationCreateContract({
          treatment_plan_id: treatment_plan.id,
          person_id: payor.person_id,
        })
      )
    )

    if (results.some(result => result?.error)) {
      notify(
        notifyError(
          'There was a problem creating the contract. Please try again.'
        )
      )
      return
    }

    submit({
      type: 'view-contracts',
      treatment_plan_id: treatment_plan.id,
    })
  }
}

function getPaymentPlanInsurances(payment_plan) {
  return payment_plan.insurances.map(insurance => ({
    insured_id: insurance.insured_id,
    estimated_reimbursement_amount: insurance.estimated_reimbursement_amount,
    is_primary: insurance.is_primary,
    downpayment_amount: insurance.downpayment_amount,
    installment_amount: insurance.installment_amount,
  }))
}
