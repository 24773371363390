import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let apps = useDataValue({
    context: 'apps',
    viewPath: props.viewPath,
  })
  let app_id = useDataValue({
    context: 'tab',
    path: 'selected.app_id',
    viewPath: props.viewPath,
  })
  let location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location._id',
  })
  return useMemo(() => {
    if (!apps) return null
    let app = apps.find(item => item.id === app_id)
    if (!app) return null

    if (!data || data.length === 0) {
      return {
        resource_id: location_id,
        connection_id: null,
        connection: null,
        xid: app.xid,
      }
    }

    return data[0]
  }, [app_id, apps, data, location_id])
}
