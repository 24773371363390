import { useDataSubmit } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let submitGlobal = useDataSubmit({
    context: 'global',
    viewPath: props.viewPath,
  })
  let submitCalendar = useDataSubmit({
    context: 'tab',
    viewPath: props.viewPath,
  })

  // Function to close the appointment overlay
  return async function onSubmit({ value, args, originalValue, change }) {
    if (value.is_global_call) {
      submitGlobal({ type: 'appointmentOverlay/close' })
      submitCalendar({ type: 'scheduling/reset', optimal_date: null })
    } else {
      submitCalendar({ type: 'appointmentOverlay/close' })
    }
  }
}
