// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/PracticeHub/InviteAction/Content/Person/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_PracticeHub_InviteAction_Content_Person($person_id: uuid!, $old_enough: date!) {
  vaxiom_persons(
    where: {
      _or: [
        { _id: { _eq: $person_id }, birth_date: { _lte: $old_enough } }
        {
          _or: [
            { birth_date: { _is_null: true } }
            { birth_date: { _lte: $old_enough } }
          ]
          from_person_relationships: {
            to_person_record: { _id: { _eq: $person_id } }
          }
        }
      ]
    }
    order_by: { search_name: asc }
  ) {
    id
    _id
    first_name
    greeting
    last_name
    profile_pic_url
    from_person_relationships(
      where: { to_person_record: { _id: { _eq: $person_id } } }
    ) {
      id
      type
      role
      permitted_to_see_info
    }
    public_user {
      id
      account {
        id
        email
      }
    }
  }
}

`