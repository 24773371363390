// this is an autogenerated file from App/Account/Content/Marketing/ReportTable/Content/Content/mutation-delete-note.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Marketing_ReportTable_Content_Content($note_id: uuid!) {
  delete_leads_notes_by_pk(id: $note_id) {
    id
  }
}

`