import View from './view.js'
import React from 'react'
import { useDataIsSubmitting } from 'Simple/Data'

export default function Logic(props) {
  let isSubmitting = useDataIsSubmitting({
    viewPath: props.viewPath,
    context: 'ruleset',
  })

  return <View {...props} disabled={isSubmitting} />
}
