import { useMemo } from 'react'
import { patientName } from 'Data/format.js'
import toPascalCase from 'to-pascal-case'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return []

    return data
      .filter(item => {
        let [from_person_relationship] = item.from_person_relationships
        return (
          item.public_user === null ||
          item.public_user.account === null ||
          (from_person_relationship &&
            from_person_relationship.permitted_to_see_info === false)
        )
      })
      .map(item => {
        let text = patientName(item)
        let [from_person_relationship] = item.from_person_relationships
        if (from_person_relationship) {
          text = `${text} (${toPascalCase(
            from_person_relationship.type
          )} - ${toPascalCase(from_person_relationship.role)})`
        } else {
          text = `${text} (Patient)`
        }

        return {
          id: item._id,
          text,
          email: item.public_user?.account?.email || null,
          already_has_account: item.public_user?.account || false,
          permitted_to_see_info:
            from_person_relationship?.permitted_to_see_info || false,
        }
      })
  }, [data])
}
