import {
  useDataIsSubmitting,
  useDataFormat,
  useDataSubmit,
  useDataValue,
} from 'Simple/Data.js'
import { getRemainingAmount } from 'Data/payment-plan.js'

export function useIsDisabled(props) {
  let isSubmitting = useDataIsSubmitting({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })
  let isMissingInsurance = useDataFormat({
    context: 'payment_plan',
    path: 'insurances',
    // couldn't save if there is any insurance without a selected subscription
    format: insurances => insurances.some(insurance => !insurance.insured_id),
    viewPath: props.viewPath,
  })
  let isMissingPayor = useDataFormat({
    context: 'payment_plan',
    path: 'payors',
    // couldn't save if there is no payor selected
    format: payors => payors.some(payor => !payor.person_id),
    viewPath: props.viewPath,
  })
  let has_insurances = useDataFormat({
    context: 'payment_plan',
    path: 'insurances',
    format: insurances => insurances.length > 0,
    viewPath: props.viewPath,
  })
  let insurance_code_id = useDataValue({
    context: 'payment_plan',
    path: 'insurance_code_id',
    viewPath: props.viewPath,
  })
  let has_remaining_amount = useDataFormat({
    context: 'payment_plan',
    format: payment_plan => getRemainingAmount(payment_plan) > 0,
    viewPath: props.viewPath,
  })
  let has_case_closer_request = useDataValue({
    context: 'payment_plan',
    path: 'request_id',
    viewPath: props.viewPath,
  })

  return (
    isSubmitting ||
    isMissingInsurance ||
    isMissingPayor ||
    has_remaining_amount ||
    has_case_closer_request ||
    (has_insurances && !insurance_code_id)
  )
}

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({ type: 'view-contracts' })
  }
}
