import { useDataValue, useDataChange, useDataSubmit } from 'Simple/Data.js'
import {
  changeInsurancesEstimatedReimbursementAmount,
  changePayorsShare,
  changeDiscountsAppliedToPayorsShare,
  changePayorsConfiguration,
} from 'Data/payment-plan.js'

export function useOnClick(props) {
  let insured_id = useDataValue({
    context: 'insured',
    path: 'id',
    viewPath: props.viewPath,
  })
  let treatment_plan = useDataValue({
    context: 'treatment_plan',
    viewPath: props.viewPath,
  })
  let insurance_subscriptions = useDataValue({
    context: 'insurance_subscriptions',
    viewPath: props.viewPath,
  })
  let index = useDataValue({
    context: 'insurance_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'selected',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(next => {
      let insurance_subscription = insurance_subscriptions.find(
        item => item.id === insured_id
      )

      let insurance = next.insurances[index]
      insurance.insured_id = insured_id
      insurance.insured = insurance_subscription
      // marking it as not overriden to recalculate the downpayment and installment amount
      insurance.is_overridden = false

      changeInsurancesEstimatedReimbursementAmount(next, treatment_plan)
      changePayorsShare(next)
      changeDiscountsAppliedToPayorsShare(next)
      changePayorsConfiguration(next)
    })

    submit({ type: 'close-popover' })
  }
}

export function getName(insured) {
  let employer_name = insured.insurance_subscription.employer
    ? `, ${insured.insurance_subscription.employer.name}`
    : ''
  return `${insured.insurance_subscription.person.first_name} ${insured.insurance_subscription.person.last_name}${employer_name}`
}

export function getSubtitle(insured) {
  return [
    insured.insurance_subscription.insurance_plan.insurance_billing_center
      ?.name ??
      insured.insurance_subscription.insurance_plan.insurance_company
        .carrier_name,
    insured.insurance_subscription.insurance_plan.number,
  ].join(` • `)
}
