import React, { useEffect, useRef } from 'react'
import View from './view.js'
import useIsHovered from 'Logic/useIsHovered.js'
import {
  useFloating,
  useClientPoint,
  offset,
  shift,
  autoPlacement,
} from '@floating-ui/react'
import { useDataChange, useDataSubmit, useDataValue } from 'Simple/Data.js'

export default function Logic(props) {
  /** @type {React.MutableRefObject<NodeJS.Timeout | null>} */
  let timerRef = useRef(null)
  /** @type {React.MutableRefObject<boolean>} */
  let isClosingRef = useRef(false)
  /** @type {React.MutableRefObject<NodeJS.Timeout | null>} */
  let highlightTimerRef = useRef(null)

  let [hovered, , hoverBind] = useIsHovered({
    isDisabled: false,
    isSelected: false,
    onMouseEnter: undefined,
    onMouseLeave: undefined,
  })

  let event = useDataValue({
    context: 'event',
    viewPath: props.viewPath,
  })
  let open_dialog_near_click = useDataValue({
    context: 'timegrid',
    path: 'open_dialog_near_click',
    viewPath: props.viewPath,
  })

  let change = useDataChange({
    context: 'event',
    viewPath: props.viewPath,
  })

  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'tab',
  })

  let { refs, x, y, context, update } = useFloating({
    open: event.selected.showInfoDialog || event.selected.showActionsDialog,
    placement: 'right-start',
    strategy: 'fixed',
    middleware: [autoPlacement(), offset(-12), shift()],
  })

  let { reference } = useClientPoint(context, {
    enabled:
      !event.selected.showInfoDialog &&
      !event.selected.showActionsDialog &&
      open_dialog_near_click,
  })

  useEffect(() => {
    if (event.shouldHighlight) {
      highlightTimerRef.current = setTimeout(() => {
        submit({ type: 'removeFocus' })
      }, 3000)
    }

    // Cleanup function
    return () => {
      if (highlightTimerRef.current) {
        clearTimeout(highlightTimerRef.current)
        submit({ type: 'removeFocus' })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event.shouldHighlight])

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [])

  return (
    <View
      {...props}
      hovered={hovered}
      onMouseEnter={event => {
        isClosingRef.current = false
        hoverBind.onMouseEnter()
        if (reference && typeof reference.onMouseEnter !== 'undefined')
          reference.onMouseEnter(event)
      }}
      onMouseMove={event => {
        if (reference && typeof reference.onMouseMove !== 'undefined')
          reference.onMouseMove(event)
      }}
      onMouseLeave={() => {
        hoverBind.onMouseLeave()

        isClosingRef.current = true

        if (timerRef.current) clearTimeout(timerRef.current)
        timerRef.current = setTimeout(() => {
          // Only close if we're still in closing state
          if (isClosingRef.current) {
            change(next => {
              next.selected.showInfoDialog = false
              next.selected.showActionsDialog = false
            })
          }
        }, 400)
      }}
      onMouseEnterContent={() => {
        isClosingRef.current = false
        if (timerRef.current) {
          clearTimeout(timerRef.current)
          timerRef.current = null
        }
      }}
      triggerRef={refs.setReference}
      contentRef={refs.setFloating}
      left={x}
      top={y}
      update={update}
    />
  )
}
