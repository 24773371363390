// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/IntegrationsApplications/Content/Connection/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_IntegrationsApplications_Content_Connection($location_id: uuid!, $app_id: uuid!) {
  apps_connection_resources(
    where: {
      # depending on the app's configuration, either parent company level or location level connections will be created
      resource_id: { _eq: $location_id }
      connection: { app_id: { _eq: $app_id } }
    }
  ) {
    id
    status
    connection_id
    resource_id
    xid
    updated_at
    connection {
      id
      display_name
      status
      app_id
      connected_at
      xid
      app {
        id
        name
        tags: metadata(path: "tags")
        connection: metadata(path: "connection")
        properties: metadata(path: "connection.properties")
        automations: metadata(path: "automations")
      }
    }
  }
}

`