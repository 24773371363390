import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnChange(props) {
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'selected',
  })
  let people = useDataValue({
    viewPath: props.viewPath,
    context: 'people',
  })

  return function onChange(value) {
    change(next => {
      if (value === null) {
        next.person_id = null
        next.email = null
        next.permitted_to_see_info = false
        next.already_has_account = false
      } else {
        let item = people.find(item => item.id === value)
        next.person_id = value
        next.email = item.email
        next.already_has_account = item.already_has_account
        next.permitted_to_see_info = item.permitted_to_see_info
      }
    })
  }
}
