// this is an autogenerated file from DesignSystem/EditAutomation/EventActions/Content/EventAction/Content/Content/TaskInsert/DocumentTemplate/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_EditAutomation_EventActions_Content_EventAction_Content_Content_TaskInsert_DocumentTemplate($organization_id: uuid!) {
  vaxiom_document_templates(
    where: { organization: { _id: { _eq: $organization_id } } }
    order_by: { name: asc }
  ) {
    id
    text: name
  }
}

`