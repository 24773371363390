import { useNotifications, notifySuccess } from 'Logic/Notifications.js'
import { useDataSubmit } from 'Simple/Data.js'

export default function useListItemDataOnSubmit(props, data) {
  let [, notify] = useNotifications()
  let submit = useDataSubmit({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return async function onSubmit({ value, args, change }) {
    change(next => {
      next.connecting = false
    })
    submit({ type: 'manage', app_id: value.id, title: value.name })

    return notify(notifySuccess(`The app is connected`, value.name))
  }
}
