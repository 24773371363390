export function getText(connection_name, location_name, steps) {
  let has_preferences = steps.some(step => step.step_type === 'preferences')
  let has_automations = steps.some(step => step.step_type === 'automation')

  if (has_preferences && has_automations) {
    return `${connection_name} - ${location_name} preferences & automations`
  }
  if (has_automations) {
    return `${connection_name} - ${location_name} automations`
  }
  if (has_preferences) {
    return `${connection_name} - ${location_name} preferences`
  }
  return `${connection_name} - ${location_name}`
}
