// this is an autogenerated file from App/Account/Content/Marketing/ReportTable/Content/Content/Content/Detail/Content/Notes/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Marketing_ReportTable_Content_Content_Content_Detail_Content_Notes(
  $note_id: uuid!
  $lead_id: uuid!
  $notes: String!
  $create_note: Boolean!
  $update_note: Boolean!
) {
  insert_leads_notes_one(object: { lead_id: $lead_id, notes: $notes })
    @include(if: $create_note) {
    id
  }
  update_leads_notes_by_pk(
    pk_columns: { id: $note_id }
    _set: { notes: $notes }
  ) @include(if: $update_note) {
    id
  }
}

`