import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let app_id = useDataValue({
    context: 'connection_definition',
    path: 'app_id',
    viewPath: props.viewPath,
  })
  let location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location._id',
  })

  return {
    variables: {
      location_id,
      app_id,
    },
    pause: false,
    requestPolicy: 'cache-and-network',
  }
}
