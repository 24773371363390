import { useDataSubmit, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'global',
  })
  let global = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
  })
  let patient_id = useDataValue({
    viewPath: props.viewPath,
    context: 'appointment_actions',
    path: 'patient.id',
  })
  let appointment_id = useDataValue({
    viewPath: props.viewPath,
    context: 'appointment_actions',
    path: 'appointment.id',
  })
  let is_patient_overlay_call = useDataValue({
    viewPath: props.viewPath,
    context: 'appointment_actions',
    path: 'is_patient_overlay_call',
  })

  return async function onClick() {
    if (global.tab_id !== 'Calendar') {
      await submit({
        type: global.tabs.includes('Calendar') ? 'tabs/select' : 'tabs/add',
        tab: {
          id: 'Calendar',
          name: 'Calendar',
          type: 'Calendar',
          viewPath: '/App/Account/Content/Calendar',
        },
      })
    }
    submit({
      type: 'appointmentOverlay/open',
      patient_id,
      appointment_id,
      is_patient_overlay_call,
    })
  }
}
