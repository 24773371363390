import { useDataSubmit } from 'Simple/Data.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

export function useOnClick(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let submit = useDataSubmit({
    context: 'patient_image',
    viewPath: props.viewPath,
  })

  return async function onClick(value) {
    switch (value) {
      case 'SEND_SECURELY':
        return sendSecurely()
      case 'EDIT':
        return editImage()
      case 'COMPARE':
        return compareImages()
      case 'PRINT':
        return print()
      case 'DOWNLOAD':
        return download()
      case 'DELETE':
        return deleteImage()
      case 'ANALYZE':
        return analyzeCeph()
      default:
        return launchers(value)
    }
  }

  function deleteImage() {
    setFlowTo(normalizePath(props.viewPath, 'Delete/Content'))
  }

  function editImage() {
    setFlowTo(normalizePath(props.viewPath, 'Edit/EditModal/Content'))
  }

  function compareImages() {
    setFlowTo(normalizePath(props.viewPath, 'Compare/Content'))
  }

  function sendSecurely() {
    setFlowTo(normalizePath(props.viewPath, 'SendSecurely/Content'))
  }

  function launchers(launcher_id) {
    return submit({ type: 'launcher', launcher_id })
  }

  function analyzeCeph() {
    return submit({ type: 'analyze' })
  }

  async function print() {
    return submit({ type: 'print' })
  }

  async function download() {
    return submit({ type: 'download' })
  }
}

export function getThreeDotsMenu(items, layout, isSupport, audaxCephEnabled) {
  return items.filter(item => {
    return (
      item.id !== 'ANALYZE' ||
      (item.id === 'ANALYZE' &&
        layout === 'xRayCephalometric' &&
        (audaxCephEnabled || isSupport))
    )
  })
}
