import { useDataSubmit, useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let submit = useDataSubmit({
    context: 'tab',
    viewPath: props.viewPath,
  })

  let app_id = useDataValue({
    context: 'app',
    path: 'id',
    viewPath: props.viewPath,
  })

  return async function onChange(next) {
    if (next?.app_id === app_id) {
      submit({ type: 'manage', ...next })
    }
  }
}
