// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/PracticeHub/InviteAction/Content/Email/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_PracticeHub_InviteAction_Content_Email($person_ids: [uuid!]!) {
  vaxiom_contact_emails(
    where: {
      contact_method: { association: { person: { _id: { _in: $person_ids } } } }
    }
    distinct_on: [address]
  ) {
    id
    text: address
  }
}

`