import { useDataValue } from 'Simple/Data'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { useMutation } from 'Data/Api'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import mutation_edit_preferences from './editPreferencesMutation.graphql.js'
import mutation_send_hub_invite from './sendHubInviteMutation.graphql.js'

export function useOnClick(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()

  let [, executeEditPreferencesMutation] = useMutation(
    mutation_edit_preferences
  )
  let [, executeSendHubInviteMutation] = useMutation(mutation_send_hub_invite)

  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'edit_professional',
    path: 'other.person.id',
  })
  let invited_person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'edit_professional',
    path: 'other.person._id',
  })

  let email = useDataValue({
    viewPath: props.viewPath,
    context: 'email',
  })

  // send hub invite requires numeric location id
  let location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location._id',
  })

  return function onClick(action) {
    switch (action) {
      case 'DELETE':
        return deleteAddress()
      case 'EDIT':
        return editAddress()
      case 'SET_PREFERRED':
        return setPreferred()
      case 'SEND_HUB_INVITE':
        return sendHubInvite()
      default:
        return
    }
  }

  function deleteAddress() {
    setFlowTo(normalizePath(props.viewPath, '../Delete/Content'))
  }

  function editAddress() {
    setFlowTo(normalizePath(props.viewPath, '../Edit/Content'))
  }

  async function setPreferred() {
    let mutationEditPreferencesResponse = await executeEditPreferencesMutation({
      id: email.id,
      preference: 'ANY',
      person_id,
    })
    if (mutationEditPreferencesResponse.error) {
      notify(notifyError('Something went wrong. Please, try again.'))
      return
    }
  }

  async function sendHubInvite() {
    let mutationResponse = await executeSendHubInviteMutation({
      invited_person_id,
      email: email.email.address,
      location_id,
    })

    if (
      mutationResponse.error ||
      !mutationResponse.data.auth_accounts_practice_hub_invite_professional.ok
    ) {
      notify(notifyError('Failed to send invite.'))
    } else {
      notify(notifySuccess('Invite sent!'))
    }
  }
}
