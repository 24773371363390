export function getView(status) {
  switch (status) {
    case 'active':
      return 'Connected'
    case 'inactive':
      return 'Disconnected'
    case 'error':
    case 'error_refresh':
    case 'expired':
      return 'Error'
    default:
      return 'No'
  }
}
