// this is an autogenerated file from App/Account/Content/Calendar/New/Content/Content/CollapsibleSidebar/LocationsChooser/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Calendar_New_Content_Content_CollapsibleSidebar_LocationsChooser{
  session_user_locations(order_by: { name: asc }) {
    id
    _id
    name
    time_zone_id
  }
}

`