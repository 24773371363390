import { useDataValue, useDataSubmit } from 'Simple/Data.js'
import { timeToMinutes } from 'Data/format.js'
import { DEFAULT_APPOINTMENT_DURATION } from 'Data/constants.js'
import { isUnscheduledAppointment } from 'Data/format.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
  })
  let appointment = useDataValue({
    context: 'appointment',
    viewPath: props.viewPath,
  })
  let selected = useDataValue({
    context: 'appointment_overlay',
    path: 'appointment_id',
    viewPath: props.viewPath,
  })

  return () => {
    if (appointment._id === selected) return
    if (appointment.id === 'new') {
      submit({
        type: 'setNewAppointment',
        approximateDate: appointment.approximateDate,
      })
    } else {
      let location = !isUnscheduledAppointment(appointment)
        ? appointment.booking[0].location
        : appointment.tx.organization
      submit({
        type: 'setExistingAppointment',
        id: appointment._id,
        type_id: appointment.type._id,
        type_name: appointment.type.display_name,
        treatment_id: appointment.tx._id,
        duration: appointment.duration || DEFAULT_APPOINTMENT_DURATION,
        approximateDate: appointment.approximateDate,
        location: {
          id: location._id,
          vaxiom_id: location.id,
          name: location.name,
          time_zone_id: location.time_zone_id,
        },
        ...(!isUnscheduledAppointment(appointment)
          ? {
              preselect_scheduling_slot_config: {
                start_min: timeToMinutes(
                  appointment.booking[0].local_start_time
                ),
                date: appointment.booking[0].local_start_date,
                chair_id: appointment.booking[0].chair._id,
                location_id:
                  appointment.booking[0].chair.resource.organization._id,
              },
              duration: appointment.booking[0].duration,
            }
          : {}),
      })
    }
    props.closePopover?.()
  }
}
