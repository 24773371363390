// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/PracticeHub/People/Person/Actions/Options/Option/mutation-resend-invite.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_PracticeHub_People_Person_Actions_Options_Option(
  $patient_person_id: uuid!
  $invited_person_id: uuid!
  $email: String!
  $location_id: uuid!
) {
  auth_accounts_practice_hub_invite_patient(
    patient_person_id: $patient_person_id
    invited_person_id: $invited_person_id
    email: $email
    location_id: $location_id
    permitted_to_see_info: true
    send_patient_reminders_to_email: true
  ) {
    ok
  }
}

`