import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useDataValue, useDataChange } from 'Simple/Data.js'
import {
  DOWNPAYMENT_TYPE,
  getInsuranceInstallmentAmount,
  changeInsurancesEstimatedReimbursementAmount,
  changePayorsShare,
  changeDiscountsAppliedToPayorsShare,
  changePayorsConfiguration,
} from 'Data/payment-plan.js'

let DEFAULT_PERCENTAGE = 0.25

export function useOnClick(props) {
  let estimated_reimbursement_amount = useDataValue({
    context: 'edit_insurance',
    path: 'estimated_reimbursement_amount',
    viewPath: props.viewPath,
  })
  let treatment_plan = useDataValue({
    context: 'treatment_plan',
    viewPath: props.viewPath,
  })
  let index = useDataValue({
    context: 'insurance_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)

  return function onClick() {
    change(next => {
      let insurance = next.insurances[index]
      insurance.estimated_reimbursement_amount = estimated_reimbursement_amount
      insurance.is_overridden = true

      // recalculate downpayment and reimbursement amount

      insurance.downpayment_amount = Math.min(
        getDownpaymentAmount(insurance, estimated_reimbursement_amount),
        estimated_reimbursement_amount
      )
      insurance.installment_amount = getInsuranceInstallmentAmount(
        insurance,
        treatment_plan,
        estimated_reimbursement_amount,
        insurance.downpayment_amount
      )

      changeInsurancesEstimatedReimbursementAmount(next, treatment_plan)
      changePayorsShare(next)
      changeDiscountsAppliedToPayorsShare(next)
      changePayorsConfiguration(next)
    })

    setFlowTo(normalizePath(props.viewPath, '../../No'))
  }
}

function getDownpaymentAmount(insurance, estimated_reimbursement_amount) {
  let ortho_coverage =
    insurance.insured.insurance_subscription.insurance_plan
      .tx_category_coverages.ortho_coverages
  if (ortho_coverage.downpayment_type === DOWNPAYMENT_TYPE.FIXED) {
    return ortho_coverage.downpayment_amount
  } else {
    let percentage = ortho_coverage.downpayment_percentage || DEFAULT_PERCENTAGE
    return parseFloat((estimated_reimbursement_amount * percentage).toFixed(2))
  }
}
