import React, { useEffect } from 'react'
import View from './view.js'
import { useFloating, shift, autoPlacement } from '@floating-ui/react-dom'
import { useDataValue } from 'Simple/Data.js'

export default function Logic(props) {
  let is_dialog_open = useDataValue({
    context: 'slot_dialog',
    viewPath: props.viewPath,
    path: 'is_dialog_open',
  })

  let view = useDataValue({
    context: 'slot_dialog',
    viewPath: props.viewPath,
    path: 'view',
  })

  let referenceElement = document.querySelector('.fc-highlight')

  let { x, y, refs, update } = useFloating({
    open: is_dialog_open,
    strategy: 'fixed',
    elements: {
      reference: referenceElement,
    },
    middleware: [autoPlacement(), shift({ padding: 5 })],
    placement: 'bottom-start',
  })

  useEffect(() => {
    update()
  }, [view, referenceElement, is_dialog_open, update])

  return (
    <View
      {...props}
      contentRef={refs.setFloating}
      isOpen={is_dialog_open}
      top={y}
      left={x}
    />
  )
}
