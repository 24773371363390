import { useClient } from 'Data/Api.js'
import { useDataValue, useDataChange } from 'Simple/Data.js'
import {
  changeInsurancesEstimatedReimbursementAmount,
  changePayorsShare,
  changeDiscountsAppliedToPayorsShare,
  changePayorsConfiguration,
} from 'Data/payment-plan.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'

import query from './query.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data, reExecuteQuery) {
  let onAssignInsuranceSubscription = useOnAssignInsuranceSubscription(props)

  return async function onSubmit({ value, args }) {
    switch (args.type) {
      case 'close-popover': {
        if (typeof props.closePopover === 'function') {
          props.closePopover()
        }
        return
      }
      case 'assign-insurance-subscription': {
        return onAssignInsuranceSubscription({ args })
      }

      default: {
      }
    }
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useOnAssignInsuranceSubscription(props) {
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })
  let treatment_plan = useDataValue({
    context: 'treatment_plan',
    viewPath: props.viewPath,
  })
  let index = useDataValue({
    context: 'insurance_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  let [, notify] = useNotifications()
  let client = useClient()

  return async function onSubmit({ args }) {
    let response = await client
      .query(query, { id: args.insured_id })
      .toPromise()
    if (response.error || !response.data?.vaxiom_insured_by_pk) {
      notify(
        notifyError(
          'An error has occurred while assigning the insurance subscription'
        )
      )
      return
    }

    change(next => {
      let insurance_subscription = response.data?.vaxiom_insured_by_pk

      let insurance = next.insurances[index]
      insurance.insured_id = insurance_subscription.id
      insurance.insured = insurance_subscription
      // marking it as not overriden to recalculate the downpayment and installment amount
      insurance.is_overridden = false

      changeInsurancesEstimatedReimbursementAmount(next, treatment_plan)
      changePayorsShare(next)
      changeDiscountsAppliedToPayorsShare(next)
      changePayorsConfiguration(next)
    })

    if (typeof props.closePopover === 'function') {
      props.closePopover()
    }
  }
}
