import { useMutation } from 'Data/Api.js'
import mutationResendInvite from './mutation-resend-invite.graphql.js'
import mutationRemoveHubAccess from './mutation-remove-hub-access.graphql.js'
import { useDataValue } from 'Simple/Data.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

export function useOnClick(props) {
  let action = useDataValue({
    context: 'option',
    viewPath: props.viewPath,
    path: 'id',
  })
  let resendHubInvite = useDataActionResendHubInvite(props)
  let removeHubAccess = useDataActionRemoveHubAccess(props)

  return function onClick() {
    // props.closePopover()
    switch (action) {
      case 'RESEND_HUB_INVITE': {
        return resendHubInvite()
      }
      case 'REMOVE_HUB_ACCESS': {
        return removeHubAccess()
      }
      default: {
        break
      }
    }
  }
}

function useDataActionResendHubInvite(props) {
  let [, executeMutation] = useMutation(mutationResendInvite)
  let patient_person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'patient',
    path: 'person._id',
  })
  let person = useDataValue({
    viewPath: props.viewPath,
    context: 'person',
  })
  let location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location._id',
  })
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onActionResendHubInvite() {
    let mutationResponse = await executeMutation({
      patient_person_id,
      invited_person_id: person._id,
      email: person.public_user.account.email,
      location_id,
    })

    if (
      mutationResponse.error ||
      !mutationResponse.data.auth_accounts_practice_hub_invite_patient.ok
    ) {
      notify(notifyError('Failed to send invite.'))
    } else {
      notify(notifySuccess('Invite sent!'))
    }

    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}

function useDataActionRemoveHubAccess(props) {
  let [, executeMutation] = useMutation(mutationRemoveHubAccess)
  let user_id = useDataValue({
    viewPath: props.viewPath,
    context: 'person',
    path: 'public_user.id',
  })
  let email = useDataValue({
    viewPath: props.viewPath,
    context: 'person',
    path: 'public_user.account.email',
  })
  let core_user_id = useDataValue({
    viewPath: props.viewPath,
    context: 'person',
    path: 'core_user_id',
  })
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onActionRemoveHubAccess() {
    if (core_user_id) {
      notify(
        notifyError(
          `This email ${email} is currently associated with a system user and cannot be removed from the Practice Hub.`
        )
      )
      return
    }

    if (
      !window.confirm(
        `This will remove ${email} from the Practice Hub, preventing login with this email. The email will be available for use with another profile if needed.\n\nAre you sure you want to proceed?
`
      )
    )
      return

    let mutationResponse = await executeMutation({ user_id })

    if (mutationResponse.error || !mutationResponse.data.update_users_by_pk) {
      notify(notifyError(`Failed to remove Practice Hub access to ${email}.`))
    } else {
      notify(notifySuccess(`Practice Hub Access removed for ${email}!`))
    }

    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}
