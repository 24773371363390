import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let treatment_plan_id = useDataValue({
    context: 'treatment_plan',
    path: 'id',
    viewPath: props.viewPath,
  })

  return {
    variables: {
      treatment_plan_id,
    },
    pause: !treatment_plan_id,
    // refetching from network to get the latest changes
    // since "payment_plan" applies the changes in memory it could lead to
    // showing previous changes when getting back to the treatment planning UI (it reads from cache)
    requestPolicy: 'network-only',
  }
}
