import React, { useRef, useState } from 'react'
import View from './view.js'

export default function Logic(props) {
  let [isShowingSuggestions, setIsShowingSuggestions] = useState(false)
  let containerRef = useRef(null)

  let { focusOnDownArrowKey, ...viewProps } = props

  return (
    <View
      {...viewProps}
      type={props.type === 'date' ? 'text' : props.type}
      shouldRenderAddressPicker={
        props.type === 'address' && isShowingSuggestions
      }
      shouldRenderDatePicker={props.type === 'date' && isShowingSuggestions}
      containerRef={containerRef}
      onBlur={onBlur}
      onFocus={onFocus}
      onKeyUp={onKeyUp}
      onChange={onChange}
      onChooseAddress={onChooseAddress}
    />
  )

  function onChooseAddress(event, value) {
    if (typeof props.onChooseAddress === 'function') {
      props.onChooseAddress(event)
      setIsShowingSuggestions(false)
    }
  }

  function onChange(event, value) {
    if (typeof props.onChange !== 'function') return

    if (props.type === 'file') {
      props.onChange({ file: event.target.files[0], name: value })
    } else if (props.type === 'number') {
      let number_value = props.isInteger
        ? parseInt(value, 10)
        : parseFloat(value)
      props.onChange(isNaN(number_value) ? null : number_value)
    } else {
      props.onChange(value)
    }

    if (props.type === 'date') {
      setIsShowingSuggestions(false)
    }
    if (props.type === 'address') {
      setIsShowingSuggestions(!!value)
    }
  }

  function onKeyUp(event) {
    if (
      containerRef.current &&
      containerRef.current.firstChild &&
      focusOnDownArrowKey &&
      event.key === 'ArrowDown'
    ) {
      containerRef.current.firstChild.focus()
    }
    if (props.onKeyUp) {
      props.onKeyUp(event)
    } else if (event.key === 'Enter' && typeof props.onSubmit === 'function') {
      props.onSubmit()
    } else if (event.key === 'Escape') {
      maybeHidePicker()
    }
  }

  function onFocus(event) {
    if (props.type === 'address' || props.type === 'date') {
      setIsShowingSuggestions(true)
    }
    if (typeof props.onFocus === 'function') {
      props.onFocus(event)
    }
  }

  function onBlur(event) {
    if (
      !(
        event.relatedTarget === containerRef.current ||
        containerRef.current?.contains(event.relatedTarget)
      )
    ) {
      maybeHidePicker()
    }

    if (typeof props.onBlur === 'function') {
      props.onBlur(event)
    }
  }

  function maybeHidePicker() {
    setIsShowingSuggestions && setIsShowingSuggestions(false)
  }
}
