import { useMutation } from 'Data/Api.js'
import { useDataValue, useDataChange, useDataSubmit } from 'Simple/Data.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'

import mutation from './mutation.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data, reExecuteQuery) {
  let payors = useDataValue({
    context: 'payor_signed_contracts',
    viewPath: props.viewPath,
  })
  let contract_id = useDataValue({
    context: 'payor_signed_contract',
    path: 'contract.id',
    viewPath: props.viewPath,
  })
  let setHasSignedContracts = useDataChange({
    context: 'payment_plan',
    path: 'has_signed_contracts',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'payor_signed_contracts',
    viewPath: props.viewPath,
  })

  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, executeMutation] = useMutation(mutation)

  return async function onSubmit({ value, originalValue, args, change }) {
    let mutationResponse = await executeMutation({
      contract_id,
    })

    if (mutationResponse.error) {
      notify(
        notifyError(
          'There was a problem removing the contract signature. Please try again.'
        )
      )
      return
    }

    // mark the contract as not signed if this was the only signed contract
    if (payors.length <= 1) {
      setHasSignedContracts(false)
    }
    setFlowTo(normalizePath(props.viewPath, '../No'))
    submit({ type: 'reFetch' })
  }
}
