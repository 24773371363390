import { useDataValue } from 'Simple/Data.js'
import formatISO from 'date-fns/formatISO/index.js'
import parseISO from 'date-fns/parseISO/index.js'
import subDays from 'date-fns/subDays/index.js'
import startOfDay from 'date-fns/startOfDay/index.js'
import endOfDay from 'date-fns/endOfDay/index.js'
import { useMemo } from 'react'

export default function useDataConfiguration(props) {
  let selectedLocations = useDataValue({
    context: 'tab',
    path: 'selected.locations',
    viewPath: props.viewPath,
  })

  let selectedCampaigns = useDataValue({
    context: 'tab',
    path: 'selected.campaigns',
    viewPath: props.viewPath,
  })

  let selectedCampaignDateRange = useDataValue({
    context: 'tab',
    path: 'selected.range',
    viewPath: props.viewPath,
  })

  let showDismissed = useDataValue({
    context: 'tab',
    path: 'selected.show_dismissed',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    // default start range
    let date_start = formatISO(startOfDay(subDays(new Date(), 30)))
    let date_end = formatISO(endOfDay(new Date()))

    if (
      selectedCampaignDateRange.date_start &&
      selectedCampaignDateRange.date_end
    ) {
      date_start = formatISO(
        startOfDay(parseISO(selectedCampaignDateRange.date_start))
      )
      date_end = formatISO(
        endOfDay(parseISO(selectedCampaignDateRange.date_end))
      )
    }

    let locationsClause = { location_id: { _in: selectedLocations } }
    let datesClause = { updated_at: { _gte: date_start, _lte: date_end } }
    let campaignsClause = selectedCampaigns.length && {
      analytics: { campaign_id: { _in: selectedCampaigns } },
    }
    let statusClause = showDismissed ? {} : { status: { _neq: 'dismissed' } }

    return {
      variables: {
        where: {
          _and: [
            locationsClause,
            datesClause,
            campaignsClause,
            statusClause,
          ].filter(Boolean),
        },
        where_total: {
          _and: [
            locationsClause,
            datesClause,
            campaignsClause,
            { status: { _neq: 'patient_exists' } },
          ].filter(Boolean),
        },
        where_total_incompletes: {
          _and: [
            locationsClause,
            datesClause,
            campaignsClause,
            { status: { _eq: 'incomplete' } },
          ].filter(Boolean),
        },
        where_total_created: {
          _and: [
            locationsClause,
            datesClause,
            campaignsClause,
            { status: { _eq: 'created' } },
          ].filter(Boolean),
        },
        where_total_patient_created: {
          _and: [
            locationsClause,
            datesClause,
            campaignsClause,
            { status: { _eq: 'patient_created' } },
          ].filter(Boolean),
        },
      },
      pause: !selectedLocations.length,
    }
  }, [
    selectedCampaignDateRange.date_end,
    selectedCampaignDateRange.date_start,
    selectedCampaigns,
    selectedLocations,
    showDismissed,
  ])
}
