import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { useDataValue } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'

import mutation from './mutation-api-authorize.graphql.js'

export default function useListItemDataOnActionConnectOAuth2(props, data) {
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()

  let location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location._id',
  })

  return async function onSubmit({ value, args, change }) {
    let mutationResponse = await executeMutation({
      app_id: value.id,
      connection_id: null,
      location_id,
      // TO DO: take this from dynamically generated connection configuration inputs
      // see: https://www.notion.so/getgreyfinch/App-Connections-Refactor-Proposal-96b3ef598e3e4db9b6880b42da3aae09?pvs=4
      connection_properties: {},
    })
    if (
      mutationResponse.error ||
      !mutationResponse.data.apps_external_api_authorize.id
    ) {
      return notify(
        notifyError(
          'An error occurred trying to connect the app. Please refresh the page and try again.'
        )
      )
    }

    if (mutationResponse.data.apps_external_api_authorize.url) {
      let authWindow = window.open(
        mutationResponse.data.apps_external_api_authorize.url,
        'popup',
        `height=768,width=1024,top=${(window.innerHeight - 768) / 2},left=${
          (window.innerWidth - 1024) / 2
        }`
      )

      change(next => {
        next.connecting = true
        next.connection_id =
          mutationResponse.data.apps_external_api_authorize.id
        next.authWindow = authWindow
      })
    }
  }
}
