import { useDataIsSubmitting, useDataValue } from 'Simple/Data'

export function useIsDisabled(props) {
  let isSubmitting = useDataIsSubmitting({
    viewPath: props.viewPath,
    context: 'selected',
  })

  let selected = useDataValue({
    viewPath: props.viewPath,
    context: 'financial_flow',
    path: 'selected',
  })

  return isSubmitting || selected === null || selected === undefined
}
