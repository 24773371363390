import { useDataChange } from 'Simple/Data.js'
export function useOnClick(props) {
  let change = useDataChange({
    context: 'ruleset',
    viewPath: props.viewPath,
    path: 'custom_payment_plan_ruleset.max_length_type',
  })
  return function onClick() {
    change('relative')
  }
}
