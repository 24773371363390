import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let connection_id = useDataValue({
    context: 'connection_resource',
    path: 'connection_id',
    viewPath: props.viewPath,
  })
  let property_key = useDataValue({
    context: 'selected',
    viewPath: props.viewPath,
    path: 'key',
  })
  let location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location._id',
  })
  return {
    variables: {
      connection_id,
      location_id,
      property_key,
    },
    pause: !connection_id || !property_key,
  }
}
