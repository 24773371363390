import { useDataFormat } from 'Simple/Data'

export function useOnshowCancelInternalFinancialAction(props) {
  return useDataFormat({
    context: 'treatment',
    path: 'is_financials_managed_externally',
    viewPath: props.viewPath,
    format: item => item === false,
  })
}
