import { useMutation } from 'Data/Api.js'

import mutation from './mutation-delete-connection.graphql.js'

export default function useListItemDataOnActionCancelOAuth2Connection(
  props,
  data
) {
  let [, executeMutation] = useMutation(mutation)

  return async function onSubmit({ value, args, change }) {
    if (!value.connecting) return

    try {
      value.authWindow?.close()

      // delete newly created connection as not used anyway
      await executeMutation({
        connection_id: value.connection_id,
      })
    } catch (_) {
    } finally {
      setTimeout(() => {
        change(next => {
          next.connecting = false
          next.authWindow = null
        })
      }, 100)
    }
  }
}
