import { DEFAULT_APPOINTMENT_DURATION } from 'Data/constants.js'
import { useDataValue, useDataSubmit } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props) {
  let submit = useDataSubmit({
    context: 'appointment_overlay',
    viewPath: props.viewPath,
  })
  let is_external_any = useDataValue({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
    path: 'is_external_any',
  })
  let patient_id = useDataValue({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
    path: 'patient_id',
  })

  return function onChange(value) {
    if (is_external_any || patient_id) return
    let preferred = value?.find(item => item.is_default === true)
    let selected = preferred || value?.[0]
    if (selected) {
      return submit({
        type: 'setAppointmentTemplate',
        appointment_type_id: selected.id,
        appointment_type_name: selected.text,
        template_id: selected.template_id,
        duration: selected.duration || DEFAULT_APPOINTMENT_DURATION,
        reset: false,
        template_exists_in_location: true,
      })
    }
  }
}
