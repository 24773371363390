import {
  useDataIsSubmitting,
  useDataSubmit,
  useDataValue,
} from 'Simple/Data.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'app',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({ type: 'connect-oauth2' })
  }
}

export function useDisabled(props) {
  let isSubmitting = useDataIsSubmitting({
    context: 'app',
    viewPath: props.viewPath,
  })
  let connecting = useDataValue({
    context: 'app',
    path: 'connecting',
    viewPath: props.viewPath,
  })

  return isSubmitting || connecting
}
