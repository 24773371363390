function maybeGetCacheExchangeKey(rfields) {
  let fields = rfields.filter(Boolean)
  return fields.length === rfields.length ? rfields.join(':') : null
}

let keys = {
  admin: {
    workflows_company_running_executions: data => data.parent_company_id,
    aws_token_vending_machine_response: () => null,
    vaxiom_persons_search_result: data => data.event?.id || data.event_id,
    comms_events_aggregate: () => null,
    comms_events_aggregate_fields: () => null,
    comms_mobile_numbers_inbound_persons: () => null,
    comms_user_preferences: data => data.user_id,
    comms_mobile_numbers_outbound_permissions: data =>
      maybeGetCacheExchangeKey([
        data.mobile_number_outbound_id,
        data.location_id,
        data.resource_id,
      ]),
    vaxiom_appointment_bookings_aggregate: () => null,
    vaxiom_appointment_types: () => null,
    nps_rulesets_appointment_templates: data =>
      maybeGetCacheExchangeKey([data.ruleset_id, data.appointment_template_id]),
    nps_rulesets_social_links: data =>
      maybeGetCacheExchangeKey([data.ruleset_id, data.type]),
    nps_social_link_types: data => data.value,
    nps_requests_aggregate: () => null,
    nps_requests_aggregate_fields: () => null,
    iam_users_activity: data => data.vaxiom_user_id,
    vaxiom_resource_object_meta_response: () => null,
    nps_history: () => null,
    vaxiom_application_properties: () => null,
    vaxiom_appointment_field_values: data =>
      maybeGetCacheExchangeKey([
        data.appointment_id,
        data.field_option_id,
        data.field_definition_id,
        data.text_value,
      ]),
    vaxiom_diagnosis_template_xml_by_id_response: () => null,
    _time: () => null,
    vaxiom_patient_image_print: () => null,
    vaxiom_core_embed_section_url: () => null,
    vaxiom_core_embed_section_url_response: () => null,
    vaxiom_patients_aggregate: () => null,
    vaxiom_patients_aggregate_fields: () => null,
    vaxiom_appointments_aggregate: () => null,
    vaxiom_appointments_aggregate_fields: () => null,
    vaxiom_notes_aggregate: () => null,
    vaxiom_notes_aggregate_fields: () => null,
    comms_threads_aggregate: () => null,
    comms_threads_aggregate_fields: () => null,
    comms_inbox_new_aggregate: () => null,
    comms_inbox_new_aggregate_fields: () => null,
    vaxiom_appointments_sum_fields: () => null,
    vaxiom_tx_plans_aggregate: () => null,
    vaxiom_tx_contracts_aggregate: () => null,
    vaxiom_tx_plans_aggregate_fields: () => null,
    vaxiom_tx_contracts_aggregate_fields: () => null,
    appointments_appointment_treatment_field_values: () => null,
    appointments_treatment_fields: () => null,
    vaxiom_referral_list_values: () => null,
    apps_launchers_response: () => null,
    vaxiom_contact_method_associations_aggregate: () => null,
    leads_history: () => null,
    leads_leads_aggregate: () => null,
    leads_leads_aggregate_fields: () => null,
    vaxiom_day_schedule_office_hours: data =>
      maybeGetCacheExchangeKey([
        data.day_schedule_id,
        data.end_min,
        data.start_min,
      ]),
    vaxiom_day_schedule_break_hours: data =>
      maybeGetCacheExchangeKey([
        data.day_schedule_id,
        data.end_min,
        data.start_min,
      ]),
    vaxiom_day_schedule_appt_slots: data =>
      maybeGetCacheExchangeKey([
        data.day_schedule_id,
        data.appt_type_id,
        data.start_min,
      ]),
    calendar_scheduling_candidate_slots: data =>
      maybeGetCacheExchangeKey([
        data.xid,
        data.chair_id,
        data.date,
        data.start_min,
        data.end_min,
      ]),
    calendar_appointment_slots: data =>
      maybeGetCacheExchangeKey([
        data.day_schedule_id,
        data.appointment_template_id,
      ]),
    calendar_open_office_dates: data =>
      maybeGetCacheExchangeKey([data.date, data.location_id]),
  },
  public: {},
}
export default keys
