// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/PracticeHub/InviteAction/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_PracticeHub_InviteAction_Content(
  $patient_person_id: uuid!
  $invited_person_id: uuid!
  $email: String!
  $permitted_to_see_info: Boolean!
  $send_patient_reminders_to_email: Boolean!
  $location_id: uuid!
) {
  auth_accounts_practice_hub_invite_patient(
    patient_person_id: $patient_person_id
    invited_person_id: $invited_person_id
    email: $email
    permitted_to_see_info: $permitted_to_see_info
    send_patient_reminders_to_email: $send_patient_reminders_to_email
    location_id: $location_id
  ) {
    ok
  }
}

`