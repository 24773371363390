import React, { useEffect } from 'react'
import { useDataSubmit, useDataValue } from 'Simple/Data.js'

import View from './view.js'

export default function Logic(props) {
  let submitTab = useDataSubmit({
    context: 'tab',
    viewPath: props.viewPath,
  })
  let date = useDataValue({
    context: 'tab',
    path: 'selected.date',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    submitTab({ type: 'scheduling/reset' })
  }, [date]) // eslint-disable-line

  return <View {...props} />
}
