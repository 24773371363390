export function getLocationDetails(selected) {
  return {
    id: selected.location_id,
    vaxiom_id: selected.vaxiom_location_id,
    time_zone_id: selected.time_zone_id,
    name: selected.location_name,
  }
}

export function getSchedulingConfig(
  duration,
  appointment_type_name,
  conflict_permission
) {
  return {
    duration,
    appointment_type_name,
    conflict_permission,
  }
}
