import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'
import {
  getAdjustedTreatmentFeeAfterInsurances,
  getDefaultPrimaryPayor,
  getPrimaryInsuranceEstimatedReimbursement,
  getSecondaryInsuranceEstimatedReimbursement,
  getSortedInsurances,
  LOCK,
} from 'Data/payment-plan.js'

export default function useDataTransform(props) {
  let settings = useDataValue({
    context: 'settings',
    viewPath: props.viewPath,
  })
  let data = useDataValue({
    context: 'payment_plan_data',
    viewPath: props.viewPath,
  })
  let default_payment_plan = useGetDefaultPaymentPlan(props)

  return useMemo(
    () =>
      data?.length ? mapPaymentPlan(data[0], settings) : default_payment_plan,
    // update the data context when contract was signed on contracts section
    [data?.[0]?.has_signed_contracts] // eslint-disable-line react-hooks/exhaustive-deps
    // ignore data
  )
}

function useGetDefaultPaymentPlan(props) {
  let settings = useDataValue({
    context: 'settings',
    viewPath: props.viewPath,
  })
  let treatment_plan = useDataValue({
    context: 'treatment_plan',
    viewPath: props.viewPath,
  })
  let insurance_subscriptions = useDataValue({
    context: 'insurance_subscriptions',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    let initial_payment_plan = {
      treatment_plan_id: treatment_plan.id,
      treatment_fee: treatment_plan.fee,
      insurance_code_id: treatment_plan.insurance_code_id,
      has_signed_contracts: false,
      discounts: [],
      charges: [],
      insurances: [],
      payors: [],
    }

    let payment_plan_with_insurances = {
      ...initial_payment_plan,
      insurances: getInsurances(
        insurance_subscriptions,
        initial_payment_plan,
        treatment_plan
      ),
    }

    return {
      ...payment_plan_with_insurances,
      payors: [getDefaultPrimaryPayor(payment_plan_with_insurances, settings)],
    }
  }, [insurance_subscriptions, settings, treatment_plan])
}

function getInsurances(insurance_subscriptions, payment_plan, treatment_plan) {
  if (!insurance_subscriptions?.length) return []

  let [initial_primary_insurance, initial_secondary_insurance] =
    insurance_subscriptions.sort(getSortedInsurances).map((insured, index) => ({
      id: crypto.randomUUID(),
      insured_id: insured.id,
      insured,
      is_primary: index === 0, // first is primary
      is_overridden: false,
      estimated_reimbursement_amount: 0,
      downpayment_amount: 0,
      installment_amount: 0,
    }))

  let primary_insurance = null
  try {
    primary_insurance = {
      ...initial_primary_insurance,
      ...getPrimaryInsuranceEstimatedReimbursement(
        initial_primary_insurance,
        payment_plan,
        treatment_plan
      ),
    }
  } catch {}

  let secondary_insurance = null
  if (initial_secondary_insurance) {
    try {
      secondary_insurance = {
        ...initial_secondary_insurance,
        ...getSecondaryInsuranceEstimatedReimbursement(
          primary_insurance,
          initial_secondary_insurance,
          payment_plan,
          treatment_plan
        ),
      }
    } catch {}
  }

  return [primary_insurance, secondary_insurance].filter(Boolean)
}

function mapPaymentPlan(payment_plan, settings) {
  let treatment_fee = payment_plan.treatment_fee
  // calculate the percentage based on the given amount
  let treatment_fee_discounts = payment_plan.discounts
    .filter(discount => !discount.is_applied_to_payor)
    .map(discount => ({
      id: discount.id,
      type_id: discount.type_id,
      name: discount.type?.name,
      type: 'percentage',
      amount: -discount.transaction.amount,
      percentage:
        treatment_fee > 0
          ? Math.round((-discount.transaction.amount / treatment_fee) * 100)
          : 0,
      is_applied_to_payor: false,
      is_included_in_insurance_claim: discount.is_included_in_insurance_claim,
      payor_person_id: null,
    }))
  let treatment_fee_charges = payment_plan.charges.map(charge => ({
    id: charge.id,
    name: charge.name,
    type: 'percentage',
    type_id: charge.type_id || charge.name,
    amount: charge.transaction.amount,
    percentage:
      treatment_fee > 0
        ? Math.round((charge.transaction.amount / treatment_fee) * 100)
        : 0,
    is_included_in_insurance_claim: charge.is_included_in_insurance_claim,
  }))

  let adjusted_treatment_fee = getAdjustedTreatmentFeeAfterInsurances({
    id: payment_plan.id,
    treatment_fee,
    insurance_code_id: payment_plan.insurance_code_id,
    treatment_plan_id: payment_plan.treatment_plan_id,
    discounts: treatment_fee_discounts,
    charges: treatment_fee_charges,
    insurances: payment_plan.insurances,
  })

  let payors = payment_plan.payors.map(payor => ({
    id: payor.id,
    is_primary: payor.is_primary,
    person_id: payor.person_id,
    share: {
      type: 'percentage',
      amount: payor.share_amount,
      percentage:
        adjusted_treatment_fee > 0
          ? Math.round((payor.share_amount / adjusted_treatment_fee) * 100)
          : 0,
    },
    downpayment_amount: payor.payment_plan.downpayment_amount,
    installment_amount: payor.payment_plan.installment_amount,
    installment_interval: payor.payment_plan.installment_interval,
    payment_plan_length: payor.payment_plan.payment_plan_length,
    first_due_date: payor.payment_plan.first_due_date,
    first_installment_date: payor.payment_plan.first_installment_date,
    second_installment_date: payor.payment_plan.second_installment_date,
    lock: payor.payment_plan.downpayment_locked
      ? LOCK.DOWNPAYMENT_AMOUNT
      : payor.payment_plan.installment_locked
      ? LOCK.INSTALLMENT_AMOUNT
      : payor.payment_plan.plan_length_locked
      ? LOCK.PAYMENT_PLAN_LENGTH
      : null,
  }))

  let payor_discounts = payment_plan.discounts
    .filter(discount => discount.is_applied_to_payor)
    .map(discount => {
      let payor_share_amount = payors.find(
        payor => payor.person_id === discount.payor_person_id
      ).share.amount

      return {
        id: discount.id,
        type_id: discount.type_id,
        name: discount.type?.name,
        type: 'percentage',
        amount: -discount.transaction.amount,
        percentage:
          payor_share_amount > 0
            ? Math.round(
                (-discount.transaction.amount / payor_share_amount) * 100
              )
            : 0,
        is_applied_to_payor: true,
        // discounts applied to payor's share are never included in insurance claim
        is_included_in_insurance_claim: false,
        payor_person_id: discount.payor_person_id,
      }
    })

  let result = {
    id: payment_plan.id,
    treatment_fee,
    insurance_code_id: payment_plan.insurance_code_id,
    treatment_plan_id: payment_plan.treatment_plan_id,
    has_signed_contracts: payment_plan.has_signed_contracts,
    request_id: payment_plan.request_id,
    discounts: [...treatment_fee_discounts, ...payor_discounts],
    charges: treatment_fee_charges,
    insurances: payment_plan.insurances,
    payors,
  }

  // if coming from the old UI, it is possible no payor to have been selected yet
  // could remove it and directly return the result once core UI is not available anymore
  return payors.length > 0
    ? result
    : {
        ...result,
        payors: [getDefaultPrimaryPayor(result, settings)],
      }
}
