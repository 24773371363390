import { useDataChange } from 'Simple/Data.js'
export function useOnClick(props) {
  let change = useDataChange({
    context: 'plan_details',
    viewPath: props.viewPath,
  })
  return function onClick() {
    change(next => {
      next.ortho_coverage = true
      next.lifetime_coverage = 0
      next.dependent_age_limit = 26
      next.reimbursement_rate = 0.5
      next.age_limit_effective = 'Immediate'
      next.annual_deductible = 0
      next.waiting_period = 0
      next.inprogress = false
      next.ortho_coverage = true
      next.downpayment_type = 'PERCENTAGE'
      next.downpayment_percentage = 0.25
      next.downpayment_percentage_base = 'TX_FEE'
      next.downpayment_amount = 0
      next.cob_type = null
    })
  }
}
