import { useDataValue } from 'Simple/Data.js'
import formatISO from 'date-fns/formatISO'
import subYears from 'date-fns/subYears'
import startOfToday from 'date-fns/startOfToday'

let YEARS_OLD_ENOUGH = 18
let OLD_ENOUGH = formatISO(subYears(startOfToday(), YEARS_OLD_ENOUGH), {
  representation: 'date',
})

export default function useDataConfiguration(props) {
  let person_id = useDataValue({
    context: 'patient',
    path: 'person._id',
    viewPath: props.viewPath,
  })

  return {
    variables: {
      person_id,
      old_enough: OLD_ENOUGH,
    },
    requestPolicy: 'networ-only',
  }
}
