import {
  useDataIsSubmitting,
  useDataSubmit,
  useDataValue,
} from 'Simple/Data.js'
export function useIsDisabled(props) {
  let isSubmitting = useDataIsSubmitting({
    context: 'create_token',
    viewPath: props.viewPath,
  })

  let { description, expires_at, acceptedTermsAndConditions } = useDataValue({
    context: 'create_token',
    viewPath: props.viewPath,
  })

  return (
    isSubmitting || !description || !expires_at || !acceptedTermsAndConditions
  )
}

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'create_token',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({ type: 'submit' })
  }
}
