import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let connectionType = useDataValue({
    context: 'app',
    path: 'connection.type',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)

  return function onClick() {
    switch (connectionType) {
      case 'API_TOKEN': {
        setFlowTo(normalizePath(props.viewPath, 'ApiToken'))
        return
      }

      case 'OAUTH2': {
        setFlowTo(normalizePath(props.viewPath, 'OAuth2'))
        return
      }

      default: {
        return
      }
    }
  }
}
