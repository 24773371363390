import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data?.length) return data
    let { organization, configurations, ...rest } = data[0]

    return {
      ...rest,
      organization_id: organization._id,
      show_financials_managed_externally:
        !!organization.treatment_settings?.data
          ?.is_financials_managed_externally,
    }
  }, [data])
}
