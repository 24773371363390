import React, { useEffect, useRef, useState, useMemo } from 'react'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import scrollgrid from '@fullcalendar/scrollgrid'
import { v4 as uuid } from 'uuid'
import {
  differenceInMinutes,
  areIntervalsOverlapping,
  parseISO,
} from 'date-fns'
import './style.css'
import { useDataSubmit } from 'Simple/Data.js'

import Resource from './Resource/index.js'
import SlotLabel from './SlotLabel/index.js'
import Appointment from './Appointment/index.js'
import AppointmentCandidate from './AppointmentCandidate/index.js'
import AppointmentCandidateShadow from './AppointmentCandidateShadow/index.js'
import AppointmentCandidateUntemplated from './AppointmentCandidateUntemplated/index.js'
import AppointmentCandidateUntemplatedHover from './AppointmentCandidateUntemplatedHover/index.js'
import Note from './Note/index.js'
import BlockedTime from './BlockedTime/index.js'
import AppointmentType from './AppointmentType/index.js'
import Closed from './Closed/index.js'
import SlotDialog from './SlotDialog/index.js'

import { format, formatInTimeZone } from 'date-fns-tz'
import {
  minutesToTimestamp,
  timeToMinutes,
  twoDigitsTime,
} from 'Data/format.js'
import pointerMovePlugin from './pointerMovePlugin.js'
import useOnPrint from './useOnPrint.js'

/**
 * @typedef {import('@fullcalendar/react').default['props']} FullCalendarProps
 * @typedef {InstanceType<import('@fullcalendar/core')['Calendar']>} FullCalendarApi
 * @typedef {Object} SlotRef
 * @property {object | null} schedule_note
 */

let schedulerLicenseKey = process.env.REACT_APP_FULL_CALENDAR_API_KEY
let SLOT_MIN_WIDTH_PX = 167
let FOCUS_EVENT_DELAY_MS = 300 // Delay when focusing event, as it causes weird janking (and sometimes its not yet available in its internal db)
let RERENDER_DELAY_MS = 50 // For scheduling (when adding/removing custom events)
let UNTEMPLATED_SCHEDULING_ALLOWED_TYPE_OVERLAPS = [
  'candidate',
  'candidate-shadow',
  'candidate-untemplated',
  'candidate-untemplated-hover',
  'blockTime',
  'note',
  'closed',
  'appointment-type',
]

export default function FullCalendarTimegrid({
  events,
  resources,
  date,
  location,
  viewPath,
  scrollTime,
  slotMinTime,
  slotMaxTime,
  slotInterval = 10,
  slotLabelMinutes = 10,
  resourceWidth,
  focusedEventId,
  schedulingConfig,
  hipaa,
}) {
  let [showSlotDialog, setShowSlotDialog] = useState(false)
  let slotDialogElementId = 'slot-dialog'
  /** @type {React.MutableRefObject<import('@fullcalendar/react').default | null>} */
  let calendarRef = useRef(null)
  let timeZoneId = useMemo(() => location.time_zone_id, [location])

  let printFilename = useMemo(
    () =>
      `${location.name} ${format(date, 'EEEE, dd MMMM yyyy', {
        timeZone: timeZoneId,
      })}.pdf`,
    [location.name, date, timeZoneId]
  )

  useOnPrint({
    calendarRef,
    hipaa,
    fileName: printFilename,
  })

  /** @type {React.MutableRefObject<SlotRef>} */
  let slotRef = useRef({
    element: null,
    schedule_note: null,
  })

  let currentDate = useMemo(() => {
    return formatInTimeZone(new Date(), timeZoneId, "yyyy-MM-dd'T'HH:mm:ssXXX")
  }, [timeZoneId])
  let slotDuration = useMemo(
    () => `00:${twoDigitsTime(slotInterval)}:00`,
    [slotInterval]
  )

  /**
   * @description Scrolls (focuses) to specific event
   *  - only if event id is provided and exists
   *  - only once (when id changes)
   *
   * TODO: this only solves vertical scroll/focus
   *  - move appointment overlay to other side if overlapping
   *  - figure out horizontal scroll (to resource)
   */
  useEffect(() => {
    if (!focusedEventId) return
    async function run() {
      await new Promise(resolve => {
        setTimeout(resolve, FOCUS_EVENT_DELAY_MS)
      })
      /** @type {FullCalendarApi | undefined} */
      let api = calendarRef.current?.getApi?.()
      if (!api) return
      let event = api.getEventById(focusedEventId)
      if (!event || !event.start) return
      let minute = event.start.getUTCHours() * 60 + event.start.getUTCMinutes()
      api.scrollToTime({ minute })
    }
    run()
  }, [focusedEventId])

  let submit = useDataSubmit({
    context: 'timegrid',
    viewPath,
  })

  let expandRows = useMemo(() => slotInterval === 30, [slotInterval])

  /**
   *
   * @param {import('@fullcalendar/core').EventContentArg} value
   */
  function renderEventContent(value) {
    switch (value.event.extendedProps.type) {
      case 'appointment':
        return (
          <Appointment
            key={value.event.id}
            event={{
              id: value.event.id,
              title: value.event.title,
              start: value.event.startStr,
              end: value.event.endStr,
              ...value.event.extendedProps,
            }}
            viewPath={`${viewPath}/Appointment(${value.event.id})`}
          />
        )
      case 'note':
        return (
          <Note
            key={value.event.id}
            viewPath={`${viewPath}/Note(${value.event.id})`}
            event={{
              id: value.event.id,
              content: value.event.extendedProps?.content,
              start: value.event.startStr,
              end: value.event.endStr,
              alert_this_day: value.event.extendedProps?.alert_this_day,
              is_blocking_time: value.event.extendedProps?.is_blocking_time,
              selected_resources: [value.event.getResources()[0].id],
              display: value.event.display,
            }}
          />
        )
      case 'blockTime':
        return (
          <BlockedTime
            key={value.event.id}
            event={{
              id: value.event.id,
              content: value.event.extendedProps?.content,
              start: value.event.startStr,
              end: value.event.endStr,
              alert_this_day: value.event.extendedProps?.alert_this_day,
              selected_resources: [value.event.getResources()[0].id],
            }}
            viewPath={`${viewPath}/BlockedTime(${value.event.id})`}
          />
        )
      case 'candidate':
        return (
          <AppointmentCandidate
            key={value.event.id}
            event={{
              id: value.event.id,
              title: value.event.title,
              start: value.event.startStr,
              end: value.event.endStr,
              selected: value.event.extendedProps.selected,
              description: value.event.extendedProps.description,
            }}
            viewPath={`${viewPath}/AppointmentCandidate(${value.event.id})`}
          />
        )
      case 'candidate-shadow':
        return (
          <AppointmentCandidateShadow
            key={value.event.id}
            event={{
              id: value.event.id,
              title: value.event.title,
              start: value.event.startStr,
              end: value.event.endStr,
              selected: value.event.extendedProps.selected,
              description: value.event.extendedProps.description,
            }}
            viewPath={`${viewPath}/AppointmentCandidateShadow(${value.event.id})`}
          />
        )
      case 'candidate-untemplated':
        return (
          <AppointmentCandidateUntemplated
            key={value.event.id}
            event={{
              id: value.event.id,
              title: value.event.title,
              start: value.event.startStr,
              end: value.event.endStr,
              description: value.event.extendedProps.description,
            }}
            viewPath={`${viewPath}/AppointmentCandidateUntemplated(${value.event.id})`}
          />
        )
      case 'candidate-untemplated-hover':
        return (
          <AppointmentCandidateUntemplatedHover
            key={value.event.id}
            event={{
              id: value.event.id,
              title: value.event.title,
              start: value.event.startStr,
              end: value.event.endStr,
              description: value.event.extendedProps.description,
              config: value.event.extendedProps.config,
            }}
            viewPath={`${viewPath}/AppointmentCandidateUntemplatedHover(${value.event.id})`}
          />
        )
      case 'appointment-type':
        return (
          <AppointmentType
            key={value.event.id}
            event={{
              id: value.event.id,
              title: value.event.title,
              start: value.event.start,
              end: value.event.end,
              ...value.event.extendedProps,
            }}
            viewPath={`${viewPath}/AppointmentType(${value.event.id})`}
          />
        )
      case 'closed':
        return (
          <Closed
            key={value.event.id}
            event={{
              id: value.event.id,
              start: value.event.start,
              end: value.event.end,
            }}
            viewPath={`${viewPath}/Closed(${value.event.id})`}
          />
        )
      default:
        return null
    }
  }

  /**
   *
   * @param {import('@fullcalendar/resource').ResourceLabelContentArg} value
   */
  function renderResourceContent(value) {
    return (
      <Resource
        viewPath={viewPath}
        resource={{ ...value.resource.extendedProps }}
        isPrintReady={false}
      />
    )
  }

  /**
   *
   * @param {import('@fullcalendar/core').SlotLabelContentArg} value
   */
  function renderSlotLabelContent(value) {
    return <SlotLabel viewPath={viewPath} time={value.text} />
  }

  /**
   *
   * @param {import('@fullcalendar/core').DateSelectArg} value
   */
  function onSelectSlot(value) {
    if (!value.resource || !value.jsEvent) return

    slotRef.current = {
      schedule_note: {
        start: value.startStr,
        end: value.endStr,
        selected_resources: [value.resource.id],
      },
    }
  }

  /**
   *
   * @param {import('@fullcalendar/core').DateUnselectArg} value
   */
  function onUnselectSlot(value) {
    setShowSlotDialog(false)
    slotRef.current = {
      schedule_note: null,
    }
  }

  function updateTimeIndicator() {
    let nowIndicator = document.querySelector(
      '.fc-timegrid-now-indicator-arrow'
    )
    if (nowIndicator) {
      let timeLabel = document.querySelector('.fc-timegrid-now-indicator-time')
      if (!timeLabel) {
        timeLabel = document.createElement('div')
        timeLabel.className = 'fc-timegrid-now-indicator-time'
        nowIndicator.appendChild(timeLabel)
      }
      timeLabel.textContent = formatInTimeZone(new Date(), timeZoneId, 'hh:mm')
    }
  }

  /**
   *
   * @param {import('@fullcalendar/core').NowIndicatorContentArg} value
   */
  function nowIndicatorContent(value) {
    if (value.isAxis) {
      updateTimeIndicator()
    }
  }

  // Untemplated scheduling
  let candidateUntemplatedHoverId = useRef(null)
  useEffect(() => {
    if (
      typeof schedulingConfig?.duration !== 'number' ||
      schedulingConfig?.conflict_permission !== true
    ) {
      return
    }

    let api = calendarRef.current?.getApi?.()

    /**
     * @param {string} start
     * @param {string} end
     * @param {string} resourceId
     * @returns {boolean}
     */
    function wouldOverlap(start, end, resourceId) {
      /** @type {import('date-fns').Interval} */
      let newEventInterval = {
        start: parseISO(start),
        end: parseISO(end),
      }

      let events = api?.getResourceById(resourceId)?.getEvents() ?? []

      for (let event of events) {
        if (
          UNTEMPLATED_SCHEDULING_ALLOWED_TYPE_OVERLAPS.includes(
            event.extendedProps.type
          )
        )
          continue

        if (
          areIntervalsOverlapping(
            { start: parseISO(event.startStr), end: parseISO(event.endStr) },
            newEventInterval,
            { inclusive: false }
          )
        ) {
          return true
        }
      }

      return false
    }

    /** @returns {void} */
    function pointerMoveCancelHandler() {
      if (candidateUntemplatedHoverId.current) {
        api?.getEventById(candidateUntemplatedHoverId.current)?.remove?.()
      }
    }

    /** @returns {void} */
    function pointerMoveHandler(
      /** @type {{ data: import('./pointerMovePluginTypes.d.ts').PointerMoveData }} */
      event
    ) {
      if (candidateUntemplatedHoverId.current) {
        api?.getEventById(candidateUntemplatedHoverId.current)?.remove?.()
      }

      let {
        data: { conflicts, resourceId, startTime, date: event_date },
      } = event

      if (
        conflicts.appointmentCandidate ||
        conflicts.appointmentCandidateUntemplated ||
        conflicts.event
      ) {
        return
      }

      let resource = api?.getResourceById?.(resourceId)
      if (!resource) return

      let start_min = timeToMinutes(startTime)
      let end_min = start_min + schedulingConfig.duration
      let start = minutesToTimestamp(date, timeZoneId, start_min)
      let end = minutesToTimestamp(date, timeZoneId, end_min)

      if (wouldOverlap(start, end, resourceId)) return

      let id = `${uuid()}-candidate-untemplated-hover`
      candidateUntemplatedHoverId.current = id

      api?.addEvent({
        id,
        type: 'candidate-untemplated-hover',
        classNames: ['candidate-untemplated-hover'],
        display: 'background',
        start,
        end,
        resourceId,
        // should be same as tab.scheduling.untemplated_slot (except id)
        config: {
          start_min,
          end_min,
          date: event_date,
          chair_id: resourceId,
        },
        extendedProps: {
          description: `${schedulingConfig.appointment_type_name} - ${resource.extendedProps.name}`,
        },
      })
    }

    api?.on(
      // @ts-expect-error custom event type from pointerMovePlugin
      'pointerMove',
      pointerMoveHandler
    )

    api?.on(
      // @ts-expect-error custom event type from pointerMovePlugin
      'pointerMoveCancel',
      pointerMoveCancelHandler
    )

    return () => {
      api?.off(
        // @ts-expect-error custom event type from pointerMovePlugin
        'pointerMoveCancel',
        pointerMoveHandler
      )
      api?.off(
        // @ts-expect-error custom event type from pointerMovePlugin
        'pointerMove',
        pointerMoveHandler
      )
    }
  }, [schedulingConfig, date, timeZoneId])

  // Update calendar date managed from outside
  useEffect(() => {
    let api = calendarRef.current?.getApi?.()

    if (api) {
      let calendarDate = format(date, "yyyy-MM-dd'T'HH:mm:ssXXX", {
        timeZone: timeZoneId,
      })

      // https://github.com/fullcalendar/fullcalendar/issues/7448#issuecomment-1751518347
      queueMicrotask(() => {
        api.gotoDate(calendarDate)
      })
    }
  }, [date, timeZoneId])

  // Manage right click on empty slot
  useEffect(() => {
    function handleRightClick(event) {
      event.preventDefault()
      if (event.target.classList.contains('fc-highlight')) {
        setShowSlotDialog(true)
      }
    }

    let calendarElement = calendarRef.current?.getApi()?.el
    calendarElement.addEventListener('contextmenu', handleRightClick)
    return () => {
      calendarElement.removeEventListener('contextmenu', handleRightClick)
    }
  }, [])

  /** @param {import('@fullcalendar/core').EventClickArg} info */
  function eventMouseEnter(info) {
    let { event } = info

    switch (event.extendedProps.type) {
      case 'candidate': {
        let api = calendarRef.current?.getApi?.()

        let { realEnd, schedulingSlot, selected, description } =
          event.extendedProps

        api?.addEvent({
          id: `${event.id}-shadow`,
          type: 'candidate-shadow',
          start: event.start,
          end: realEnd,
          display: 'background',
          classNames: ['candidate-shadow'],
          resourceId: schedulingSlot.chair_id,
          schedulingSlot,
          selected,
          description,
        })

        break
      }
      default: {
        break
      }
    }
  }

  /** @param {import('@fullcalendar/core').EventClickArg} info */
  function eventMouseLeave(info) {
    let { event } = info

    switch (event.extendedProps.type) {
      case 'candidate': {
        let api = calendarRef.current?.getApi?.()
        api?.getEventById(`${event.id}-shadow`)?.remove?.()
        break
      }
      default: {
        break
      }
    }
  }

  /**
   *
   * @param {import('@fullcalendar/interaction').EventResizeDoneArg} value
   */
  function onEventResizeDone({ oldEvent, event }) {
    if (
      event.start === null ||
      event.end === null ||
      oldEvent.start === null ||
      oldEvent.end === null
    )
      return
    switch (event.extendedProps.type) {
      case 'blockTime':
      case 'note': {
        // Check if duration has changed
        let newDuration = differenceInMinutes(event.end, event.start)
        let oldDuration = differenceInMinutes(oldEvent.end, oldEvent.start)

        if (newDuration !== oldDuration && newDuration > 0) {
          submit({
            type: 'updateScheduleNote',
            id: event.id,
            duration: newDuration,
          })
        }

        break
      }

      default: {
      }
    }
  }

  /**
   *
   * @param {import('@fullcalendar/core').EventDropArg} value
   */
  function onEventDropDone({ event, oldEvent }) {
    switch (event.extendedProps.type) {
      case 'blockTime': {
        // Check if start or end time have changed
        if (oldEvent.startStr !== event.startStr) {
          submit({
            type: 'updateScheduleNote',
            id: event.id,
            start_time: event.startStr,
          })
        }

        break
      }

      default: {
      }
    }
  }

  /** @type {import('@fullcalendar/core').OverlapFunc} */
  function selectOverlap(event) {
    return (
      event.display === 'background' &&
      event.extendedProps.type === 'appointment-type'
    )
  }

  return (
    <div className="calendar-container">
      <FullCalendar
        schedulerLicenseKey={schedulerLicenseKey}
        ref={calendarRef}
        timeZone={timeZoneId}
        plugins={[
          pointerMovePlugin,
          timeGridPlugin,
          interactionPlugin,
          resourceTimeGridPlugin,
          scrollgrid,
        ]}
        initialView="resourceTimeGridDay"
        editable={false} // Enables event edition - for example, drag and drop - for the calendar
        headerToolbar={false} // Hides toolbar with today, next, previous actions
        events={events}
        eventMouseEnter={eventMouseEnter}
        eventMouseLeave={eventMouseLeave}
        resourceOrder={'position'}
        resources={resources}
        now={currentDate} // Current date
        nowIndicatorContent={nowIndicatorContent}
        rerenderDelay={RERENDER_DELAY_MS}
        eventContent={renderEventContent} // Custom event component
        resourceLabelContent={renderResourceContent} // Custom resource component
        allDaySlot={false} // Display specific slot/row below calendar header for all-day events
        slotMinTime={slotMinTime} // Earliest time to display on the time axis
        slotMaxTime={slotMaxTime} // Latest time to display on the time axis
        slotDuration={slotDuration} // Slot duration in minutes
        slotLabelInterval={{ minutes: slotLabelMinutes }} // Minutes in which the slot label is displayed
        slotLabelContent={renderSlotLabelContent} // Custom label to render for every slot
        nowIndicator={true} // Line that indicates the current time
        scrollTime={scrollTime} // Initial scroll position of the timegrid when it is first rendered
        slotMinWidth={SLOT_MIN_WIDTH_PX}
        dayMinWidth={resourceWidth}
        height={'100%'}
        eventResourceEditable={false}
        selectable={true}
        selectOverlap={selectOverlap}
        select={onSelectSlot}
        unselect={onUnselectSlot}
        unselectCancel={`#${slotDialogElementId}`}
        eventResize={onEventResizeDone}
        eventDrop={onEventDropDone}
        expandRows={expandRows}
      />
      <SlotDialog
        viewPath={`${viewPath}/SlotDialog`}
        slotDialogElementId={slotDialogElementId}
        showDialog={showSlotDialog}
        schedule_note={slotRef.current.schedule_note}
        onClose={() => {
          let api = calendarRef.current?.getApi?.()
          api?.unselect()

          setShowSlotDialog(false)
        }}
      />
    </div>
  )
}
