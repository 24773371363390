import {
  useDataValue,
  useDataSubmit,
  useDataIsSubmitting,
} from 'Simple/Data.js'

export function useIsDisabled(props) {
  let connections = useDataValue({
    context: 'connections',
    viewPath: props.viewPath,
  })
  let status = useDataValue({
    context: 'connection_resource',
    path: 'status',
    viewPath: props.viewPath,
  })
  let is_submitting = useDataIsSubmitting({
    context: 'connection_resource',
    viewPath: props.viewPath,
  })

  return (
    connections?.length === 0 ||
    (status && status !== 'created') ||
    is_submitting
  )
}

export function useOnChange(props) {
  let submit = useDataSubmit({
    context: 'connection_resource',
    viewPath: props.viewPath,
  })
  return function onChange(value) {
    if (!value) return

    submit({ type: 'save', connection_id: value })
  }
}
