// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentCaseCloser/Configuration/DownPayment/Rulesets/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentCaseCloser_Configuration_DownPayment_Rulesets($parent_company_id: uuid!) {
  treatments_downpayment_payment_methods(
    where: { parent_id: { _eq: $parent_company_id } }
  ) {
    id
    organization_id
    parent_id
    division_id
    location_id
    payment_methods
  }
}

`