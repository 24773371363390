import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let selected_person_id = useDataValue({
    context: 'selected',
    path: 'person_id',
    viewPath: props.viewPath,
  })
  let patient_person_id = useDataValue({
    context: 'patient',
    path: 'person._id',
    viewPath: props.viewPath,
  })

  return {
    variables: {
      person_ids: [patient_person_id, selected_person_id].filter(Boolean),
    },
    // pause: !selected_person_id,
  }
}
