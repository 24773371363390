import { useDataSubmit, useDataValue } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import mutation from './mutation.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let sync_id = useDataValue({
    context: 'tab',
    path: 'sync_id',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'tab',
    viewPath: props.viewPath,
  })

  let [, notify] = useNotifications()

  let [, executeMutation] = useMutation(mutation)

  return async function onSubmit({ value, originalValue, args, change }) {
    // get selected patients and do the sync
    let matched = value
      .filter(item => item.selected)
      .map(item => ({
        external: item.external.external,
        patient_id: item.internal.id,
      }))

    if (matched.length === 0) {
      return notify(notifyError('Please select patients to sync.'))
    }

    let mutationResponse = await executeMutation({
      sync_id,
      matched,
    })
    if (mutationResponse.error) {
      return notify(
        notifyError('Something went wrong. Please try again later.')
      )
    }

    notify(
      notifySuccess(
        `${mutationResponse.data.apps_complete_sync.patients_synced} patients synced.`
      )
    )
    submit({
      type: 'back',
    })
  }
}
