// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/IntegrationsApplications/Content/Connection/Content/EditPreferences/Content/Main/Content/Content/ActiveStep/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_IntegrationsApplications_Content_Connection_Content_EditPreferences_Content_Main_Content_Content_ActiveStep(
  $location_id: uuid!
  $connection_id: uuid!
  $property_key: String!
) {
  apps_connection_properties_property_source_values(
    connection_id: $connection_id
    location_id: $location_id
    property_key: $property_key
  ) {
    id
    text: value
  }
}

`