// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentPlans/New/Steps/Content/PaymentPlans/Content/Content/PaymentPlan/Content/Content/Send/Content/Sidebar/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentPlans_New_Steps_Content_PaymentPlans_Content_Content_PaymentPlan_Content_Content_Send_Content_Sidebar($id: uuid!) {
  treatments_requests_by_pk(id: $id) {
    id
    settings
    allowed_steps
    downpayment_payment_methods
    autodraft_payment_methods
    max_discount
    tx_plan {
      id
      tx_plan_template {
        id
        tx_plan_group {
          id
          custom_payment_plan_ruleset {
            id
            min_downpayment
            min_downpayment_type
            max_length
            max_length_type
          }
        }
      }
    }
  }
}

`