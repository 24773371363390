import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { useDataValue } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'

import mutationAppsConnectionResourceEnable from './mutation-apps-connection-resource-enable.graphql.js'
import mutationAppsConnectionResourceDisable from './mutation-apps-connection-resource-disable.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data, reExecuteQuery) {
  let [, executeAppsConnectionResourceEnableMutation] = useMutation(
    mutationAppsConnectionResourceEnable
  )
  let [, executeAppsConnectionResourceDisableMutation] = useMutation(
    mutationAppsConnectionResourceDisable
  )

  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()

  let connection_resource_id = useDataValue({
    context: 'connection_resource',
    path: 'id',
    viewPath: props.viewPath,
  })

  let connection_title = useDataValue({
    context: 'connection_definition',
    path: 'title',
    viewPath: props.viewPath,
  })

  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'activate_location_connection': {
        let mutationResponse =
          await executeAppsConnectionResourceEnableMutation({
            id: connection_resource_id,
          })

        if (
          mutationResponse.error ||
          !mutationResponse.data.apps_connection_resource_enable.ok
        ) {
          return notify(
            notifyError(
              'An error occurred trying to enable the connection. Please refresh the page and try again.'
            )
          )
        } else {
          notify(notifySuccess(`Connection to ${connection_title} is enabled.`))
        }

        return
      }
      case 'complete_setup': {
        setFlowTo(normalizePath(props.viewPath, 'SetupAutomations/Content'))
        return
      }
      case 'create_automations': {
        setFlowTo(normalizePath(props.viewPath, 'SetupAutomations/Content'))
        return
      }
      case 'disable_connection': {
        if (
          !window.confirm(
            'Are you sure you want to disable the integration for your current location? This will also deactivate any automations added by the integration. You can re-enable it at any time.'
          )
        )
          return

        let mutationResponse =
          await executeAppsConnectionResourceDisableMutation({
            id: connection_resource_id,
          })
        if (mutationResponse.error) {
          return notify(
            notifyError(
              'An error occurred trying to disable the connection. Please refresh the page and try again.'
            )
          )
        } else {
          notify(
            notifySuccess(`Connection to ${connection_title} is disabled.`)
          )
        }

        return
      }

      default: {
        return
      }
    }
  }
}
