// this is an autogenerated file from App/Account/Content/Patients/Content/Content/TopBar/CustomResource/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_TopBar_CustomResource($patient_id: uuid!) {
  apps_display_latest_resource(
    type: ADMIN_PATIENT_HEADER_STATUS
    patient_id: $patient_id
  ) {
    id
    created_at
    updated_at
    name
    statusType
    statusText
    note
    link
  }
}

`