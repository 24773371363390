import { useEffect } from 'react'
import { useMutation } from 'Data/Api.js'
import { useDataValue, useDataSubmit } from 'Simple/Data'

import {
  systemUserNotification,
  useNotifications,
} from 'Logic/Notifications.js'

import mutation from './mutation.graphql.js'

import View from './view.js'

let ACTION_TYPES = {
  OPEN_TAB: 'OPEN_TAB',
}

export default function Logic(props) {
  let notification = useDataValue({
    context: 'notification',
    viewPath: props.viewPath,
  })

  let notification_user_id = useDataValue({
    context: 'notification_user',
    path: 'id',
    viewPath: props.viewPath,
  })

  let notification_user_status = useDataValue({
    context: 'notification_user',
    path: 'status',
    viewPath: props.viewPath,
  })

  let submit = useDataSubmit({
    context: 'global',
    viewPath: props.viewPath,
  })

  let [, notify, close] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  useEffect(() => {
    let action = notification.metadata.payload.action
      ? {
          ...notification.metadata.payload.action,
          onClick: () => {
            if (
              notification.metadata.payload.action.type ===
              ACTION_TYPES.OPEN_TAB
            ) {
              // Destructure and fall back to using name if others aren't provided
              let {
                name,
                id = name,
                type = name,
                viewPath,
                context = {},
              } = notification.metadata.payload.action.value

              submit({
                type: 'tabs/add',
                tab: {
                  type,
                  id,
                  name,
                  viewPath,
                  sync: {
                    ...context,
                  },
                  selected: Object.keys(context).length > 0,
                },
              })
            }
            // Close notification after action button clicked, if defined
            if (notification.metadata.payload.action?.autoClose) {
              setTimeout(() => close(notification), 1500)
            }
          },
        }
      : {}

    if (notification.metadata.payload?.markAsRead) {
      action.onRemove = async () => {
        // Update the user's notification status to read
        await executeMutation({
          id: notification_user_id,
        })
      }
    }

    notify(
      systemUserNotification({
        id: notification.id,
        payload: {
          ...notification.metadata.payload,
          action,
        },
        type: notification.type,
      }),
      {
        hideAfter: notification.metadata.payload?.hideAfter ?? 6000,
        extendExistingNotification: true,
      }
    )

    return () => close(notification)
  }, [notification, notification_user_status]) // eslint-disable-line react-hooks/exhaustive-deps

  return <View {...props} />
}
