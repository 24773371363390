// this is an autogenerated file from DesignSystem/PatientComms/PatientCommsContactMethods/mutationSms.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_PatientComms_PatientCommsContactMethods($number: PhoneNumber!) {
  thread: comms_threads_sms_create(number: $number) {
    id
  }
}

`