// this is an autogenerated file from DesignSystem/ImageCard/Content/Content/Actions/Launchers/Content/Edit/EditModal/Content/Editor/query.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_ImageCard_Content_Content_Actions_Launchers_Content_Edit_EditModal_Content_Editor($patient_id: uuid!) {
  vaxiom_patients(where: { _id: { _eq: $patient_id } }) {
    id
    _id
    person {
      id
      _id
      profile_pic_url
    }
  }
}

`