// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentPlans/New/Steps/Content/PaymentPlans/Content/Content/PaymentPlan/Content/Content/SignedContracts/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentPlans_New_Steps_Content_PaymentPlans_Content_Content_PaymentPlan_Content_Content_SignedContracts($treatment_plan_id: numeric!) {
  treatments_payment_plan_payors(
    where: {
      treatment_plan_id: { _eq: $treatment_plan_id }
      contract: { signed_date: { _is_null: false } }
    }
  ) {
    id
    is_primary
    person_id
    person {
      id
      first_name
      last_name
    }
    contract {
      id
      file
      template_name
      generated_date
      signed_date
      html_file_uuid
      document_template_id
      signature_request_id
    }
  }
}

`