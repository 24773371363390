import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let patients = useDataValue({
    context: 'patients',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!data) return data

    let patientsIds = new Set(
      patients.filter(item => item.internal).map(item => item.internal.id)
    )
    // filter out patients that are already in the list
    return data
      .map(item => item.person)
      .filter(
        item =>
          item.patient &&
          !patientsIds.has(item.patient._id) &&
          item.patient.external_mappings.length === 0
      )
  }, [data, patients])
}
