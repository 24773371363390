import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { useDataValue } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import { useUppy } from 'design-system/FileUploader/react.js'

import mutation from './mutation.graphql.js'

export function useOnClose(props) {
  let id = useDataValue({
    context: 'payor',
    path: 'contract_id',
    viewPath: props.viewPath,
  })

  let uppy = useUppy(props)
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  return async function onClose() {
    let attachments = uppy.getFiles()

    // modal closed without uploading signed contract
    if (attachments.length === 0) {
      setFlowTo(normalizePath(props.viewPath, '../../No'))
      return
    }

    if (attachments.some(attachment => !attachment.progress.uploadComplete)) {
      return notify(
        notifyError(
          'Please upload the pending documents or remove them from the list before closing the modal.'
        )
      )
    }

    let mutationResponse = await executeMutation({
      id,
    })

    if (mutationResponse?.error) {
      return notify(
        notifyError(
          'There was a problem uploading the signed contract. Please try again.'
        )
      )
    }

    setFlowTo(normalizePath(props.viewPath, '../../No'))

    // reset Uppy after successfully uploading files
    uppy.cancelAll()
  }
}
