// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/IntegrationsApplications/Content/SyncPatients/data.graphql
import { gql } from 'Data/Api'

export default gql`
subscription app__App_Account_Content_Settings_AdminContent_SettingsContent_IntegrationsApplications_Content_SyncPatients($sync_id: uuid!) {
  apps_sync_by_pk(id: $sync_id) {
    id
    file_url
    metadata
    processingPage: metadata(path: "processing.page")
    matchingPage: metadata(path: "matching.page")
    status
    connection_id
  }
}

`