import {
  notifyError,
  useNotifications,
  notifySuccess,
} from 'Logic/Notifications.js'
import { useDataValue, useDataSubmit } from 'Simple/Data.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { useMutation } from 'Data/Api.js'

import mutationMarkConnectionInactive from './mutation-mark-connection-inactive.graphql.js'

export function useOnSubmit(props) {
  let [, executeMarkConnectionInactiveMutation] = useMutation(
    mutationMarkConnectionInactive
  )

  let [, notify] = useNotifications()

  let setFlowTo = useSetFlowTo(props.viewPath)
  let connection_id = useDataValue({
    viewPath: props.viewPath,
    context: 'connection',
    path: 'id',
  })
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'connections',
  })

  return async function onSubmit() {
    let mutationResponse = await executeMarkConnectionInactiveMutation({
      id: connection_id,
    })

    if (
      mutationResponse.error ||
      !mutationResponse.data.apps_connection_disconnect.ok
    ) {
      return notify(notifyError('An error has occurred disconnecting the app'))
    }

    submit({ type: 'refetch' })
    setFlowTo(normalizePath(props.viewPath, '../../No'))

    return notify(
      notifySuccess('The application was disconnected successfully')
    )
  }
}
