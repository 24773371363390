// this is an autogenerated file from App/Account/PatientOverlay/Content/Content/Appointments/TabContent/Content/AppointmentsList/Content/Appointment/BottomAction/Content/Unseat/mutation-requirements-check.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_PatientOverlay_Content_Content_Appointments_TabContent_Content_AppointmentsList_Content_Appointment_BottomAction_Content_Unseat($id: uuid!, $params: jsonb) {
  create_pre_appointment_bookings_state_update_automations(
    appointment_booking_id: $id
    state: "UNSEATED"
    params: $params
  ) {
    event_execution_ids
    is_pending
  }
}

`