// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentPlans/New/Steps/Content/TreatmentPlanTemplates/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentPlans_New_Steps_Content_TreatmentPlanTemplates($organization_id: uuid!) {
  vaxiom_tx_plan_templates(
    where: {
      deleted: { _eq: false }
      organization: { _id: { _eq: $organization_id } }
      _or: [
        { tx_plan_group_id: { _is_null: true } }
        { tx_plan_group: { deleted: { _eq: false } } }
      ]
    }
    order_by: { tx_plan_group: { name: asc_nulls_last }, name: asc_nulls_last }
  ) {
    id
    name
    fee
    length_in_weeks
    tx_plan_group {
      id
      _id
      name
    }
  }
}

`