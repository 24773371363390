import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return data
      .map(({ from_person_relationships, ...item }) => ({
        ...item,
        relationship_type: from_person_relationships[0]?.type || null,
      }))
      .sort((a, b) => (a.relationship_type === null ? -1 : 1))
  }, [data])
}
