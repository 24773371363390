import {
  notifyInvalid,
  notifyError,
  useNotifications,
} from 'Logic/Notifications.js'
import { sixDigits as isValidSixDigits } from 'Data/validate.js'
import { useMutation } from 'Data/Api.js'
import mutation from './mutation-mfa.graphql.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { getAccessTokenData, setSkipMfaToken } from 'Data/Auth.js'
import { useDataValue } from 'Simple/Data.js'

export default function useDataOnActionMfa(props, data) {
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)
  let setFlowTo = useSetFlowTo(props.viewPath)
  let xid = useDataValue({
    viewPath: props.viewPath,
    context: 'auth',
    path: 'access_token_data.xid',
  })

  return async function onActionMfa({ value, change }) {
    if (isInvalid(value, notify)) return true

    let mutationResponse = await executeMutation(
      {
        ticket: value.mfa.ticket,
        token: value.mfa.token,
        skip_next: value.mfa.skip_next,
        xid,
      },
      {
        fetchOptions: {
          headers: {
            'x-hasura-role': 'public',
          },
        },
      }
    )

    if (
      mutationResponse.error ||
      mutationResponse.data.vaxiom_users_login_mfa.status !== 'ok'
    ) {
      return notify(
        notifyError('Please verify the two-factor authentication token')
      )
    }

    if (value.mfa.skip_next) {
      setSkipMfaToken(
        value.selected_email,
        mutationResponse.data.vaxiom_users_login_mfa.skip_mfa_token
      )
    }

    let access_token_data = getAccessTokenData(
      mutationResponse.data.vaxiom_users_login_mfa.access_token
    )

    change(next => {
      next.access_token =
        mutationResponse.data.vaxiom_users_login_mfa.access_token
      next.role = access_token_data.default_role
      next.mfa = null
    })
    setFlowTo(normalizePath(props.viewPath, 'Content/ClockInOrOut'))
  }

  function isInvalid(value, notify) {
    let list = [
      !isValidSixDigits(value.mfa.token) && 'two-factor auth token',
    ].filter(Boolean)

    if (list.length > 0) {
      notify(notifyInvalid(list))
      return true
    }
    return false
  }
}
