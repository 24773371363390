import {
  notifySuccess,
  notifyError,
  useNotifications,
} from 'Logic/Notifications.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useMutation } from 'Data/Api.js'

import mutation from './mutation.graphql.js'
import { useDataSubmit } from 'Simple/Data.js'

function validate(value) {
  return !value.carrier || !value.number || !value.billing_center
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let submit = useDataSubmit({
    context: 'insurances',
    viewPath: props.viewPath,
  })
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args?.type) {
      case 'submit': {
        if (validate(value)) return true

        setFlowTo(normalizePath(props.viewPath, 'Content/Confirm/Content'))
        return
      }
      case 'register_insurance_plan': {
        // close the current modal and delegate to the higher level context to redirect to the exact view
        submit({ type: 'register_insurance_plan' })
        setFlowTo(normalizePath(props.viewPath, '../No'))
        return
      }
      case 'edit': {
        try {
          let is_deleted = !value.ortho_coverage && originalValue.ortho_coverage
          let mutationResponse = await executeMutation({
            id: value.id,
            name: value.name,
            number: value.number,
            insurance_company_id: value.carrier,
            insurance_billing_center_id: value.billing_center,
            description: value.description,
            last_verified: value.last_verified,
            is_deleted,
            editcoverage: !is_deleted,
            cid: value.coverage_id || 0,
            ortho_coverage: {
              max_lifetime_coverage: value.lifetime_coverage,
              dependent_age_limit: value.dependent_age_limit || null,
              reimbursement_percentage: value.reimbursement_rate,
              age_limit_cutoff: value.age_limit_effective,
              deductible_amount: value.annual_deductible,
              waiting_period: value.waiting_period,
              downpayment_type: value.downpayment_type,
              inprogress: value.inprogress,
              downpayment_percentage: value.downpayment_percentage,
              downpayment_amount: value.downpayment_amount,
              downpayment_percentage_base: value.downpayment_percentage_base,
              cob_type: value.cob_type,
            },
            employers: value.insurance_plan_employers.map(e => ({
              employer_id: e.id,
              insurance_plan_id: value.id,
            })),
          })
          if (mutationResponse.error) {
            notify(notifyError(`Cannot edit insurance plan. Please try again.`))
            return
          }
          notify(notifySuccess('Edited successfully.'))
          setFlowTo(normalizePath(props.viewPath, '../No'))
        } catch (err) {
          notify(notifyError('Cannot edit insurance plan. Please try again.'))
        }
        break
      }
      default: {
        return
      }
    }
  }
}
