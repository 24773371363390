// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentPlans/New/Steps/Content/Contracts/Content/Payor/Content/RemoveSignatureAction/Content/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentPlans_New_Steps_Content_Contracts_Content_Payor_Content_RemoveSignatureAction_Content_Content(
  $contract_id: numeric!
  $treatment_plan_id: numeric!
  $person_id: numeric!
) {
  update_vaxiom_tx_contracts_by_pk(
    pk_columns: { id: $contract_id }
    _set: { signed_date: null, signature_request_id: null }
  ) {
    id
    signed_date
    signature_request_id
  }
  vaxiom_contracts_create(
    treatment_plan_id: $treatment_plan_id
    person_id: $person_id
  ) {
    id
  }
}

`