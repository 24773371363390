// this is an autogenerated file from App/Account/Content/Calendar/New/AppointmentOverlay/Content/AppointmentData/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Calendar_New_AppointmentOverlay_Content_AppointmentData_Content($appointment_id: uuid!) {
  vaxiom_appointments(where: { _id: { _eq: $appointment_id } }) {
    id
    _id
    duration
    type {
      id
      _id
      display_name
    }
    patient {
      id
      _id
    }
    tx {
      id
      _id
      organization {
        id
        _id
        name
        time_zone_id
      }
    }
    booking {
      id
      _id
      local_start_time
      local_start_date
      state
      chair {
        id
        _id
        resource {
          id
          organization {
            _id
          }
        }
      }
      location {
        id
        _id
        name
        time_zone_id
      }
    }
    notes(where: { note: { _neq: "" }, visible_in_schedule: { _eq: true } }) {
      id
      note
      author_user {
        id
        person {
          id
          first_name
          last_name
        }
      }
    }
  }
}

`