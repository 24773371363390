// this is an autogenerated file from DesignSystem/ImageCard/Content/Content/mutation-delete-image.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_ImageCard_Content_Content($id: numeric!) {
  delete_patient_image(id: $id) {
    id
  }
}

`