// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/NewImageSeriesAction/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_NewImageSeriesAction_Content(
  $name: String!
  $series_date: timestamp!
  $patient_id: numeric!
  $treatment_id: numeric
  $appointment_id: numeric
) {
  insert_vaxiom_patient_imageseries_one(
    object: {
      name: $name
      patient_id: $patient_id
      series_date: $series_date
      treatment_id: $treatment_id
      appointment_id: $appointment_id
    }
  ) {
    id
    _id
    name
    patient_id
    series_date
    treatment_id
    appointment_id
  }
}

`