// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/Content/Content/Content/Content/CreateIteroScanRequest/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_Content_Content_Content_Content_CreateIteroScanRequest_Content($location_id: uuid!) {
  apps_connection_resources(
    where: {
      status: { _eq: enabled }
      resource_id: { _eq: $location_id }
      # TODO: ideally it should also check the app_id
      connection: {
        app: { metadata: { _contains: { tags: ["itero"] } } }
        status: { _eq: active }
      }
    }
  ) {
    id
    connection {
      id
      app_id
      name
    }
  }
  apps_connection_resource_properties(
    where: {
      resource_id: { _eq: $location_id }
      # TODO: ideally it should also check the app_id
      connection: {
        app: { metadata: { _contains: { tags: ["itero"] } } }
        status: { _eq: active }
      }
    }
  ) {
    id
    resource_id
    connection_id
    properties
  }
}

`