import View from './view.js'
import { useDataIsSubmitting } from 'Simple/Data'

export default function Logic(props) {
  let isSubmitting = useDataIsSubmitting({
    context: 'steps',
    viewPath: props.viewPath,
  })

  return <View {...props} disabled={isSubmitting} />
}
