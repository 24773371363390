// this is an autogenerated file from App/Account/UserNotifications/Content/Notifications/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_UserNotifications_Content_Notifications_Content($id: uuid!) {
  update_notifications_users_by_pk(
    pk_columns: { id: $id }
    _set: { status: READ }
  ) {
    id
  }
}

`