import { useDataValue } from 'Simple/Data.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

export function useOnClick(props) {
  let show_financials_managed_externally = useDataValue({
    context: 'treatment',
    path: 'show_financials_managed_externally',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)

  return function onClick() {
    setFlowTo(
      normalizePath(
        props.viewPath,
        show_financials_managed_externally
          ? '../../FinancialsManagedExternally'
          : '../../Normal'
      )
    )
  }
}
