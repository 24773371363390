import { notifyError, useNotifications } from 'Logic/Notifications.js'

import useListItemDataOnActionCancelOAuth2Connection from './useListItemDataOnActionCancelOAuth2Connection.js'
import useListItemDataOnActionConnectApiToken from './useListItemDataOnActionConnectApiToken.js'
import useListItemDataOnActionConnectOAuth2 from './useListItemDataOnActionConnectOAuth2.js'
import useListItemDataOnActionInstall from './useListItemDataOnActionInstall.js'
import useListItemDataOnActionOAuth2Connected from './useListItemDataOnActionOAuth2Connected.js'

export default function useListItemDataOnSubmit(props, data) {
  let onActionInstall = useListItemDataOnActionInstall(props, data)
  let onActionConnectApiToken = useListItemDataOnActionConnectApiToken(
    props,
    data
  )
  let onActionConnectOAuth2 = useListItemDataOnActionConnectOAuth2(props, data)
  let onActionCancelOAuth2Connection =
    useListItemDataOnActionCancelOAuth2Connection(props, data)
  let onActionOAuth2Connected = useListItemDataOnActionOAuth2Connected(
    props,
    data
  )

  let [, notify] = useNotifications()

  return async function onSubmit({ value, args, change }) {
    switch (args.type) {
      case 'install': {
        return await onActionInstall({ value, args, change })
      }

      case 'connect-api-token': {
        return await onActionConnectApiToken({ value, args, change })
      }

      case 'connect-oauth2': {
        return await onActionConnectOAuth2({ value, args, change })
      }

      case 'cancel-oauth2-connection': {
        return await onActionCancelOAuth2Connection({ value, args, change })
      }

      case 'oauth2-connected': {
        return await onActionOAuth2Connected({ value, args, change })
      }

      default: {
        return notify(notifyError('Invalid action'))
      }
    }
  }
}
