import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let chairs = useDataValue({
    context: 'resources',
    viewPath: props.viewPath,
    path: 'chairs',
  })

  let chairs_in_view = useDataValue({
    context: 'settings',
    viewPath: props.viewPath,
    path: 'chairs_in_view',
  })

  return useMemo(
    () => ({
      options: Array.from({
        length: chairs.length ?? 0,
      }).map((_, index) => ({
        id: index + 1,
        text: `${index + 1} chair${index > 0 ? 's' : ''}`,
      })),
      selected: chairs_in_view ? chairs_in_view : Math.min(5, chairs.length),
    }),
    [chairs, chairs_in_view]
  )
}
