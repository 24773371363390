// Auth works together with Api. There are some extra notes on Api.js that
// complement these.
//
// It stores its access and refresh tokens in local storage and coordinates
// refreshing the token across tabs using local storage events.
//
// It also has a few fallback mechanisms to catch race conditions when a token
// was refreshed by another tab but the current tab made a request to refresh
// the token with the same (recently used) refresh token that resulted in an
// unauthorized request, so at that point we look into local storage just in
// case another tab got the right token.
//
// Should anything fail, the user is redirected to /App/Auth/SignOut.
//
// When a user is signed in on one tab, opening another one will log them in
// automatically.
// However, if a user had two or more logged out tabs and logs in on one of
// them, the others won't be automatically logged in. We could enable this
// through local storage events but I think it's probably ok to leave this
// as is for now.
//
import { useSetFlowTo } from 'Simple/Flow.js'
import { captureBreadcrumb } from 'Logic/ErrorBoundary.js'
import { DataProvider, useDataChange, useDataValue } from 'Simple/Data.js'
import {
  localStorage,
  windowAddEventListenerStorage,
  windowRemoveEventListenerStorage,
} from 'Logic/localStorage'
import { useMutation } from 'Data/Api.js'
import { useDataValueOnce } from 'Logic/FlowShortcuts.js'
// we need to import gql directly in here otherwise Webpack can't find the reference
import { gql } from 'urql'
import makeDebug from 'Simple/debug.js'
import toSnakeCase from 'to-snake-case'
import React, { useEffect, useLayoutEffect, useMemo, useRef } from 'react'
import { ALLOWED_ROLES, DEFAULT_ROLE } from 'Data/constants'

let debug = makeDebug('simple/Auth')

let mutationRefreshToken = gql`
  mutation vaxiom_users_refresh_token($refresh_token: String!, $xid: String) {
    auth_refresh_token: vaxiom_users_refresh_token(
      refresh_token: $refresh_token
      xid: $xid
    ) {
      status
      access_token: jwt_token
      refresh_token
      inactivity_timeout
    }
  }
`

let AUTH_KEY = 'auth'
let AUTH_TEMPORARY_KEY = 'auth_temporary'

export function Auth(props) {
  let authTokenData = useDataValueOnce({
    viewPath: props.viewPath,
    context: 'flow_shortcuts',
    path: 'token',
  })

  let setFlowTo = useSetFlowTo(props.viewPath)

  let value = useMemo(
    () =>
      authTokenData
        ? loginData({ refresh_token: authTokenData })
        : getAuthFromLocalStorage(),
    [authTokenData]
  )

  useReleaseOldRefreshTokenRights()

  return (
    <DataProvider
      context="auth"
      onChange={onChange}
      value={value}
      viewPath={props.viewPath}
    >
      <Sync viewPath={props.viewPath} />
      {props.children}
    </DataProvider>
  )

  async function onChange(next) {
    let data = { api_role: next.api_role }
    if (data.api_role && data.api_role !== 'public') {
      data.id =
        next.access_token_data?.user_id ||
        next.access_token_data?.vaxiom_user_id
    }

    captureBreadcrumb({ category: 'auth', data })

    // by checking for the non-existence of the localstorage item within the
    // onchange of the auth provider we can know that an explicit logout has
    // happened and logout other open tabs
    if (
      !isSessionTemporary() &&
      !hasLocalStorageAuthentication() &&
      next.access_token
    ) {
      setFlowTo(props.authSignOutView)
    }
  }
}

Auth.defaultProps = {
  authSignOutView: '/App/Auth/SignOut',
}

function hasLocalStorageAuthentication() {
  return !!localStorage.getItem(AUTH_KEY)
}

function Sync(props) {
  let change = useDataChange({ context: 'auth', viewPath: props.viewPath })

  useEffect(() => {
    if (isSessionTemporary()) return

    function listener(event) {
      if (event.key === AUTH_KEY) {
        debug({
          type: 'Sync',
          event,
        })

        change(getAuthFromLocalStorage())
      }
    }

    windowAddEventListenerStorage(listener)

    return () => {
      windowRemoveEventListenerStorage(listener)
    }
  }, [change])

  return null
}

export function useAuthRef({ viewPath }) {
  let data = useDataValue({ context: 'auth', viewPath: viewPath })
  let ref = useRef(data)

  useLayoutEffect(() => {
    ref.current = data
  }, [data])

  return ref
}

export function useRefreshToken({ viewPath }) {
  let [, mutate] = useMutation(mutationRefreshToken)
  let refresh_token = useDataValue({
    viewPath,
    context: 'auth',
    path: 'refresh_token',
  })
  let xid = useDataValue({
    viewPath,
    context: 'auth',
    path: 'access_token_data.xid',
  })
  let authDataChange = useDataChange({
    viewPath,
    context: 'auth',
  })

  return async function _refreshToken() {
    if (!refresh_token) return {}

    debug({ type: 'useRefreshToken/_refreshToken', refresh_token })

    let nextAuth = await refreshToken({
      mutate: (_, variables, context) => mutate(variables, context),
      refresh_token,
      xid,
    })
    authDataChange(nextAuth)
    return nextAuth
  }
}

export async function refreshToken({ mutate, refresh_token, xid = null }) {
  if (!refresh_token) {
    return logout()
  }
  debug({ type: 'Auth/refreshToken', refresh_token })

  let hasRefreshTokenRight = await claimRefreshTokenRight(refresh_token)
  if (!hasRefreshTokenRight) {
    let wasRefreshedElsewhere = await waitForTokenToBeRefreshed()
    if (wasRefreshedElsewhere) {
      return getAuthFromLocalStorage()
    }
  }

  debug({ type: 'Auth/refreshToken/got-lock', refresh_token })

  let variables = { refresh_token }
  if (xid !== null) {
    variables.xid = xid
  }

  try {
    let mutationResponse = await mutate(mutationRefreshToken, variables, {
      fetchOptions: {
        headers: {
          'x-hasura-role': 'public',
        },
      },
    })

    debug({ type: 'Auth/refreshToken', mutationResponse })

    if (
      mutationResponse.error ||
      mutationResponse.data.auth_refresh_token.status !== 'ok'
    ) {
      return logout()
    } else {
      return login(mutationResponse.data.auth_refresh_token)
    }
  } catch (error) {
    debug({ type: 'Auth/refreshToken', error })
    return getAuthFromLocalStorage()
  }
}

let REFRESH_TOKEN_RIGHT_KEY = 'refresh_token_right_'
function getRefreshTokenRightKey(refresh_token) {
  return `${REFRESH_TOKEN_RIGHT_KEY}${refresh_token}`
}

async function claimRefreshTokenRight(refresh_token) {
  if (isSessionTemporary()) return true

  let key = getRefreshTokenRightKey(refresh_token)

  if (localStorage.getItem(key)) {
    return false
  } else {
    let value = `${Date.now()}-${Math.random()}`
    localStorage.setItem(key, value)
    // wait a bit to ensure the value wasn't stepped over by another tab
    await Promise.resolve()
    // TODO: explore this https://developer.mozilla.org/en-US/docs/Web/API/Web_Locks_API
    return localStorage.getItem(key) === value
  }
}

let THIRTY_MINUTES_IN_MS = 30 * 60 * 1_000
function useReleaseOldRefreshTokenRights() {
  useEffect(() => {
    Object.keys(localStorage)
      .filter(item => item.startsWith(REFRESH_TOKEN_RIGHT_KEY))
      .forEach(item => {
        try {
          let [date] = localStorage.getItem(item).split('-')
          if (Date.now() - THIRTY_MINUTES_IN_MS < parseInt(date, 10)) {
            localStorage.removeItem(item)
          }
        } catch (_) {
          localStorage.removeItem(item)
        }
      })
  }, [])
}

async function waitForTokenToBeRefreshed(timeout = 20_000) {
  return new Promise(resolve => {
    function listener(event) {
      if (event.key === AUTH_KEY) {
        debug({
          type: 'waitForTokenToBeRefreshed',
          event,
        })

        windowRemoveEventListenerStorage(listener)
        clearTimeout(timeoutId)
        resolve(true)
      }
    }

    windowAddEventListenerStorage(listener)

    let timeoutId = setTimeout(() => {
      windowRemoveEventListenerStorage(listener)
      resolve(false)
    }, timeout)
  })
}

/**
 * @param {{
 *   access_token?: string
 *   refresh_token?: string
 *   inactivity_timeout?: number
 * }} params
 * @returns {{
 *   access_token?: string
 *   access_token_data?: any
 *   refresh_token?: string
 *   inactivity_timeout?: number
 *   api_role: 'public' | 'app-admin'
 * }}
 */
export function login({
  access_token = null,
  refresh_token = null,
  inactivity_timeout = null,
}) {
  let value = (access_token || refresh_token) && {
    access_token,
    refresh_token,
    inactivity_timeout,
  }

  if (value && !isSessionTemporary()) {
    localStorage.setItem(AUTH_KEY, JSON.stringify(value))
  } else {
    localStorage.removeItem(AUTH_KEY)
  }

  return loginData({
    access_token,
    refresh_token,
    inactivity_timeout,
  })
}

function isSessionTemporary() {
  return sessionStorage.getItem(AUTH_TEMPORARY_KEY)
}

export function maybeMakeSessionTemporary(temporary = false) {
  if (temporary) {
    sessionStorage.setItem(AUTH_TEMPORARY_KEY, 'true')
  } else {
    sessionStorage.removeItem(AUTH_TEMPORARY_KEY)
  }
}

// `loginData` is used to get the shape of the auth data without
// modifying local storage.
// This is useful when a sign in token is provided and there
// are other sessions logged in. If we reset local storage
// only with the refresh_token the other tabs will see their
// auth changed to public for a moment until the check is done
// and they eventually get the new token info in.
// That was leading to unexpected bugs like the websocket
// connections being terminated:
// https://greyfinch.sentry.io/issues/4021592168/events/3bebf5383ac042d6b7bbe6eb10685ecc/?project=5392841
// and the whole cache for the tab being destroyed too as Api
// was recreated
function loginData({
  access_token = null,
  refresh_token = null,
  inactivity_timeout = null,
}) {
  let access_token_data = getAccessTokenData(access_token)

  let api_role = access_token_data.allowed_roles?.find(role =>
    ALLOWED_ROLES.includes(role)
  )

  return {
    access_token,
    access_token_data,
    // TODO Add option 'Act as support' instead of setting support role automatically
    // TODO replace for app's name
    api_role: api_role ? api_role : DEFAULT_ROLE,
    refresh_token,
    inactivity_timeout,
  }
}
export function getAccessTokenData(token) {
  if (token === null) return {}
  try {
    let data = JSON.parse(atob(token.split('.')[1]))
    let claims = Object.fromEntries(
      Object.entries(data['https://hasura.io/jwt/claims']).map(
        ([key, value]) => [toSnakeCase(key.replace(/^x-hasura-/, '')), value]
      )
    )
    return {
      ...data,
      ...claims,
    }
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'development') {
      debug({ type: 'auth/getAccessTokenData', error, token })
    }
    return {}
  }
}

let ACKNOWLEDGED_NOTIFICATIONS_SESSION_KEY = 'acknowledged_notifications'
export function logout() {
  sessionStorage.removeItem(ACKNOWLEDGED_NOTIFICATIONS_SESSION_KEY)
  localStorage.removeItem('choseLocationAfterLogin')
  localStorage.removeItem(AUTH_KEY)
  localStorage.removeItem('inactivity_tracker_timeout')
  localStorage.removeItem('aws_credentials')

  return {
    access_token: null,
    access_token_data: {},
    api_role: 'public',
    refresh_token: null,
    inactivity_timeout: null,
  }
}

export function getTimeToTokenExpirationInMs({
  access_token,
  access_token_data,
}) {
  if (!access_token) return 0

  return Math.ceil(access_token_data.exp - Date.now() / 1000) * 1000
}

export function isTokenExpired({ access_token, access_token_data }) {
  return log(access_token === null || Date.now() / 1000 > access_token_data.exp)

  function log(value) {
    if (value) {
      debug({
        type: 'Auth/isTokenExpired',
        value,
        expires_in_ms: getTimeToTokenExpirationInMs({
          access_token,
          access_token_data,
        }),
        access_token,
        access_token_data,
      })
    }
    return value
  }
}

export function isAccessTokenValid(auth) {
  return !isTokenExpired(auth)
}

function getAuthFromLocalStorage() {
  try {
    let { access_token, refresh_token, inactivity_timeout } = JSON.parse(
      localStorage.getItem(AUTH_KEY)
    )
    if (access_token || refresh_token) {
      let value = loginData({ access_token, refresh_token, inactivity_timeout })
      return isTokenExpired(value)
        ? login({ access_token: null, refresh_token, inactivity_timeout })
        : value
    }
  } catch (error) {}

  return logout()
}

let SKIP_MFA_TOKEN_KEY = 'auth_skip_mfa_token'
export function getSkipMfaToken(email) {
  return localStorage.getItem(`${SKIP_MFA_TOKEN_KEY}_${email}`) || null
}

export function setSkipMfaToken(email, token) {
  localStorage.setItem(`${SKIP_MFA_TOKEN_KEY}_${email}`, token)
}
