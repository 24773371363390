import { useDataValue } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'

import mutation from './mutation.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let treatment_plan = useDataValue({
    context: 'treatment_plan',
    viewPath: props.viewPath,
  })
  let payment_plan = useDataValue({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })

  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, executeMutation] = useMutation(mutation)

  return async function onSubmit({ value, args, change }) {
    if (
      payment_plan.insurances.some(insurance => !insurance.insured_id) ||
      payment_plan.payors.some(payor => !payor.person_id) ||
      (payment_plan.insurances.length > 0 && !payment_plan.insurance_code_id)
    ) {
      setFlowTo(normalizePath(props.viewPath, 'MissingPayors/Content'))
      return
    }

    try {
      setFlowTo(normalizePath(props.viewPath, 'Loading/Content'))

      let results = await Promise.all(
        payment_plan.payors.map(payor =>
          executeMutation({
            treatment_plan_id: treatment_plan.id,
            person_id: payor.person_id,
          })
        )
      )

      if (results.some(result => result?.error)) {
        notify(
          notifyError(
            'There was a problem creating the contract. Please try again.'
          )
        )
        return
      }

      for (let mutationResponse of results) {
        window.open(
          `/preview-contract/${mutationResponse.data.vaxiom_contracts_create._id}`,
          '_blank'
        )
      }
    } finally {
      setFlowTo(normalizePath(props.viewPath, 'Loading/No'))
    }
  }
}
