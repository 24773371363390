import View from './view.js'
import React, { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'
import { statusActionDescription } from 'Data/format.js'
import { THREAD_STATUS_ORDERED_WITHOUT_NEW } from 'Data/constants.js'
import { useDisabled } from './helpers.js'

export default function Logic(props) {
  let messageData = useDataValue({
    viewPath: props.viewPath,
    context: 'message_compose',
    path: 'message',
  })

  let statuses = useMemo(() => {
    // don't use the first item, new
    return THREAD_STATUS_ORDERED_WITHOUT_NEW.map(item => ({
      text: statusActionDescription(messageData, item),
      id: item,
    }))
  }, [messageData])

  let disabled = useDisabled(props)

  return <View from={statuses} disabled={disabled} viewPath={props.viewPath} />
}
