import React from 'react'
import View from './view.js'
import { useDataValue } from 'Simple/Data.js'

export default function Logic(props) {
  let slot_min_time = useDataValue({
    context: 'timegrid',
    path: 'slot_min_time',
    viewPath: props.viewPath,
  })
  let [, start_minutes] = slot_min_time.split(':')
  let [timePart, range] = props.time.split(/(am|pm)/).filter(Boolean)
  let [hours, minutes] = timePart.split(':')
  if (!minutes) minutes = '00'

  return (
    <View
      {...props}
      hours={start_minutes === minutes && hours}
      range={start_minutes === minutes && range}
      minutes={minutes}
    />
  )
}
