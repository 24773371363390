import { useDataChange } from 'Simple/Data.js'

export function useOnClick(props) {
  let setInsuranceId = useDataChange({
    context: 'filter',
    path: 'assign_insurance_id',
    viewPath: props.viewPath,
  })

  return function onClick() {
    setInsuranceId(null)
  }
}
