import { useDataChange } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import { addPrefixIfMissing } from 'Data/format.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import mutation from './mutationSms.graphql.js'

export default function useDataOnActionSms(props) {
  let change = useDataChange({
    context: 'comms',
    path: 'thread_id',
    viewPath: props.viewPath,
  })

  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  return async function onActionSms({ args }) {
    let mutationResponse = await executeMutation({
      number: addPrefixIfMissing(args.number),
    })

    if (mutationResponse.error) {
      return notify(notifyError(`There was a problem starting this thread.`))
    }

    change(mutationResponse.data.thread.id)

    if (typeof props.onSuccess === 'function') props.onSuccess()
  }
}
