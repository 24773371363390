import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'register_insurance_plan': {
        setFlowTo([
          normalizePath(props.viewPath, 'AddAction/Content'),
          normalizePath(
            props.viewPath,
            'AddAction/Content/Content/Filter/RegisterAction/Content'
          ),
        ])
        return
      }
      default:
        return
    }
  }
}
