import { useRef } from 'react'
import {
  notifyError,
  useNotifications,
  notifySuccess,
} from 'Logic/Notifications.js'
import { useMutation } from 'Data/Api.js'
import { useDataSubmit, useDataValue } from 'Simple/Data.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

import mutationApiAuthorize from './mutation-api-authorize.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data, reExecuteQuery) {
  let [, executeApiAuthorizeMutation] = useMutation(mutationApiAuthorize)

  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)
  let submit = useDataSubmit({
    context: 'connections',
    viewPath: props.viewPath,
  })
  let location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location._id',
  })

  let authWindow = useRef(null)
  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'connect': {
        setFlowTo(normalizePath(props.viewPath, 'Reconnect/Content'))

        let mutationResponse = await executeApiAuthorizeMutation({
          app_id: value.app_id,
          connection_id: value?.id,
          location_id,
          // TO DO: take this from dynamically generated connection configuration inputs
          // see: https://www.notion.so/getgreyfinch/App-Connections-Refactor-Proposal-96b3ef598e3e4db9b6880b42da3aae09?pvs=4
          connection_properties: {},
        })

        if (
          mutationResponse.error ||
          !mutationResponse.data.apps_external_api_authorize.id ||
          !mutationResponse.data.apps_external_api_authorize.url
        ) {
          return notify(
            notifyError(
              'An error occurred trying to connect the app. Please refresh the page and try again.'
            )
          )
        }

        if (mutationResponse.data.apps_external_api_authorize.url) {
          authWindow.current = window.open(
            mutationResponse.data.apps_external_api_authorize.url,
            'popup',
            `height=768,width=1024,top=${(window.innerHeight - 768) / 2},left=${
              (window.innerWidth - 1024) / 2
            }`
          )
        }

        return
      }
      case 'cancel': {
        try {
          authWindow.current.close()
        } catch (_) {}

        return
      }
      case 'active': {
        submit({ type: 'refetch' })

        return notify(
          notifySuccess('The application was connected successfully')
        )
      }

      default: {
        return
      }
    }
  }
}
