// this is an autogenerated file from App/Account/Content/Calendar/New/AppointmentOverlay/Content/Form/Content/LocationSelect/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Calendar_New_AppointmentOverlay_Content_Form_Content_LocationSelect{
  session_user_locations(order_by: { name: asc }) {
    id
    _id
    name
    time_zone_id
  }
}

`