import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let focused_event_id = useDataValue({
    context: 'timegrid',
    path: 'focused_event_id',
    viewPath: props.viewPath,
  })

  return useMemo(
    () => ({
      ...data,
      ...props.event,
      shouldHighlight: props.event.id === focused_event_id,
    }),
    [data, props.event, focused_event_id]
  )
}
