import { useDataValue, useDataChange } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import mutation from './mutationInApp.graphql.js'

export default function useDataOnActionInApp(props) {
  let change = useDataChange({
    context: 'comms',
    viewPath: props.viewPath,
  })
  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'comms',
    path: 'person.id',
  })
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  return async function onSubmit() {
    let mutationResponse = await executeMutation({
      person_id,
    })

    if (mutationResponse.error) {
      return notify(notifyError(`There was a problem starting this thread.`))
    }

    change(next => {
      next.thread_id = mutationResponse.data.thread.id
      next.is_secure = true
    })

    if (typeof props.onSuccess === 'function') props.onSuccess()
  }
}
