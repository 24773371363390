import { useDataSubmit, useDataValue } from 'Simple/Data.js'

export function getDays() {
  return Array.from({ length: 10 }).map((_, i) => ({
    id: i + 1,
    text: i + 1 + 'd',
  }))
}

export function useOnChangeBefore(props) {
  let blue_bar_range = useDataValue({
    context: 'settings',
    path: 'blue_bar_range',
    viewPath: props.viewPath,
  })

  let submit = useDataSubmit({
    context: 'settings',
    viewPath: props.viewPath,
  })

  return function onClick(id) {
    return submit({
      key: 'blue_bar_range',
      value: { ...blue_bar_range, before: id },
    })
  }
}

export function useOnChangeAfter(props) {
  let blue_bar_range = useDataValue({
    context: 'settings',
    path: 'blue_bar_range',
    viewPath: props.viewPath,
  })

  let submit = useDataSubmit({
    context: 'settings',
    viewPath: props.viewPath,
  })

  return function onClick(id) {
    return submit({
      key: 'blue_bar_range',
      value: { ...blue_bar_range, after: id },
    })
  }
}
