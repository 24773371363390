import { useDataChange, useOnChangeLastValueGuard } from 'Simple/Data.js'

export default function useDataOnChange(props) {
  let change = useDataChange({
    context: 'steps',
    viewPath: props.viewPath,
  })

  return useOnChangeLastValueGuard(function onChange(next) {
    change(inext => {
      if (
        inext.current_index !== null &&
        inext.current_index !== undefined &&
        inext.steps[inext.current_index]
      ) {
        inext.steps[inext.current_index].value = next.value
      }
    })
  })
}
