import { useDataSubmit, useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let submit = useDataSubmit({
    context: 'treatment_plans',
    viewPath: props.viewPath,
  })
  let treatment_plan_id = useDataValue({
    context: 'selected',
    path: 'treatment_plan_id',
    viewPath: props.viewPath,
  })

  return async function onSubmit({ value }) {
    submit({
      type: 'accept',
      is_financials_managed_externally: value.selected,
      treatment_plan_id,
    })
  }
}
