import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data'
import { getParentView } from 'Simple/Flow'
import useEnsureDefaultTab from './useEnsureDefaultTab'
import useEnsureTab from 'Logic/use-ensure-tab'
import { useDataValueOnce } from 'Logic/FlowShortcuts'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let tabs = useDataValue({
    context: 'global',
    path: 'tabs',
    viewPath: props.viewPath,
  })

  let sync = useDataValueOnce({
    context: 'tab',
    path: 'sync',
    viewPath: props.viewPath,
  })
  let appId = useDataValueOnce({
    context: 'flow_shortcuts',
    path: 'appId',
    viewPath: props.viewPath,
  })

  let [, tab_id] = getParentView(props.viewPath)
  let ensureDefaultTab = useEnsureDefaultTab(props, data)

  let tab = useMemo(
    () =>
      ensureDefaultTab(
        tabs.find(item => item.id === tab_id),
        tab_id,
        sync,
        appId
      ),
    [tab_id] // eslint-disable-line
  ) // only care about first render

  useEnsureTab({
    viewPath: props.viewPath,
    tab,
  })

  return tab
}
