import addDays from 'date-fns/addDays/index.js'
import differenceInDays from 'date-fns/differenceInDays/index.js'

import { INACTIVE_CONNECTION_STATUSES } from 'Data/constants.js'

import parseISO from 'date-fns/parseISO/index.js'
let EXPIRY_THRESHOLD_IN_DAYS = 10

export function getView(value) {
  if (value.app.connection?.expiresInDays) {
    let expiresDate = addDays(
      parseISO(value.updated_at),
      value.app.connection.expiresInDays
    )
    return (
      differenceInDays(expiresDate, new Date()) <= EXPIRY_THRESHOLD_IN_DAYS ||
      INACTIVE_CONNECTION_STATUSES.includes(value.status)
    )
  }

  return INACTIVE_CONNECTION_STATUSES.includes(value.status)
}
