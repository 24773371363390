// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/IntegrationsApplications/Content/triggerSyncMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_IntegrationsApplications_Content($connection_id: uuid!, $location_id: uuid!) {
  apps_sync_processing_start(
    connection_id: $connection_id
    location_id: $location_id
  ) {
    ok
    sync_id
  }
}

`