// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/Tabs/Comms/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_Tabs_Comms($person_id: uuid!) {
  vaxiom_relationships(
    where: {
      to_person_record: { _id: { _eq: $person_id } }
      role: { _eq: "RESPONSIBLE" }
    }
  ) {
    id
    type
    from_person_record {
      id
      contact_method_associations(
        where: { contact_method: { dtype: { _eq: "phone" } } }
      ) {
        id
        contact_method {
          id
          mobile_phone {
            id
            number
          }
        }
      }
    }
  }
  vaxiom_contact_method_associations(
    where: {
      contact_method: { dtype: { _eq: "phone" } }
      person: { _id: { _eq: $person_id } }
    }
  ) {
    id
    description
    contact_method {
      id
      mobile_phone {
        id
        number
      }
    }
  }
}

`