// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentPlans/New/Steps/Content/Contracts/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentPlans_New_Steps_Content_Contracts(
  $treatment_plan_id: numeric!
  $current_location_id: numeric!
) {
  treatments_accept_payment_plan(
    treatment_plan_id: $treatment_plan_id
    current_location_id: $current_location_id
  ) {
    ok
  }
}

`