// this is an autogenerated file from App/ChooseLocation/query-session.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_ChooseLocation($user_id: uuid!, $location_id: uuid!) {
  feature_flags: feature_flags_app_admin(location_id: $location_id) {
    id
    is_new_calendar_enabled
    is_new_calendar_default_enabled
    is_marketing_analytics_enabled
    is_pay_app_enabled
    is_new_tx_planning_default_enabled
  }
  locations: vaxiom_sys_organizations(where: { _id: { _eq: $location_id } }) {
    id
    _id
    name
    time_zone_id
    local_current_date
    xid
    parent_company {
      id
      _id
      name
      xid
    }
    permissions {
      id
      has_admin
      has_work_hours
      has_chairs
      has_employee_work_hours
      has_hr_admin
      has_notes
      has_office_calendar
      has_calendar_schedule
      has_calendar_schedule_conflict
      has_patients
      has_tasks
      has_financial
      has_reports
      has_patient_ledger
      has_patient_medical
      has_patient_profile
      has_patient_treatment
      has_patient_documents
      has_patient_images
      has_patient_insurance_edit
      has_calendar
    }
  }
  user_preferences: user_preferences_by_pk(user_id: $user_id) {
    id: user_id
    user_id
    chair_ids
    current_location_id
    hipaa
  }
  comms_user_preferences: comms_user_preferences_by_pk(user_id: $user_id) {
    user_id
    location_ids: data(path: "location_ids")
    mobile_numbers_outbound_ids: data(path: "mobile_numbers_outbound_ids")
  }
  user_details: users_by_pk(id: $user_id) {
    id
    created_at
    account {
      id
      email
    }
    vaxiom_user {
      id
      role
      person {
        id
        title
        first_name
        last_name
        job_title
      }
    }
  }
}

`