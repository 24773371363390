// this is an autogenerated file from App/Account/UserNotifications/Content/Notifications/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_UserNotifications_Content_Notifications($id: uuid!, $location_id: uuid!) {
  notifications(
    where: { id: { _eq: $id }, location_id: { _eq: $location_id } }
  ) {
    id
    metadata
    type
    status
  }
}

`