import { useDataValue } from 'Simple/Data.js'
import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let value = useDataValue({
    context: 'steps',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (value.current_index === null || !value.steps?.length) {
      return null
    }

    let step = value.steps[value.current_index]
    if (!step) return null

    return {
      ...step,
      required: isRequired(step) || null,
    }
  }, [value])
}

function isRequired(step) {
  if (step.step_type === 'automation' && step.required) {
    return {
      ...step.required,
      data: step.required?.data.length
        ? step.required?.data.map((item, index) => ({
            ...item,
            id: index,
            value: null,
          }))
        : null,
    }
  }

  if (step.step_type === 'preferences') {
    return step.required || null
  }
}
