import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'

export function useOnClick(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)

  return function onClick(e) {
    e?.stopPropagation()

    setFlowTo(normalizePath(props.viewPath, 'Content'))
  }
}
