import { useDataChange, useDataValue } from 'Simple/Data.js'
export function useOnChange(props) {
  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })
  let image_series = useDataValue({
    context: 'image_series',
    viewPath: props.viewPath,
  })
  return function onChange(value) {
    let image_series_uuid = image_series.find(is => is.id === value)._id
    change(next => {
      next.selected.images.image_series_id = value
      next.selected.images.image_series_uuid = image_series_uuid
    })
  }
}
