import { useDataChange } from 'Simple/Data'

export function useOnClick(props) {
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'financial_flow',
    path: 'selected',
  })

  return function onClick() {
    change(true)
  }
}
