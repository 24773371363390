export function getText(insurance) {
  return insurance.insured
    ? [
        `${insurance.insured.insurance_subscription.person.first_name} ${insurance.insured.insurance_subscription.person.last_name}`,
        insurance.insured.insurance_subscription.insurance_plan
          .insurance_billing_center?.name ??
          insurance.insured.insurance_subscription.insurance_plan
            .insurance_company.carrier_name,
        insurance.insured.insurance_subscription.insurance_plan.number,
      ].join(', ')
    : 'Select an insurance'
}
