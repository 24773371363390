import View from './view.js'
import { useOnshowCancelInternalFinancialAction } from './helpers.js'

export default function Logic(props) {
  let showCancelInternalFinancialAction =
    useOnshowCancelInternalFinancialAction(props)

  return (
    <View
      {...props}
      showCancelInternalFinancialAction={showCancelInternalFinancialAction}
    />
  )
}
