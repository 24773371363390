import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let sync = useDataValue({
    context: 'tab',
    path: 'sync',
    viewPath: props.viewPath,
  })

  let app_id = useDataValue({
    context: 'app',
    path: 'id',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    return sync?.app_id === app_id ? sync : data
  }, [sync]) // eslint-disable-line
}
