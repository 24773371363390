import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return data.map(d => ({
      _id: d._id,
      vaxiom_id: d.id,
      text: d.name,
      time_zone_id: d.time_zone_id,
    }))
  }, [data])
}
