import {
  notifyError,
  useNotifications,
  notifySuccess,
} from 'Logic/Notifications.js'
import { useMutation } from 'Data/Api'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import mutation from './mutation.graphql.js'
import mutationDismiss from './mutation-dismiss.graphql.js'
import mutationDeleteNote from './mutation-delete-note.graphql.js'

export default function useDataOnSubmit(props, data, reExecuteQuery) {
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)

  let [, executeMutation] = useMutation(mutation)
  let [, executeMutationDismissLead] = useMutation(mutationDismiss)
  let [, executeMutationDeleteNote] = useMutation(mutationDeleteNote)

  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'create-patient': {
        let mutationResponse = await executeMutation({
          lead_id: value.lead.id,
        })

        if (mutationResponse.error) {
          notify(
            notifyError(
              'Cannot create patient from this lead. Please try again.'
            )
          )
          return
        }

        setFlowTo(normalizePath(props.viewPath, 'Content/Detail/No'))
        notify(notifySuccess('Patient created.'))
        return
      }
      case 'dismiss': {
        let mutationResponse = await executeMutationDismissLead({
          lead_id: value.lead.id,
          status: 'dismissed',
        })

        if (mutationResponse.error) {
          notify(notifyError('Something went wrong. Please try again.'))
          return
        }

        setFlowTo(normalizePath(props.viewPath, 'Content/Detail/No'))
        notify(notifySuccess('Lead dimissed.'))
        return
      }
      case 'undismiss': {
        let mutationResponse = await executeMutationDismissLead({
          lead_id: value.lead.id,
          status: 'created', // TOOD: check what it should be reverted to
        })

        if (mutationResponse.error) {
          notify(notifyError('Something went wrong. Please try again.'))
          return
        }

        setFlowTo(normalizePath(props.viewPath, 'Content/Detail/No'))
        notify(notifySuccess('Lead undimissed.'))
        return
      }

      case 'deleteNote': {
        let note = value.lead.notes.find(note => note.id === args.note_id)
        if (!note.createNote) {
          // skip the mutation if it is not saved yet
          let mutationResponse = await executeMutationDeleteNote({
            note_id: args.note_id,
          })

          if (mutationResponse.error) {
            notify(notifyError('Something went wrong. Please try again.'))
            return
          }
        }

        change(next => {
          next.lead.notes = next.lead.notes.filter(
            note => note.id !== args.note_id
          )
        })

        notify(notifySuccess('Note deleted.'))
        return
      }
      default: {
        return
      }
    }
  }
}
