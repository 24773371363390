import { useDataFormat, useDataValue } from 'Simple/Data.js'
import { format } from 'date-fns-tz'

export default function useDataConfiguration(props) {
  let appointment_type_id = useDataValue({
    context: 'appointment_overlay',
    path: 'appointment_type_id',
    viewPath: props.viewPath,
  })
  let appointment_id = useDataValue({
    context: 'appointment_overlay',
    path: 'appointment_id',
    viewPath: props.viewPath,
  })
  let location_id = useDataValue({
    context: 'tab',
    path: 'selected.location_id',
    viewPath: props.viewPath,
  })
  let date = useDataFormat({
    context: 'tab',
    path: 'selected.date',
    format: value => format(value, 'yyyy-MM-dd'),
    viewPath: props.viewPath,
  })

  return {
    variables: {
      location_id,
      appointment_type_id,
      start_date: date,
      end_date: date,
      // to ignore when rescheduling
      appointment_id: appointment_id || null,
    },
    pause: !location_id || !appointment_type_id || !date,
    requestPolicy: 'network-only',
  }
}
