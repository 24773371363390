import { booleanToYesNo, formatDateDisplay } from 'Data/format'
import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return data
      .filter(
        question =>
          (question.category !== 'habits' && question.category !== 'general') ||
          question.type === 'boolean'
      )
      .map(question => {
        let answer = question.answers[0]
        let questionType = question?.type
        let response = answer?.value

        return {
          id: question.id,
          name: question.name,
          value: getValue(response, questionType, answer?.alert),
          alert: getAlertValue({
            answer_value: response,
            answer_alert: answer?.alert,
            alert_type: question?.alert_type,
            alert_conditions: question?.alert_conditions,
          }),
        }
      })
      .filter(alertDTO => alertDTO.alert === true)
  }, [data])
}

function getValue(response, questionType, answer_alert) {
  switch (questionType) {
    case 'boolean':
      return response ? 'Yes' : 'No'
    case 'number':
    case 'choice':
    case 'text':
      return response
    case 'date':
      return formatDateDisplay(response)
    case 'multichoice':
      return Array.isArray(response)
        ? response
            .filter(
              item =>
                answer_alert === true ||
                (Array.isArray(answer_alert) && answer_alert?.includes(item))
            )
            .join(', ')
        : response
    default:
      return JSON.stringify(response)
  }
}

function getAlertValue({
  answer_alert,
  answer_value,
  alert_type,
  alert_conditions,
}) {
  if (
    answer_alert === false ||
    answer_alert === null ||
    answer_alert === undefined ||
    alert_type === 'none'
  ) {
    return false
  } else {
    if (alert_type === 'any') {
      if (typeof answer_value === 'boolean') {
        return answer_value
      } else if (typeof answer_value === 'string') {
        return !!answer_value.trim().length
      } else if (Array.isArray(answer_value)) {
        return !!answer_value.length
      }
      return true
    }

    if (alert_type === 'values') {
      if (Array.isArray(alert_conditions)) {
        if (Array.isArray(answer_value)) {
          return alert_conditions.some(item => answer_value.includes(item))
        }
        return alert_conditions.includes(answer_value)
      }

      return alert_conditions === answer_value
    }

    return false
  }
}
