// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentPlans/New/Steps/Content/PaymentPlans/Content/Content/PaymentPlan/Content/Content/Insurances/Insurance/Subscription/Content/Content/Search/Content/InsurancePlan/Assign/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentPlans_New_Steps_Content_PaymentPlans_Content_Content_PaymentPlan_Content_Content_Insurances_Insurance_Subscription_Content_Content_Search_Content_InsurancePlan_Assign_Content(
  $insurance_subscription: json!
  $insured_patient_id: numeric!
  $ortho_insured: json!
  $tx_category_id: numeric!
) {
  insert_patient_insurance_subscription(
    args: {
      insurance_subscription: $insurance_subscription
      insured_patient_id: $insured_patient_id
      ortho_insured: $ortho_insured
      tx_category_id: $tx_category_id
    }
  ) {
    id
  }
}

`