// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/PracticeHub/People/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_PracticeHub_People($person_id: uuid!) {
  vaxiom_persons(
    where: {
      public_user: { account_id: { _is_null: false } }
      _or: [
        { _id: { _eq: $person_id } }
        {
          from_person_relationships: {
            permitted_to_see_info: { _eq: true }
            to_person_record: { _id: { _eq: $person_id } }
          }
        }
      ]
    }
    order_by: { search_name: asc }
  ) {
    id
    _id
    first_name
    greeting
    last_name
    profile_pic_url
    core_user_id
    from_person_relationships(
      where: {
        permitted_to_see_info: { _eq: true }
        to_person_record: { _id: { _eq: $person_id } }
      }
    ) {
      id
      type
    }
    public_user {
      id
      account {
        id
        active
        email
      }
    }
  }
}

`