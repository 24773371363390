// this is an autogenerated file from App/Account/PatientOverlay/Content/Content/Apps/CustomResource/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_PatientOverlay_Content_Content_Apps_CustomResource($patient_id: uuid!) {
  apps_display_latest_resource(
    type: ADMIN_PATIENT_HEADER_STATUS
    patient_id: $patient_id
  ) {
    id
    created_at
    updated_at
    name
    statusType
    statusText
    note
    link
  }
}

`