// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentCard/Summary/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
subscription app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentCard_Summary_Content($treatment_id: uuid!) {
  vaxiom_txs(where: { _id: { _eq: $treatment_id } }) {
    id
    _id
    status
    estimated_start_date
    estimated_end_date
    start_date
    end_date
    is_financials_managed_externally
  }
}

`