import { useDataSubmit } from 'Simple/Data.js'

export function useOnClickComplete(props) {
  let submit = useDataSubmit({
    context: 'event',
    viewPath: props.viewPath,
  })

  return function onClickComplete() {
    submit({ type: 'close' })
  }
}

export function useOnChange(props) {
  return function onChange(value) {
    if (typeof props.update === 'function') props.update()
  }
}
