import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { useDataValue } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import { useUppy } from 'design-system/FileUploader/react.js'
import mutation from './mutation.graphql.js'

export function useOnClose(props) {
  let patient_id = useDataValue({
    context: 'patient',
    path: 'id',
    viewPath: props.viewPath,
  })
  let location_id = useDataValue({
    context: 'global',
    path: 'current_location.id',
    viewPath: props.viewPath,
  })

  let uppy = useUppy(props)
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  return async function onClose() {
    let attachments = uppy.getFiles()

    if (attachments.some(attachment => !attachment.progress.uploadComplete)) {
      return notify(
        notifyError(
          'Please upload the pending documents or remove them from the list before closing the modal.'
        )
      )
    }

    let objects = attachments.map(attachment => ({
      patient_id,
      // TODO: can organization_node_id be deleted?
      organization_node_id: location_id,
      original_name: attachment.name,
      mime_type: attachment.type,
      file_key: attachment.meta.location.substring(
        attachment.meta.location.lastIndexOf('/') + 1
      ),
    }))

    let mutationCreateDocumentResponse = await executeMutation({ objects })

    if (mutationCreateDocumentResponse.error) {
      console.error(mutationCreateDocumentResponse.error)
      return notify(
        notifyError(
          'There was a problem storing the temporary images for selected patient documents. Please try again.'
        )
      )
    }

    setFlowTo([
      normalizePath(props.viewPath, '../../No'),
      normalizePath(props.viewPath, '../../../UploadedImages/Content'),
    ])

    // reset Uppy after successfully uploading files
    uppy.cancelAll()
  }
}
