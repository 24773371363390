import { useDataValue } from 'Simple/Data.js'
import useStableValue from 'Logic/useStableValue.js'

export default function useDataConfiguration(props) {
  let organization_id = useDataValue({
    context: 'patient',
    path: 'person.organization_id',
    viewPath: props.viewPath,
  })

  let search = useDataValue({
    context: 'selected',
    path: 'billing_center_search',
    viewPath: props.viewPath,
  })

  let selected_billing_center = useDataValue({
    context: 'plan_details',
    path: 'billing_center',
    viewPath: props.viewPath,
  })

  let search_term = useStableValue(`%${search}%`)

  return {
    variables: {
      organization_id,
      search_term,
      // setting a dummy number to prevent GraphQL variables error
      selected_billing_center: selected_billing_center ?? 0,
      search_selected_billing_center: !!selected_billing_center,
    },
  }
}
