// this is an autogenerated file from App/Account/Content/Calendar/New/AppointmentOverlay/Content/PatientSelect/Content/Content/Existing/PatientSelect/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Calendar_New_AppointmentOverlay_Content_PatientSelect_Content_Content_Existing_PatientSelect_Content($search_term: String!, $parent_company_id: uuid!) {
  vaxiom_persons_search(
    search_term: $search_term
    parent_company_id: $parent_company_id
    whole_parent_company: false
  ) {
    vaxiom_person {
      id
      _id
      first_name
      last_name
      gender
      birth_date
      patient {
        id
        _id
        human_readable_id
        primary_location {
          id
          name
        }
      }
    }
  }
}

`