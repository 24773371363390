let STATUS_TO_VIEW_MAP = {
  processed: 'SyncPatients',
  error: 'Error',
  matching: 'Matching',
  processing: 'Syncing',
  completed: 'Completed',
}

export function getView(value) {
  return STATUS_TO_VIEW_MAP[value] || 'Syncing'
}
