import { useDataSubmit } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let submit = useDataSubmit({
    context: 'app',
    viewPath: props.viewPath,
  })

  return async function onChange(value) {
    if (value === null) {
      submit({ type: 'cancel-oauth2-connection' })
    } else if (value.status === 'active') {
      submit({ type: 'oauth2-connected' })
    }
  }
}
