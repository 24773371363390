import { useMutation } from 'Data/Api.js'
import {
  useNotifications,
  notifyError,
  notifySuccess,
} from 'Logic/Notifications.js'
import { useDataValue } from 'Simple/Data.js'
import mutation_send_medical_form from './sendMedicalFormMutation.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let [, notify] = useNotifications()
  let patient = useDataValue({
    viewPath: props.viewPath,
    context: 'profile_patient',
  })

  let [, executeSendMedicalFormMutation] = useMutation(
    mutation_send_medical_form
  )

  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'MEDICAL_FORM': {
        let mutationSendMedicalFormResponse =
          await executeSendMedicalFormMutation({
            patient_id: patient._id,
            contact_method_association_id: args.email.id,
          })

        if (mutationSendMedicalFormResponse.error) {
          notify(notifyError('Something went wrong. Please, try again.'))
          return
        }

        notify(notifySuccess('Medical Form Sent!'))
        break
      }
      default: {
      }
    }
  }
}
