import { useDataValue } from 'Simple/Data.js'
import { UNSCHEDULED_BOOKING_STATES } from 'Data/common-constants'

let APPOINTMENT_BOOL_EXP = {
  past: {
    _and: [
      { booking: { end_time: { _lte: 'now() ' } } },
      { booking: { state: { _nin: UNSCHEDULED_BOOKING_STATES } } },
    ],
  },
  upcoming: {
    _or: [
      { booking: { end_time: { _gt: 'now()' } } },
      { booking: { state: { _in: UNSCHEDULED_BOOKING_STATES } } },
      // i have tried different variants, only this one worked computed field for some reason
      { _not: { booking: { _id: { _is_null: false } } } },
    ],
  },
}

let CREATED_AT_ORDER_BY = {
  past: 'desc',
  upcoming: 'asc',
}

export default function useDataConfiguration(props) {
  let tx_uuid = useDataValue({
    context: 'appointments',
    path: 'tx_uuid',
    viewPath: props.viewPath,
  })
  let tab = useDataValue({
    context: 'appointments',
    path: 'tab',
    viewPath: props.viewPath,
  })

  return {
    requestPolicy: 'network-only',
    variables: {
      appointment_bool_exp: APPOINTMENT_BOOL_EXP[tab],
      tx_uuid,
      created_at_order_by: CREATED_AT_ORDER_BY[tab],
    },
    pause: !tx_uuid,
  }
}
