// this is an autogenerated file from DesignSystem/TimelineEvent/CustomResource/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_TimelineEvent_CustomResource($id: uuid!) {
  apps_display_resource(
    type: ADMIN_PATIENT_TREATMENT_TIMELINE
    resource_id: $id
  ) {
    id
    created_at
    updated_at
    name
    statusType
    statusText
    note
    link
  }
}

`