// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentPlans/New/Steps/Content/PaymentPlans/Content/Content/PaymentPlan/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentPlans_New_Steps_Content_PaymentPlans_Content_Content_PaymentPlan($treatment_plan_id: numeric!) {
  treatments_payment_plans(
    where: { treatment_plan_id: { _eq: $treatment_plan_id } }
  ) {
    id
    treatment_fee
    insurance_code_id
    treatment_plan_id
    request_id
    has_signed_contracts
    discounts {
      id
      type_id: discount_id
      is_applied_to_payor
      is_included_in_insurance_claim
      payor_person_id
      transaction {
        id
        amount
      }
      type: discount {
        id
        name
      }
    }
    charges {
      id
      name
      is_included_in_insurance_claim
      type_id
      transaction {
        id
        amount
      }
    }
    payors {
      id
      is_primary
      person_id
      share_amount
      payment_plan {
        id
        first_due_date
        first_installment_date
        second_installment_date
        downpayment_amount
        installment_amount
        installment_interval
        payment_plan_length
        downpayment_locked
        installment_locked
        plan_length_locked
        transaction {
          id
          amount
        }
      }
    }
    insurances {
      id
      is_primary
      estimated_reimbursement_amount
      downpayment_amount
      installment_amount
      insured_id
      insured {
        id
        _id
        patient {
          id
          _id
          person {
            id
            _id
            birth_date
          }
        }
        tx_category_insured {
          id
          _id
          ortho_insured {
            id
            _id
            used_lifetime_coverage
            year_deductible_paid_last
          }
        }
        insurance_subscription {
          id
          _id
          person {
            id
            _id
            first_name
            last_name
          }
          enrollment_date
          insurance_plan {
            id
            _id
            number
            insurance_company {
              id
              carrier_name
              installment_interval
            }
            insurance_billing_center {
              id
              name
            }
            tx_category_coverages {
              id
              ortho_coverages {
                id
                deductible_amount
                reimbursement_percentage
                max_lifetime_coverage
                cob_type
                downpayment_type
                downpayment_amount
                downpayment_percentage
                downpayment_percentage_base
                waiting_period
                dependent_age_limit
                age_limit_cutoff
              }
            }
          }
        }
      }
    }
  }
}

`