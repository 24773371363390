// this is an autogenerated file from App/Account/Content/Calendar/New/Content/mutation-upsert-location-settings.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Calendar_New_Content(
  $object: calendar_location_settings_insert_input!
  $columns_to_update: [calendar_location_settings_update_column!]!
) {
  insert_calendar_location_settings_one(
    object: $object
    on_conflict: {
      constraint: location_settings_location_id_key
      update_columns: $columns_to_update
    }
  ) {
    id
    location_id
    blue_bar_range
  }
}

`