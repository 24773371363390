// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/IntegrationsApplications/Content/Apps/mutation-install.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_IntegrationsApplications_Content_Apps($app_id: uuid!) {
  apps_install(app_id: $app_id) {
    app {
      id
      companies {
        id
        status
      }
    }
  }
}

`