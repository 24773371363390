// this is an autogenerated file from App/Account/PatientOverlay/Content/Content/Appointments/TabContent/Empty/mutation-create-tx.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_PatientOverlay_Content_Content_Appointments_TabContent_Empty($patient_id: uuid!, $location_id: uuid!) {
  vaxiom_treatment_card_create(
    patient_id: $patient_id
    location_id: $location_id
  ) {
    id
  }
}

`