export function getView(connections) {
  let statuses = new Set(connections.map(item => item.status))

  if (statuses.has('active')) {
    return 'Connected'
  } else if (
    ['error', 'error_refresh', 'expired'].some(item => statuses.has(item))
  ) {
    return 'Error'
  } else if (statuses.has('inactive')) {
    return 'Disconnected'
  } else {
    return 'No'
  }
}
