import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    return data
      ?.filter(item => item.phone)
      .map(item => ({
        ...item,
        text: `${item.phone.number} - ${item.description}`,
      }))
  }, [data])
}
