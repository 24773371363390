import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let setFlowTo = useSetFlowTo(props.viewPath)

  // TODO: copy the approach used in comms and apply here and in
  // other tabs that don't follow the pattern
  // see: apps/admin/src/App/Account/Content/Comms/data.json
  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'tab/openCommsThread': {
        change(next => {
          let comms = next.selected.comms

          comms.thread_id = args.thread_id
          comms.is_secure =
            typeof args.is_secure === 'boolean' ? args.is_secure : true
          comms.auto_uploaded_attachments = args.auto_uploaded_attachments || []
          comms.message = args.message || ''
          comms.tags = args.tags || []
          comms.is_blocking = !!args.is_blocking

          if (args.thread_id in comms.message_composes) {
            let messageCompose = comms.message_composes[args.thread_id]
            messageCompose.is_secure = comms.is_secure
            messageCompose.message = comms.message

            if (Array.isArray(messageCompose.auto_uploaded_attachments)) {
              messageCompose.auto_uploaded_attachments = [
                ...messageCompose.auto_uploaded_attachments,
                ...comms.auto_uploaded_attachments,
              ]
            } else {
              messageCompose.auto_uploaded_attachments =
                comms.auto_uploaded_attachments
            }

            if (Array.isArray(messageCompose.tags)) {
              messageCompose.tags = [...messageCompose.tags, ...comms.tags]
            } else {
              messageCompose.tags = comms.tags
            }
          }
        })
        setFlowTo(
          normalizePath(props.viewPath, 'Content/Content/Comms/Content')
        )
        break
      }

      default: {
        throw new Error(`Unknown action type ${args.type}`)
      }
    }
  }
}
