import { useDataValue, useDataChange } from 'Simple/Data.js'
import query from './queryDiscussion.graphql.js'
import { useClient, useMutation } from 'Data/Api.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { patientName } from 'Data/format.js'
import mutation from './mutationDiscussion.graphql.js'

export default function useDataOnActionDiscussion(props) {
  let client = useClient()
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  let change = useDataChange({
    context: 'comms',
    path: 'thread_id',
    viewPath: props.viewPath,
  })

  let patient_id = useDataValue({
    viewPath: props.viewPath,
    context: 'comms',
    path: 'patient_id',
  })
  let person = useDataValue({
    viewPath: props.viewPath,
    context: 'comms',
    path: 'person',
  })

  let parent_company_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location.parent_company._id',
  })

  return async function onActionDiscussion() {
    let { data, error } = await client
      .query(
        query,
        {
          patient_id,
        },
        {
          requestPolicy: 'network-only',
        }
      )
      .toPromise()

    if (error) {
      return notify(notifyError(`There was a problem opening this record.`))
    }

    let thread = null
    if (data.comms_threads.length > 0) {
      thread = data.comms_threads[0]
    } else {
      let mutationResponse = await executeMutation({
        resource: {
          schema: 'vaxiom',
          table: 'patients',
          id: patient_id,
        },
        thread_name: patientName(person).concat(' - Patient'),
        parent_company_id,
      })

      if (mutationResponse.error) {
        return notify(
          notifyError(`There was a problem starting this discussion.`)
        )
      }

      thread = mutationResponse.data.thread
    }

    change(thread.id)

    if (typeof props.onSuccess === 'function') props.onSuccess()
  }
}
