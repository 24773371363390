import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    let cmas = [
      ...data.vaxiom_contact_method_associations
        .filter(item => item.contact_method.mobile_phone?.number)
        .map(item => ({
          id: item.contact_method.id,
          type: 'Phone',
          number: item.contact_method.mobile_phone?.number,
          description: item.description,
        })),
      ...data.vaxiom_relationships.reduce(
        (result, current) => [
          ...result,
          ...current.from_person_record.contact_method_associations
            .filter(item => item.contact_method.mobile_phone?.number)
            .map(item => ({
              id: item.contact_method.id,
              type: 'Phone',
              number: item.contact_method.mobile_phone.number,
              description: current.type.toLowerCase(),
            })),
        ],
        []
      ),
    ]

    // Create a Map to store unique contact methods with contact_method.id as the key
    let uniqueMap = new Map()

    // Iterate through each contact method in the cmas array
    cmas.forEach(item => {
      // Get the length of the description, 0 if null or undefined
      let currentLength = (item.description || '').length

      // Check if either:
      // 1. This ID doesn't exist in our Map yet, OR
      // 2. The new contact_method has a longer description than the existing one
      if (
        !uniqueMap.has(item.id) ||
        (uniqueMap.get(item.id).description || '').length < currentLength
      ) {
        // Add or update the item in our Map
        uniqueMap.set(item.id, item)
      }
    })

    return [
      { type: 'InApp', id: 'InApp' },
      ...Array.from(uniqueMap.values()),
      { type: 'Discussion', id: 'Discussion' },
    ]
  }, [data])
}
