import View from './view.js'
import { useMemo } from 'react'

export default function Logic(props) {
  /** @type {{has_insurance_overdues: boolean, has_personal_overdues: boolean}} */
  let overdueFlags = useMemo(
    () => ({
      has_insurance_overdues:
        props.overdues?.some(
          overdue =>
            overdue.payment_account.account_type === 'InsurancePaymentAccount'
        ) ?? false,
      has_personal_overdues:
        props.overdues?.some(
          overdue =>
            overdue.payment_account.account_type === 'PersonPaymentAccount'
        ) ?? false,
    }),
    [props.overdues]
  )

  return <View {...props} {...overdueFlags} />
}
