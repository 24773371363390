import { useDataChange, useDataValue } from 'Simple/Data.js'
import { useMutation } from 'Data/Api'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import mutation from './mutation.graphql.js'
import debounce from 'lodash/debounce.js'

export default function useListItemDataOnChange(props) {
  let change = useDataChange({
    context: 'selected',
    viewPath: props.viewPath,
  })

  let lead = useDataValue({
    context: 'selected',
    viewPath: props.viewPath,
    path: 'lead',
  })

  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()

  return debounce(onChange, 500)

  async function onChange(next) {
    let note = lead.notes.find(note => note.id === next.id)
    if (note.notes == next.notes || next.notes === '') {
      return
    }
    let mutationResponse = await executeMutation({
      lead_id: lead.id,
      note_id: next.id,
      create_note: next.createNote || false,
      update_note: !next.createNote,
      notes: next.notes,
    })

    if (mutationResponse.error) {
      notify(notifyError('Something went wrong. Please try again.'))
      return
    }

    change(inext => {
      inext.lead.notes[props.index] = {
        id: next.createNote
          ? mutationResponse.data.insert_leads_notes_one.id // replace the temp id with newly created one
          : next.id,
        notes: next.notes,
      }
    })
  }
}
