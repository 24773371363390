import React, { useMemo } from 'react'
import { DataProvider, useDataValue } from 'Simple/Data.js'

import View from './view.js'

export default function Logic(props) {
  let apps = useDataValue({
    context: 'apps',
    viewPath: props.viewPath,
  })
  let app_id = useDataValue({
    context: 'tab',
    path: 'selected.app_id',
    viewPath: props.viewPath,
  })
  let value = useMemo(() => {
    if (!apps) return {}
    let app = apps.find(item => item.id === app_id)
    if (!app) return {}

    return {
      ...app.connection,
      title: app.name,
      tags: app.tags,
      app_id: app.id,
      xid: app.xid,
      sync_available: !!app.sync,
    }
  }, [app_id, apps])

  // TODO: do we need connection_definition?
  return (
    <DataProvider
      context="connection_definition"
      value={value}
      viewPath={props.viewPath}
    >
      <View {...props} />
    </DataProvider>
  )
}
