import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let connection_app_tags = useDataValue({
    context: 'connection_definition',
    path: 'tags',
    viewPath: props.viewPath,
  })
  let stepsData = useDataValue({
    context: '_steps',
    viewPath: props.viewPath,
  })
  let automation_id = useDataValue({
    context: 'connection',
    path: 'automation_id',
    viewPath: props.viewPath,
  })
  return useMemo(() => {
    let automations = stepsData.apps_automations.automations
      // only keep the missing automations
      .filter(automation => !automation.is_created)
      .map(automation => ({
        ...automation,
        step_type: 'automation',
        status: 'pending',
      }))

    if (automation_id) {
      return {
        steps: automations
          .filter(automation => automation.automation_id === automation_id)
          .map((step, index) => ({ ...step, index })),
        current_index: 0,
      }
    }

    let properties =
      stepsData.apps_connections_by_pk.app.properties
        ?.filter(property => {
          if (property.required) {
            return !Object.keys(
              stepsData.apps_connections_by_pk.resource_properties[0]
                ?.properties ?? {}
            ).includes(property.key)
          }
          return false
        })
        ?.map(property => ({
          preference_id:
            stepsData.apps_connections_by_pk.resource_properties[0].id,
          key: property.key,
          name: property.name,
          required: property.required,
          step_type: 'preferences',
          status: 'pending',
          value: null,
          ...stepsData.apps_connections_by_pk.resource_properties[0],
          id: `${stepsData.apps_connections_by_pk.resource_properties[0].id}-${property.key}`, // unique key
        })) ?? []

    let steps = [...properties, ...automations]

    return {
      steps: steps.map((step, index) => ({ ...step, index })),
      current_index: 0,
    }
  }, [connection_app_tags])
}
