// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentCard/Events/Content/Content/Events/Content/Compact/Past/Content/Content/CustomResourceRow/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentCard_Events_Content_Content_Events_Content_Compact_Past_Content_Content_CustomResourceRow($id: uuid!) {
  apps_display_resource(
    type: ADMIN_PATIENT_TREATMENT_TIMELINE
    resource_id: $id
  ) {
    id
    created_at
    updated_at
    name
    statusType
    statusText
    note
    link
  }
}

`