import { birthday, name } from 'Data/validate.js'
import { useMutation } from 'Data/Api.js'
import { minutesToThreeDigitsTime } from 'Data/format.js'
import { useDataSubmit, useDataValue } from 'Simple/Data'
import { isNil, omitBy } from 'lodash'
import { toDate } from 'date-fns-tz'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import mutationScheduleNewPatient from './mutation-schedule-new-patient.graphql.js'
import mutationScheduleExistingPatientAndNewAppointment from './mutation-schedule-existing-patient-and-new-appointment.graphql.js'
import mutationScheduleExistingPatientAndExistingAppointment from './mutation-schedule-existing-patient-and-existing-appointment.graphql.js'
import mutationCreateTreatment from './mutation-create-treatment.graphql.js'
import { DEFAULT_TIMEZONE } from 'Data/constants.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let onActionSchedule = useDataOnActionSchedule(props, data)
  let onActionUpdatePatientOption = useDataOnActionUpdatePatientOption(
    props,
    data
  )
  let onActionUnsetPatientOption = useDataOnActionUnsetPatient(props, data)
  let onActionSetNewAppointment = useDataOnActionSetNewAppointment(props, data)
  let onActionSetExistingAppointment = useDataOnActionSetExistingAppointment(
    props,
    data
  )
  let onActionSetAppointmentTemplate = useDataOnActionSetAppointmentTemplate(
    props,
    data
  )
  let onActionCreateTreatment = useDataOnActionCreateTreatment(props, data)

  return async function onSubmit(params) {
    let { args } = params

    switch (args?.type) {
      case 'schedule': {
        return onActionSchedule(params)
      }
      case 'updatePatientOption': {
        return onActionUpdatePatientOption(params)
      }
      case 'unsetPatient': {
        return onActionUnsetPatientOption(params)
      }
      case 'setNewAppointment': {
        return onActionSetNewAppointment(params)
      }
      case 'setExistingAppointment': {
        return onActionSetExistingAppointment(params)
      }
      case 'setAppointmentTemplate': {
        return onActionSetAppointmentTemplate(params)
      }
      case 'createTreatment': {
        return onActionCreateTreatment(params)
      }
      default: {
        console.error(`Unsupported action ${args?.type}`)
      }
    }
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useDataOnActionSetNewAppointment(props) {
  let submitTab = useDataSubmit({
    viewPath: props.viewPath,
    context: 'tab',
  })

  return async function onAction({ args, change }) {
    change(next => {
      next.appointment_id = null
      next.is_external_appointment_id = false
      next.appointment_type_id = null
      next.template_id = null
      next.duration = null
    })

    let optimal_date = args.approximateDate ?? null
    let date = optimal_date
    submitTab({
      type: 'scheduling/reset',
      optimal_date,
      ...(date && { date }),
    })
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useDataOnActionSetExistingAppointment(props) {
  let submitTab = useDataSubmit({
    viewPath: props.viewPath,
    context: 'tab',
  })
  let timeZoneId = useDataValue({
    context: 'tab',
    path: 'selected.time_zone_id',
    viewPath: props.viewPath,
  })

  return async function onAction({ value, args, originalValue, change }) {
    let booking = args.preselect_scheduling_slot_config
    let optimal_date = args.approximateDate ?? null
    let location = args.location
      ? {
          location_id: args.location.id,
          vaxiom_location_id: args.location.vaxiom_id,
          time_zone_id: args.location.time_zone_id || DEFAULT_TIMEZONE,
          location_name: args.location.name,
        }
      : null
    let date = booking
      ? toDate(booking.date, { timeZone: location?.time_zone_id || timeZoneId })
      : optimal_date

    // We show the blue bar if the appointment is an unscheduled one
    await submitTab({
      type: 'scheduling/reset',
      optimal_date,
      ...(date && { date }),
      ...(location && { location }),
    })

    change({
      ...originalValue,
      patient_id: args.patient_id || value.patient_id,
      patient_option: 'existing',
      appointment_id: args.id,
      appointment_type_id: args.type_id,
      appointment_type_name: args.type_name,
      treatment_id: args.treatment_id,
      duration: args.duration,
      notes: args.notes,
      ...(args.is_external
        ? {
            is_external_any: true,
            is_external_patient_id: true,
            is_external_appointment_id: true,
            is_external_treatment_id: true,
          }
        : {}),
      ...(booking
        ? {
            preselect_scheduling_slot_config: booking,
          }
        : {}),
    })
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useDataOnActionSetAppointmentTemplate(props) {
  let submitTab = useDataSubmit({
    viewPath: props.viewPath,
    context: 'tab',
  })

  return async function onAction({ value, args, originalValue, change }) {
    change(next => {
      if (args.template_exists_in_location) {
        next.appointment_type_id = args.appointment_type_id
        next.appointment_type_name = args.appointment_type_name
        next.template_id = args.template_id
        if (!value.appointment_id || !value.duration)
          next.duration = args.duration
        next.template_exists_in_location = true
      } else {
        next.template_exists_in_location = false
      }
    })
    if (args.reset) {
      submitTab({ type: 'scheduling/reset' })
    }
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useDataOnActionUnsetPatient(props) {
  let submitTab = useDataSubmit({
    viewPath: props.viewPath,
    context: 'tab',
  })

  return async function onAction({ originalValue, change }) {
    change({
      ...originalValue,
      patient_option: 'existing',
    })
    submitTab({
      type: 'scheduling/reset',
      optimal_date: null,
    })
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useDataOnActionUpdatePatientOption(props) {
  let submitTab = useDataSubmit({
    viewPath: props.viewPath,
    context: 'tab',
  })

  return async function onAction({ args, originalValue, change }) {
    change({
      ...originalValue,
      patient_option: args.patient_option,
    })
    // We would like to reset the blue bar configuration here and not show them
    // So that when another appointment is selected eventually, the decision
    // could be made there, if we should blue bars or not.
    submitTab({
      type: 'scheduling/reset',
      optimal_date: null,
    })
  }
}

/**
 * @param {*} input
 * @returns {string | null}
 */
function validateNewPatient(input) {
  let { first_name, last_name, gender, birth_date } = input.person

  if (!name(first_name)) return 'Invalid first name'
  if (!name(last_name)) return 'Invalid last name'
  if (!gender) return 'Invalid gender'
  if (!birthday(birth_date)) return 'Invalid birth date'

  return null
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useDataOnActionSchedule(props) {
  let [, executeMutationScheduleNewPatient] = useMutation(
    mutationScheduleNewPatient
  )
  let [, executeMutationScheduleExistingPatientAndNewAppointment] = useMutation(
    mutationScheduleExistingPatientAndNewAppointment
  )
  let [, executeMutationScheduleExistingPatientAndExistingAppointment] =
    useMutation(mutationScheduleExistingPatientAndExistingAppointment)
  let [, notify] = useNotifications()
  let location_id = useDataValue({
    context: 'tab',
    path: 'selected.location_id',
    viewPath: props.viewPath,
  })
  let slots = useDataValue({
    context: 'tab',
    path: 'scheduling.slots',
    viewPath: props.viewPath,
  })
  let slot_id = useDataValue({
    context: 'tab',
    path: 'scheduling.slot_id',
    viewPath: props.viewPath,
  })
  let untemplated_slot = useDataValue({
    context: 'tab',
    path: 'scheduling.untemplated_slot',
    viewPath: props.viewPath,
  })
  let submitOverlay = useDataSubmit({
    viewPath: props.viewPath,
    context: 'overlay',
  })
  let submitTab = useDataSubmit({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return async function onAction({ value }) {
    let slot = slots.find(v => v.id === slot_id)
    let date = slot?.date || untemplated_slot?.date
    let start_time =
      slot?.start_time ||
      (typeof untemplated_slot?.start_min === 'number'
        ? minutesToThreeDigitsTime(untemplated_slot.start_min)
        : null)
    let end_time =
      slot?.end_time ||
      (typeof untemplated_slot?.end_min === 'number'
        ? minutesToThreeDigitsTime(untemplated_slot.end_min)
        : null)
    let chair_id = slot?.chair_id || untemplated_slot?.chair_id

    if (!date) {
      notify(notifyError('Date not selected'))
      return true
    }

    if (!start_time) {
      notify(notifyError('Start time not selected'))
      return true
    }

    if (!end_time) {
      notify(notifyError('End time not selected'))
      return true
    }

    if (!chair_id) {
      notify(notifyError('Chair not selected'))
      return true
    }

    let {
      emergency,
      patient_id,
      appointment_id,
      treatment_id,
      appointment_type_id,
      note,
      add_notes,
    } = value

    let shared = {
      location_id,
      ...(add_notes ? { note } : {}),
      date,
      chair_id,
      start_time,
      end_time,
    }

    let mutationResponse

    if (patient_id) {
      if (appointment_id) {
        mutationResponse =
          await executeMutationScheduleExistingPatientAndExistingAppointment({
            ...shared,
            patient_id,
            appointment_id,
          })
      } else {
        mutationResponse =
          await executeMutationScheduleExistingPatientAndNewAppointment({
            ...shared,
            patient_id,
            appointment_type_id,
            treatment_id,
            unplanned: emergency,
          })
      }
    } else {
      let validationResult = validateNewPatient(value.patient_new)

      if (validationResult) {
        notify(notifyError(validationResult))
        return true
      }

      let patient_form = omitBy(
        {
          ...value.patient_new.person,
          // prettier-ignore
          ...(typeof value.patient_new?.provider_employee_resource_id === 'number'
            ? {
                provider_employee_resource_id:
                  value.patient_new.provider_employee_resource_id,
              }
            : {}),
        },
        isNil
      )

      mutationResponse = await executeMutationScheduleNewPatient({
        ...shared,
        unplanned: false,
        appointment_type_id,
        patient_form,
      })
    }

    if (mutationResponse.error) {
      notify(notifyError('Something went wrong'))
    } else {
      let action =
        !value.initial_location || value.initial_location?.id === location_id
          ? null
          : {
              text: `Switch back to last location`,
              onClick: () => {
                submitTab({
                  type: 'setLocation',
                  location_id: value.initial_location.id,
                  vaxiom_location_id: value.initial_location.vaxiom_id,
                  time_zone_id:
                    value.initial_location.time_zone_id || DEFAULT_TIMEZONE,
                  location_name: value.initial_location.name,
                })
              },
            }
      notify(
        notifySuccess(
          'Appointment for this patient is scheduled and added to calendar.',
          'Appointment scheduled!',
          action
        )
      )
      submitOverlay({})
    }
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useDataOnActionCreateTreatment(props) {
  let [, executeMutation] = useMutation(mutationCreateTreatment)
  let [, notify] = useNotifications()
  let location_id = useDataValue({
    context: 'tab',
    path: 'selected.location_id',
    viewPath: props.viewPath,
  })

  return async function onAction({ value, change, originalValue }) {
    let { patient_id } = value

    let mutationResponse = await executeMutation({
      patient_id,
      location_id,
    })
    if (mutationResponse.error) {
      notify(notifyError('Something went wrong'))
    } else {
      notify(notifySuccess('Treatment created'))
      change({
        ...originalValue,
        patient_option: 'existing',
        patient_id,
        treatment_id: mutationResponse.data.treatments_create_treatment._id,
      })
    }
  }
}
