import { useDataSubmit, useDataChange } from 'Simple/Data'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'connection',
    viewPath: props.viewPath,
  })

  let submit = useDataSubmit({
    context: 'steps',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(next => {
      next.automation_id = null
    })
    submit({
      type: 'changeStatus',
      value: 'skipped',
    })
  }
}
