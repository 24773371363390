// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/Insurance/AssignedInsurance/Content/AssignedInsuranceItem/InsurancePlan/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_Insurance_AssignedInsurance_Content_AssignedInsuranceItem_InsurancePlan_Content(
  $id: numeric!
  $name: String
  $number: String!
  $insurance_company_id: numeric!
  $insurance_billing_center_id: numeric!
  $description: String
  $is_deleted: Boolean!
  $editcoverage: Boolean!
  $cid: numeric!
  $ortho_coverage: json
  $employers: [vaxiom_insurance_plan_employers_insert_input!]!
  $last_verified: date
) {
  update_vaxiom_patient_insurance_plans_by_pk(
    pk_columns: { id: $id }
    _set: {
      name: $name
      number: $number
      insurance_company_id: $insurance_company_id
      insurance_billing_center_id: $insurance_billing_center_id
      description: $description
      last_verified: $last_verified
    }
  ) {
    id
  }

  update_ortho_coverage(
    args: {
      _insurance_plan_id: $id
      coverage_id: $cid
      ortho_coverage: $ortho_coverage
    }
  ) @include(if: $editcoverage) {
    id
  }

  delete_vaxiom_insurance_plan_employers(
    where: { insurance_plan_id: { _eq: $id } }
  ) {
    affected_rows
  }

  insert_vaxiom_insurance_plan_employers(objects: $employers) {
    affected_rows
  }

  delete_vaxiom_ortho_coverages_by_pk(id: $cid) @include(if: $is_deleted) {
    id
  }
  delete_vaxiom_tx_category_coverages_by_pk(id: $cid)
    @include(if: $is_deleted) {
    id
  }
}

`