import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let primary_location = useDataValue({
    context: 'patient',
    viewPath: props.viewPath,
    path: 'primary_location',
  })

  return useMemo(() => {
    if (!data) return data

    if (data.length === 0) return [primary_location]

    let organizations = data
      .flatMap(tx_card => tx_card.txs)
      .map(tx => tx.organization)
      .filter(Boolean)

    let locations = Array.from(
      new Set(
        data
          .flatMap(tx_card => tx_card.txs)
          .map(tx => tx.organization)
          .filter(Boolean)
          .map(organization => organization.id)
      )
    ).map(id => organizations.find(org => org.id === id))

    return locations
  }, [data, primary_location])
}
