// this is an autogenerated file from App/Account/PatientOverlay/Content/Content/Appointments/TabContent/Content/AppointmentsList/Content/Appointment/BottomAction/Content/mutation-check-out.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_PatientOverlay_Content_Content_Appointments_TabContent_Content_AppointmentsList_Content_Appointment_BottomAction_Content($id: uuid!) {
  vaxiom_appointment_booking_check_out(id: $id) {
    id
  }
}

`