// this is an autogenerated file from App/Account/Content/Marketing/ReportTable/Content/Content/mutation-dismiss.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Marketing_ReportTable_Content_Content($lead_id: uuid!, $status: leads_lead_statuses_enum) {
  update_leads_leads_by_pk(
    pk_columns: { id: $lead_id }
    _set: { status: $status }
  ) {
    id
  }
}

`