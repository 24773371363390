// this is an autogenerated file from App/Account/UserNotifications/data.graphql
import { gql } from 'Data/Api'

export default gql`
subscription app__App_Account_UserNotifications{
  notifications_users(
    where: { _or: [{ status: { _eq: UNREAD } }] }

    order_by: { updated_at: desc }
  ) {
    id
    notification_id
    status
  }
}

`