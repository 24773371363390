import { useMutation } from 'Data/Api.js'
import { useDataValue } from 'Simple/Data.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'

import mutation from './mutation.graphql.js'

export default function useListItemDataOnSubmit(props) {
  let image_series_id = useDataValue({
    viewPath: props.viewPath,
    context: 'tab',
    path: 'selected.images.image_series_uuid',
  })
  let location_id = useDataValue({
    context: 'global',
    path: 'current_location._id',
    viewPath: props.viewPath,
  })

  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()

  return async function onSubmit({ args, value, change }) {
    let mutationResponse = await executeMutation({
      id: value.id,
      resource_id: image_series_id,
      location_id,
    })
    if (mutationResponse.error) {
      return notify(notifyError(`There was a problem launching the app.`))
    }

    if (!mutationResponse.data.apps_launchers_resolve.ok) {
      return notify(
        notifyError(mutationResponse.data.apps_launchers_resolve.message)
      )
    }

    if (mutationResponse.data.apps_launchers_resolve.message) {
      return notify(
        notifySuccess(mutationResponse.data.apps_launchers_resolve.message)
      )
    }
  }
}
