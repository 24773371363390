import { useMutation } from 'Data/Api.js'
import mutation from './mutation.graphql.js'
import { useDataValue } from 'Simple/Data.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let [, executeMutation] = useMutation(mutation)
  let patient_person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'patient',
    path: 'person._id',
  })
  let location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location._id',
  })
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onSubmit({ value, originalValue, args, change }) {
    let mutationResponse = await executeMutation({
      patient_person_id,
      invited_person_id: value.person_id,
      email: value.email,
      permitted_to_see_info: value.permitted_to_see_info,
      send_patient_reminders_to_email: value.send_patient_reminders_to_email,
      location_id,
    })

    if (
      mutationResponse.error ||
      !mutationResponse.data.auth_accounts_practice_hub_invite_patient.ok
    ) {
      notify(notifyError('Failed to send invite.'))
    } else {
      notify(notifySuccess('Invite sent!'))
    }

    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}
