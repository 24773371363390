import { useMemo } from 'react'
import addDays from 'date-fns/addDays'
import isAfter from 'date-fns/isAfter'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import format from 'date-fns/format'
import { isUnscheduledAppointment } from 'Data/format.js'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    let result = data.map(event =>
      event.type === 'appointment'
        ? {
            ...event,
            appointment: {
              ...event.appointment,
              tooth_chart_snapshot: event.appointment.toothchart_snapshot?.[0],
              diagnosis: event.appointment.diagnosis?.[0],
              has_images: event.appointment.patient_imageseries?.length > 0,
              booking: event.appointment?.booking?.[0],
            },
          }
        : event
    )

    let unscheduled_appointments = []
    let other_events = []
    for (let event of result) {
      if (
        event.type === 'appointment' &&
        isUnscheduledAppointment(event.appointment)
      ) {
        unscheduled_appointments.push(event)
      } else {
        other_events.push(event)
      }
    }

    result = [...other_events, ...unscheduled_appointments]

    // exclude today's appointments
    let first_future_event = result.find(
      event =>
        isAfter(
          Date.parse(`${event.created_at}Z`),
          startOfDay(addDays(new Date(), 1))
        ) ||
        (event.type === 'appointment' &&
          isUnscheduledAppointment(event.appointment))
    )

    let future_separator = {
      id: 'future_separator',
      type: 'future_separator',
      hasFutureEvents: false,
      created_at: format(endOfDay(new Date()), `yyyy-MM-dd'T'HH:mm`),
      updated_at: format(endOfDay(new Date()), `yyyy-MM-dd'T'HH:mm`),
    }
    if (first_future_event) {
      result.splice(result.indexOf(first_future_event), 0, {
        ...future_separator,
        hasFutureEvents: true,
      })
    } else {
      result.push(future_separator)
    }
    return result
  }, [data])
}
