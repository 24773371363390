import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let is_support = useDataValue({
    context: 'auth',
    path: 'access_token_data.is_support',
    viewPath: props.viewPath,
  })
  return useMemo(() => {
    if (!data) return data

    return is_support
      ? data
      : data.filter(
          item =>
            item.visibility === 'LISTED' ||
            item.companies.some(item => item.status === 'granted')
        )
  }, [data, is_support])
}
