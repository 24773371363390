// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/IntegrationsApplications/Content/Apps/mutation-connect-api-token.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_IntegrationsApplications_Content_Apps(
  $app_id: uuid!
  $token: String!
  $connection_properties: jsonb!
  $location_id: uuid!
  $expires_at: date
) {
  apps_connect_api_token(
    app_id: $app_id
    token: $token
    connection_properties: $connection_properties
    location_id: $location_id
    expires_at: $expires_at
  ) {
    connection_id
  }
}

`