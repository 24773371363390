import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data
    let unique_persons = new Set()
    return data
      .map(item => {
        if (unique_persons.has(item.person.id)) {
          return false
        }
        unique_persons.add(item.person.id)
        return item
      })
      .filter(Boolean)
  }, [data])
}
