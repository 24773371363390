import { useMemo } from 'react'

let NO_GROUP_LABEL = 'OTHER TX PLANS'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data?.length) return null

    let groups = data.reduce((acc, item) => {
      let { tx_plan_group: group, ...rest } = item

      let groupId = group ? group.id : 0
      let groupName = group ? group.name.toUpperCase() : NO_GROUP_LABEL

      if (!acc[groupId]) {
        acc[groupId] = { id: groupId, type: groupName, plans: [] }
      }

      acc[groupId].plans.push(rest)

      return acc
    }, {})

    return Object.values(groups).sort((a, b) => a.type.localeCompare(b.type))
  }, [data])
}
