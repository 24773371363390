import addDays from 'date-fns/addDays/index.js'
import differenceInDays from 'date-fns/differenceInDays/index.js'
import format from 'date-fns/format/index.js'
import parseISO from 'date-fns/parseISO/index.js'

let EXPIRY_THRESHOLD_IN_DAYS = 12

export function getDisplayName(value) {
  return (
    value.display_name ||
    `${value.user.vaxiom_person.first_name} ${value.user.vaxiom_person.last_name}`
  )
}

export function expiresDisplay(value) {
  // return
  if (!value.connected_at || !value.app.connection?.expiresInDays) return '-'

  let expiresDate = addDays(
    parseISO(value.connected_at),
    value.app.connection.expiresInDays
  )

  return `${format(expiresDate, 'MMM d, yyyy, h:mm aaa')}`
}

export function expiresDisplayAlert(value) {
  if (!value.connected_at) return

  let expiresDate = addDays(
    parseISO(value.connected_at),
    value.app.connection.expiresInDays
  )

  return differenceInDays(expiresDate, new Date()) <= EXPIRY_THRESHOLD_IN_DAYS
}
