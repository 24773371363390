import { useDataIsSubmitting, useDataValue } from 'Simple/Data.js'

export function useDisabled(props) {
  let submitting = useDataIsSubmitting({
    viewPath: props.viewPath,
    context: 'selected',
  })

  let selected = useDataValue({
    viewPath: props.viewPath,
    context: 'selected',
  })

  let patient_person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'patient',
    path: 'person._id',
  })

  return (
    submitting ||
    selected.person_id === null ||
    selected.email === null ||
    (selected.permitted_to_see_info === false &&
      patient_person_id !== selected.person_id)
  )
}
