import { useDataValue, useDataSubmit } from 'Simple/Data.js'
export function useOnClick(props) {
  let note_id = useDataValue({
    context: 'note',
    viewPath: props.viewPath,
    path: 'id',
  })
  let submit = useDataSubmit({
    context: 'selected',
    viewPath: props.viewPath,
  })
  return function onClick() {
    submit({
      type: 'deleteNote',
      note_id,
    })
  }
}
