import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

// TODO: filter out Send hub invite from menu when account already exists.
// TODO: rename Send Hub invite to Resend Practice Hub invite if account already exists.
export default function useDataTransform(props, data) {
  let email = useDataValue({
    viewPath: props.viewPath,
    context: 'email',
  })
  return useMemo(() => {
    if (!data) return data

    return [
      ...data,
      ...(email.preference === false
        ? [
            {
              id: 'SET_PREFERRED',
              text: 'Set Preferred',
            },
          ]
        : []),
    ]
  }, [data, email])
}
