export function getView(
  connection_resource_status,
  connection_status,
  connection_properties
) {
  // only allow setting up automations for iTero and if the connection is active and enabled for the parent compant/location
  return (
    connection_resource_status === 'enabled' &&
    connection_status === 'active' &&
    connection_properties?.length > 0
  )
}
