import mutation from './mutation.graphql.js'
import { useMutation } from 'Data/Api'
import { useSetFlowTo, normalizePath } from 'Simple/Flow'
import { notifyError, useNotifications } from 'Logic/Notifications'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()

  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'remove_financial_contract_setup': {
        let response = await executeMutation({
          treatment_id: value._id,
        })

        if (response.error) {
          notify(notifyError(`Can't remove financial contract setup`))
          return
        }

        setFlowTo(
          normalizePath(
            props.viewPath,
            'Content/TreatmentPlanStatus/Content/Modal/No'
          )
        )

        return
      }
      default: {
        return
      }
    }
  }
}
