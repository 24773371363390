// this is an autogenerated file from App/Account/Content/Calendar/New/AppointmentOverlay/Content/PatientSelect/Content/Content/Existing/PatientPreview/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Calendar_New_AppointmentOverlay_Content_PatientSelect_Content_Content_Existing_PatientPreview($patient_id: uuid!) {
  vaxiom_patients(where: { _id: { _eq: $patient_id } }) {
    id
    human_readable_id
    primary_location {
      id
      name
    }
    person {
      id
      profile_pic_url
      gender
      birth_date
      greeting
      first_name
      last_name
    }
  }
}

`