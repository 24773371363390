import { useMutation } from 'Data/Api.js'
import { useDataValue } from 'Simple/Data.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'

import mutation from './mutation.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data, reExecuteQuery) {
  let contract_id = useDataValue({
    context: 'payor',
    path: 'contract.id',
    viewPath: props.viewPath,
  })
  let treatment_plan_id = useDataValue({
    context: 'payor',
    path: 'treatment_plan_id',
    viewPath: props.viewPath,
  })
  let person_id = useDataValue({
    context: 'payor',
    path: 'person_id',
    viewPath: props.viewPath,
  })

  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  return async function onSubmit({ value, originalValue, args, change }) {
    let mutationResponse = await executeMutation({
      contract_id,
      treatment_plan_id,
      person_id,
    })

    if (mutationResponse.error) {
      notify(
        notifyError(
          'There was a problem removing the contract signature. Please try again.'
        )
      )
      return
    }
  }
}
