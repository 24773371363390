// this is an autogenerated file from DesignSystem/PatientComms/PatientCommsContactMethods/queryDiscussion.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_PatientComms_PatientCommsContactMethods($patient_id: String!) {
  comms_threads(
    where: {
      resources_ids: { _has_keys_any: [$patient_id] }
      type: { _eq: internal }
    }
  ) {
    id
  }
}

`