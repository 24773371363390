import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { useDataValue } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import mutation from './mutation-login.graphql.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { getAccessTokenData, getSkipMfaToken } from 'Data/Auth.js'

export default function useDataOnActionLogin(props, data) {
  let [, notify] = useNotifications()

  let auth = useDataValue({
    viewPath: props.viewPath,
    context: 'auth',
    path: 'access_token_data',
  })

  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, executeMutation] = useMutation(mutation)

  return async function onActionLogin({ value, originalValue, args, change }) {
    if (auth.user_id === value.selected_id) {
      setFlowTo(normalizePath(props.viewPath, 'Content/ClockInOrOut'))
    } else {
      let mutationResponse = await executeMutation(
        {
          email: value.selected_email,
          password: value.password,
          xid: auth.xid,
          skip_mfa_token: getSkipMfaToken(value.selected_email),
        },
        {
          fetchOptions: {
            headers: {
              'x-hasura-role': 'public',
            },
          },
        }
      )

      if (mutationResponse.error) {
        notify(
          notifyError(
            'Something went wrong. Please, try again or contact support if the problem persists.'
          )
        )

        return
      }
      switch (mutationResponse.data.vaxiom_users_login.status) {
        case 'unauthorized': {
          return notify(notifyError('Please verify your credentials'))
        }

        case 'mfa_required': {
          change(next => {
            next.access_token = null
            next.role = null
            next.mfa = {
              ...mutationResponse.data.vaxiom_users_login.mfa,
              skip_next: false,
            }
          })
          setFlowTo(normalizePath(props.viewPath, 'Content/TwoFactorAuth'))
          return
        }

        case 'ok': {
          let access_token_data = getAccessTokenData(
            mutationResponse.data.vaxiom_users_login.access_token
          )

          change(next => {
            next.access_token =
              mutationResponse.data.vaxiom_users_login.access_token
            next.role = access_token_data.default_role
            next.mfa = null
          })
          setFlowTo(normalizePath(props.viewPath, 'Content/ClockInOrOut'))
          return
        }

        default: {
          return
        }
      }
    }
  }
}
