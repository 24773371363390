// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentPlans/New/Steps/Content/PaymentPlans/Content/Content/PaymentPlan/Content/Content/Insurances/Insurance/Subscription/Content/Content/Default/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentPlans_New_Steps_Content_PaymentPlans_Content_Content_PaymentPlan_Content_Content_Insurances_Insurance_Subscription_Content_Content_Default($patient_id: numeric!) {
  vaxiom_insured(
    where: {
      patient_id: { _eq: $patient_id }
      deleted: { _eq: false }
      insurance_subscription: { active: { _eq: true } }
    }
  ) {
    id
    _id
    patient {
      id
      _id
      person {
        id
        _id
        birth_date
      }
    }
    tx_category_insured {
      id
      _id
      ortho_insured {
        id
        _id
        used_lifetime_coverage
        year_deductible_paid_last
      }
    }
    insurance_subscription {
      id
      _id
      employer {
        id
        name
      }
      person {
        id
        _id
        birth_date
        first_name
        last_name
      }
      enrollment_date
      insurance_plan {
        id
        _id
        number
        insurance_company {
          id
          carrier_name
          installment_interval
        }
        insurance_billing_center {
          id
          name
        }
        tx_category_coverages {
          id
          ortho_coverages {
            id
            deductible_amount
            reimbursement_percentage
            max_lifetime_coverage
            cob_type
            downpayment_type
            downpayment_amount
            downpayment_percentage
            downpayment_percentage_base
            waiting_period
            dependent_age_limit
            age_limit_cutoff
          }
        }
      }
    }
  }
}

`