import { useRef } from 'react'
import { useDataValue } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import debounce from 'lodash/debounce.js'
import { equals } from 'Data/aggregate.js'

import mutation from './mutation-save.graphql.js'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let current_location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location.id',
  })
  let [, executeMutation] = useMutation(mutation)
  let previous_value = useRef(data)

  return debounce(onChange, 500)

  async function onChange(value, change) {
    // prevent auto-saving when payment plan disabled
    if (value.request_id || value.has_signed_contracts) {
      return
    }
    if (
      value.payors.length === 0 ||
      value.payors.some(payor => !payor.person_id) ||
      value.insurances.some(insurance => !insurance.insured_id)
    ) {
      return
    }
    if (equals(previous_value.current, value)) {
      return
    }

    previous_value.current = value
    await executeMutation({
      current_location_id,
      payment_plan: {
        ...value,
        insurances: getPaymentPlanInsurances(value),
      },
    })
  }
}

function getPaymentPlanInsurances(payment_plan) {
  return payment_plan.insurances.map(insurance => ({
    insured_id: insurance.insured_id,
    estimated_reimbursement_amount: insurance.estimated_reimbursement_amount,
    is_primary: insurance.is_primary,
    downpayment_amount: insurance.downpayment_amount,
    installment_amount: insurance.installment_amount,
  }))
}
