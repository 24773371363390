import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let stepsData = useDataValue({
    context: 'connection_resource_properties',
    viewPath: props.viewPath,
  })
  let propertyValues = useDataValue({
    context: 'connection_resource_properties',
    viewPath: props.viewPath,
    path: 'resource_properties[0]',
  })

  return useMemo(() => {
    return {
      steps: stepsData.app.properties.map(property => ({
        id: `${propertyValues.id}-${property.key}`,
        property_id: propertyValues.id,
        name: property.name,
        key: property.key,
        value: propertyValues.properties?.[property.key] || null,
      })),
      current_index: 0,
    }
  }, [])
}
