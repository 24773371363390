import { useDataValue } from 'Simple/Data.js'
import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let value = useDataValue({
    context: 'steps',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (value.current_index === null || !value.steps?.length) {
      return null
    }

    let step = value.steps[value.current_index]
    if (!step) return null

    return {
      ...step,
    }
  }, [value])
}
