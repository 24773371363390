import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data, reExecuteQuery) {
  let onShowQrCode = useOnShowQrCode(props)

  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'reFetch':
        return reExecuteQuery({ requestPolicy: 'network-only' })

      case 'show-qr-code':
        return onShowQrCode({ value, args, change, reExecuteQuery })

      default:
        return
    }
  }
}

function useOnShowQrCode(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onSubmit({ value, args, change, reExecuteQuery }) {
    setTimeout(
      () => setFlowTo(normalizePath(props.viewPath, 'Content/Content')),
      300
    )
  }
}
